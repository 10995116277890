import { useContextStore } from "@/store/zustandStore";
import { useLocation } from "@tanstack/react-router";
import styles from "@/styles/BuildingCompanyPicker.module.scss";
import { SelectChild } from "@/types";
import { Button, Dropdown, Icon, PopupCard } from "@app-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoggedInUser } from "@/providers/AuthProvider";
import { useBuilding, useCompanies } from "@/api";
import { useCompany } from "@/api";

export default function BuildingCompanyPicker() {
  const { t } = useTranslation();
  const location = useLocation();
  const user = useLoggedInUser();
  const { data: building } = useBuilding();
  const { data: companies } = useCompanies(user.adminInCompanies);
  const { data: company } = useCompany(user.buildingTenantId);
  const { selectedCompany, setCompany } = useContextStore();
  const [modalOpen, setModalOpen] = useState(false);
  const [newCompany, setNewCompany] = useState<SelectChild | undefined>(
    selectedCompany,
  );

  const shouldBeButton = companies && companies.length > 1;

  const saveSelections = () => {
    if (newCompany) {
      setCompany(newCompany);
      closeModal();
    }
  };

  const openModal = () => {
    setModalOpen(true);
    document.getElementById("handbook")?.classList.add("hide");
    document.getElementById("iframe-top-cover")?.classList.add("hide");
    document.getElementById("bookings")?.classList.add("hide");
  };
  const closeModal = () => {
    setModalOpen(false);
    if (location.pathname.includes("handbook")) {
      document.getElementById("handbook")?.classList.remove("hide");
      document.getElementById("iframe-top-cover")?.classList.remove("hide");
    }
    if (location.pathname.includes("bookings")) {
      document.getElementById("bookings")?.classList.remove("hide");
      document.getElementById("iframe-top-cover")?.classList.remove("hide");
    }
  };

  // Every time selectedCompany changes - persist the selection to localStorage
  useEffect(() => {
    if (
      !selectedCompany ||
      // company name has been changed by someone else
      (selectedCompany.value === company.buildingTenantId &&
        selectedCompany.label !== company.buildingTenantName)
    ) {
      if (company) {
        const companyAsSelectItem = {
          value: company.buildingTenantId,
          label: company.buildingTenantName,
        };
        localStorage.setItem(
          "selectedCompany",
          JSON.stringify(companyAsSelectItem),
        );
        setCompany(companyAsSelectItem);
      } else {
        throw new Error("No company data in local storage or database.");
      }
    } else {
      localStorage.setItem("selectedCompany", JSON.stringify(selectedCompany));
    }
  }, [selectedCompany, company]);

  return (
    <>
      <div
        className={styles.container}
        style={shouldBeButton ? { cursor: "pointer" } : {}}
        role={shouldBeButton ? "button" : "none"}
        aria-pressed={shouldBeButton && modalOpen}
        onClick={() => {
          if (shouldBeButton) openModal();
        }}
      >
        <>
          <h2>
            <Icon name="domain" />{" "}
            <span className={styles.ellipsis}>{building.displayName}</span>
          </h2>
          <h2 className={styles.selectedCompany}>
            <Icon name="work" />{" "}
            <span className={styles.ellipsis}>{selectedCompany?.label}</span>
          </h2>
        </>
      </div>
      {modalOpen && building && companies && (
        <PopupCard onClose={() => closeModal()}>
          <div className={styles.modalContainer}>
            <Dropdown
              label={t("CONTEXT.BUILDING")}
              placeholder={t("DEFAULT_SELECT.PLACEHOLDER")}
              disabled
              dark
              large
              inputBgColor="var(--wlcm-color-light-black)"
              value={{
                value: "default",
                label: building.displayName,
              }}
              options={[
                {
                  value: "default",
                  label: building.displayName,
                },
              ]}
              noResultsText={t("DEFAULT_SELECT.NO_RESULTS")}
              onChange={(newVal) => console.log(newVal)}
            />
            <Dropdown
              label={t("CONTEXT.COMPANY")}
              placeholder={t("DEFAULT_SELECT.PLACEHOLDER")}
              dark
              large
              menuPlacement="top"
              maxMenuHeight={180}
              value={newCompany}
              options={companies.map((t) => ({
                value: t.buildingTenantId,
                label: t.buildingTenantName,
              }))}
              noResultsText={t("DEFAULT_SELECT.NO_RESULTS")}
              onChange={(newVal) => setNewCompany(newVal!)}
            />
            <div className={styles.buttonContainer}>
              <Button dark onClick={() => closeModal()}>
                {t("CANCEL")}
              </Button>
              <Button success onClick={saveSelections}>
                {t("CONTEXT.SWITCH")}
              </Button>
            </div>
          </div>
        </PopupCard>
      )}
    </>
  );
}
