import { createFileRoute } from "@tanstack/react-router";
import { GroupPreregistration } from "@/components/pre-registration/GroupPreregistration";
import { useTranslation } from "react-i18next";

export const Route = createFileRoute("/visitors/preregistration")({
  component: Preregistration,
});

function Preregistration() {
  const { t } = useTranslation();

  const breadcrumbs = [
    { label: t("SIDEBAR.VISITORS"), path: "/visitors" },
    {
      label: t("PAGES.VISITORS.PREREGISTRATION"),
      path: "/visitors/preregistration",
    },
  ];
  return <GroupPreregistration breadcrumbs={breadcrumbs} />;
}
