import {
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import client, { throwCaughtError } from "../client";
import { CompanyVisit, Visit } from "@/types";
import { Timestamp } from "firebase/firestore";

const PersonalVisitorsQueryFn = async (userId: string) => {
  try {
    const response = await client.get(`/getPersonVisitors?userId=${userId}`);
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const personalVisitorsOptions = (userId: string) => ({
  queryKey: ["personal-visitors", userId],
  queryFn: () => PersonalVisitorsQueryFn(userId),
});

export const usePersonalVisitors = (
  userId: string | undefined = "",
): UseSuspenseQueryResult<Visit[]> => {
  return useSuspenseQuery(personalVisitorsOptions(userId));
};

const CompanyVisitorsQueryFn = async (buildingTenantId: string) => {
  try {
    if (!buildingTenantId) throw new Error("buildingTenantId is required");
    const response = await client.get(
      `/getCompanyVisitors?buildingTenantId=${buildingTenantId}`,
    );
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const companyVisitorsOptions = (buildingTenantId: string) => ({
  queryKey: ["company-visitors", buildingTenantId],
  queryFn: () => CompanyVisitorsQueryFn(buildingTenantId),
});

export const useCompanyVisitors = (
  buildingTenantId: string,
): UseSuspenseQueryResult<CompanyVisit[]> => {
  return useSuspenseQuery(companyVisitorsOptions(buildingTenantId));
};

export const useBuildingVisitors = (): UseSuspenseQueryResult<Visit[]> => {
  return useSuspenseQuery({
    queryKey: ["visitors-building"],
    refetchInterval: 2500,
    queryFn: async () => {
      try {
        const response = await client.get("/getVisitorsBuilding");
        return response.data.visits;
      } catch (error) {
        throwCaughtError(error);
      }
    },
  });
};

export const updateVisitor = async (
  visitorId: string,
  values: {
    field: string;
    value: string | Timestamp;
  }[],
  company: boolean = false,
) => {
  try {
    const response = await client.patch("/updateVisitor", {
      visitorId,
      values,
      company,
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const letInVisitor = async (
  visitorId: string,
  company: boolean,
  name: string,
  phone: string,
  language: string,
) => {
  try {
    const response = await client.post("/letInVisitor", {
      visitorId,
      company,
      name,
      phone,
      language,
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};
