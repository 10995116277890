import { useUser } from "@/api";
import { Breadcrumbs, Button, Icon, SelectRow } from "@app-components";
import {
  createFileRoute,
  Outlet,
  useNavigate,
  useRouterState,
} from "@tanstack/react-router";
import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "@/styles/Users.module.scss";

export const Route = createFileRoute("/company/users/$userId")({
  component: UserDetails,
});

export const UserIdContext = createContext("");

function UserDetails() {
  const { t } = useTranslation();
  const router = useRouterState();
  const navigate = useNavigate();
  const { userId } = Route.useParams();
  const { data: user } = useUser(userId);
  const [tab, setTab] = useState<string>(router.location.pathname);

  const breadcrumbs = [
    { label: t("SIDEBAR.USERS"), path: "/company/users" },
    {
      label: `${user?.firstName} ${user?.lastName}`,
      path: `/company/users/${userId}/settings`,
    },
  ];

  const tabs = [
    {
      value: `/company/users/${userId}/settings`,
      label: t("PAGES.USERS.SUB_ROUTES.SETTINGS"),
      default: true,
    },
    {
      value: `/company/users/${userId}/access`,
      label: t("PAGES.USERS.SUB_ROUTES.ACCESS"),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4px var(--wlcm-spacing-sm)",
          flexWrap: "wrap",
        }}
      >
        <Button
          secondary
          size="sm"
          icon="arrow_back"
          onClick={() => navigate({ to: "/company/users" })}
        >
          {t("BACK")}
        </Button>
        <Breadcrumbs
          levels={breadcrumbs}
          onNavigate={(path) => navigate({ to: path })}
        />
      </div>
      <div className="pageHeader">
        {user.adminInCompanies.includes(user.buildingTenantId) && (
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "var(--wlcm-spacing-xs)",
              padding: "var(--wlcm-spacing-xs)",
              backgroundColor: "rgba(250, 217, 0, 0.5)",
              borderRadius: "var(--wlcm-border-radius-sm)",
              marginTop: "var(--wlcm-spacing-sm)",
              marginBottom: "var(--wlcm-spacing-xs)",
            }}
          >
            <Icon name="user_cogwheel" /> {t("USERS.ALSO_ADMIN")}
          </p>
        )}
        <h1>
          {user?.firstName} {user?.lastName}{" "}
          <span style={{ opacity: 0.5 }}>({user?.email})</span>
        </h1>
      </div>
      <SelectRow
        label="Tabs"
        hideLabel
        name="user-details-tabs"
        options={tabs}
        value={tab}
        onChange={(route) => {
          setTab(route as string);
          navigate({
            to: route as string,
          });
        }}
      />
      <div className={styles.userDetailsSubRouteContainer}>
        <UserIdContext.Provider value={userId}>
          <Outlet />
        </UserIdContext.Provider>
      </div>
    </>
  );
}
