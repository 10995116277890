import { CardStatus } from "@/types";
import { env } from "./utils";

export const ADDRESS_REGEX = /\w+(\s\w+){1,5}$/u;
export const DOMAIN_REGEX = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
export const NUMERIC_REGEX = /^[0-9+]+$/;
export const MESSAGE_REGEX = /^[\p{L}\p{N}\s!'\-&()?,."]*$/u;
export const NAME_REGEX = /^[\p{L}\s\-&.]*$/u;
export const COMPANY_REGEX = /^[\p{L}\p{N}\s\-&.]*$/u;
export const PHONE_REGEX = /^[+]([0-9]{3,15})$/;
export const ORG_NUMBER_REGEX = /^[0-9]{9}$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const WELCOME_BASE_HELP_URL = "https://help.welcomeworkdays.com/";

export const WELCOME_HELP_URL = (language: string) => {
  const base = "https://help.welcomeworkdays.com/";
  return language === "no" ? `${base}nb/` : `${base}${language}/`;
};

export const WELCOME_USER_ADMINISTRATION_URL = (lang: string) =>
  `${WELCOME_BASE_HELP_URL}${lang}/collections/316566-funksjonsbeskrivelser`;

export const WELCOME_VISITORS_URL = (lang: string) =>
  `${WELCOME_BASE_HELP_URL}${lang}/collections/322346-besoksregistrering`;

export const WELCOME_TERMS_OF_USE_URL = (lang: string) =>
  `${WELCOME_BASE_HELP_URL}${lang}/articles/220297-vilkar-for-bruk-av-welcome-workdays-tjenesteplattform`;

export const WELCOME_PRIVACY_POLICY_URL = (lang: string) =>
  `${WELCOME_BASE_HELP_URL}${lang}/articles/171672-personvernerklaering-for-welcome-workdays`;

export const WELCOME_LOGIN_METHOD_HELP_URL = (lang: string) =>
  `${WELCOME_BASE_HELP_URL}${lang}/articles/240918-choosing-a-login-method-sso-vs-passwordless-email-sign-on`;

const environment = env("VITE_ENVIRONMENT") as "dev" | "prod" | "preview";
const project = env("VITE_FB_PROJECT");
export const REAL_ESTATE = env("VITE_REAL_ESTATE");
if (
  environment !== "dev" &&
  environment !== "prod" &&
  environment !== "preview"
) {
  throw new Error("Invalid environment");
}

export const APP_DOMAIN = () => {
  const domainMap = {
    dev: `https://portal-${project}.web.app`,
    prod: `https://${REAL_ESTATE}.welcomeworkdays.com`,
    preview: `https://${REAL_ESTATE}.welcomeworkdays.dev`,
  };

  return domainMap[environment];
};

export const WELCOME_VISIT_KIOSK_URL = (id: string) => {
  const domainMap = {
    dev: `https://visit-${project}.web.app?entranceId=${id}`,
    prod: `https://visit-${REAL_ESTATE}.welcomeworkdays.com?entranceId=${id}`,
    preview: `https://visit-${REAL_ESTATE}.welcomeworkdays.dev?entranceId=${id}`,
  };

  return domainMap[environment];
};

export const accessCardStatusColorMap = {
  [CardStatus.ORDERED]: "var(--wlcm-color-malling-purple)",
  [CardStatus.ACTIVE]: "var(--wlcm-color-success-green)",
  [CardStatus.INACTIVE]: "var(--wlcm-color-warning-orange)",
  [CardStatus.DEACTIVATED]: "var(--wlcm-color-dark-inactive-grey)",
  [CardStatus.CANCELLED]: "var(--wlcm-color-dark-inactive-grey)",
};
