import { useCompanyContact } from "@/api";
import { useLoggedInUser } from "@/providers/AuthProvider";
import { Trans, useTranslation } from "react-i18next";

export default function PageNotFound() {
  const { t } = useTranslation();
  const user = useLoggedInUser();
  const { data: companyContact } = useCompanyContact(user.buildingTenantId);

  return (
    <>
      <h1
        style={{
          textAlign: "center",
          fontSize: "5em",
          color: "var(--wlcm-color-malling-purple)",
        }}
      >
        404
      </h1>
      <h2 style={{ textAlign: "center" }}>{t("404.HEADER")}</h2>
      <p style={{ textAlign: "center" }}>
        {t("404.BODY")}
        {companyContact && (
          <>
            <br />
            <Trans
              i18nKey="YOUR_MAIN_ADMIN"
              values={{
                companyContact: companyContact.name,
              }}
              components={{
                mailto: <a href={`mailto:${companyContact.email}`}></a>,
              }}
            ></Trans>
          </>
        )}
      </p>
    </>
  );
}
