import UserDetailsForm from "@/components/user/UserDetailsForm";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useContext, useState } from "react";
import { UserIdContext } from "./$userId";
import { deleteUser, updateUser, useCompany, useUser } from "@/api";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbarStore } from "@/store/zustandStore";
import { NotificationType } from "@/types";
import { useTranslation } from "react-i18next";
import { Button } from "@app-components";

export const Route = createFileRoute("/company/users/$userId/settings")({
  component: UserDetailsSettings,
});

function UserDetailsSettings() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userId = useContext(UserIdContext);
  const { data: user } = useUser(userId);
  const { data: company } = useCompany(user.buildingTenantId);
  const { addNotification } = useSnackbarStore();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isGrantingAdmin, setIsGrantingAdmin] = useState(false);

  const deleteUser_ = async () => {
    setIsDeleting(true);
    try {
      await deleteUser(user.userId, user.email);
      await queryClient.invalidateQueries({ queryKey: ["users"] });
      await queryClient.invalidateQueries({ queryKey: ["company-admins"] });
      addNotification("User was deleted", NotificationType.SUCCESS);
      navigate({ to: "/company/users" });
    } catch (error) {
      console.error(error);
      addNotification("Failed to delete user. Try again later!");
    } finally {
      setIsDeleting(false);
    }
  };

  const turnUserIntoAdmin = async () => {
    setIsGrantingAdmin(true);
    try {
      await updateUser(user.userId, {
        adminInCompanies: [...user.adminInCompanies, company.buildingTenantId],
      });
      addNotification(
        "User was granted admin rights!",
        NotificationType.SUCCESS,
      );
      await queryClient.invalidateQueries({ queryKey: ["users"] });
      await queryClient.invalidateQueries({ queryKey: ["company-admins"] });
    } catch (error) {
      console.error(error);
      addNotification("Failed to grant user admin rights. Try again later!");
    } finally {
      setIsGrantingAdmin(false);
    }
  };

  return (
    <>
      <UserDetailsForm userData={user} />
      <div
        className="editDangerArea"
        style={{
          marginTop: "var(--wlcm-spacing-lg)",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <h4 style={{ width: "100%" }}>{t("EDIT_USER.DANGER")}</h4>
        {!user.adminInCompanies.includes(company.buildingTenantId) && (
          <Button
            dark
            icon="user_cogwheel"
            disabled={isDeleting || isGrantingAdmin}
            isLoading={isGrantingAdmin}
            onClick={turnUserIntoAdmin}
          >
            {t("EDIT_USER.ADMIN", { company: company.buildingTenantName })}
          </Button>
        )}
        <Button
          icon="delete"
          disabled={isDeleting || isDeleting}
          isLoading={isDeleting}
          danger
          onClick={deleteUser_}
        >
          {t("EDIT_USER.DELETE")}
        </Button>
      </div>
    </>
  );
}
