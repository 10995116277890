import { Button, Icon, InputField, LoadingSpinner } from "@app-components";
import styles from "@/styles/AddAdminModal.module.scss";
import { User } from "@/types";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { useEffect, useState } from "react";
import { getCompanyFunction } from "@/api";

interface PersonSearchProps {
  header?: string;
  body?: string;
  noResultsText: string;
  dark?: boolean;
  isSearching: boolean;
  searchQuery: string;
  searchForUser: () => void;
  setSearchQuery: (value: string) => void;
  foundUser: User | null | undefined;
  addUser: () => void;
  isAddButtonDisabled?: boolean;
  isLoading?: boolean;
  userAdded?: boolean;
  deleteHost?: () => void;
  showCompany?: boolean;
  infoText?: string;
  label?: string;
  required?: boolean;
}

export const PersonSearch = ({
  header,
  body,
  noResultsText,
  dark,
  isSearching,
  searchQuery,
  searchForUser,
  setSearchQuery,
  foundUser,
  addUser,
  isAddButtonDisabled,
  isLoading,
  userAdded,
  deleteHost,
  showCompany,
  infoText,
  label,
  required,
}: PersonSearchProps) => {
  const { t } = useTranslation();
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    const fetchCompany = async () => {
      if (showCompany && foundUser && foundUser.buildingTenantId) {
        const company = await getCompanyFunction(foundUser?.buildingTenantId);
        if (company) {
          setCompanyName(company.buildingTenantName);
        }
      }
    };
    fetchCompany();
  }, [foundUser, showCompany]);

  return (
    <div className={styles.addAdminCard} onChange={(e) => e.stopPropagation()}>
      {userAdded ? (
        <p className={styles.preregistrationHost}>
          {t("PREREGISTRATION.HOST")}
        </p>
      ) : (
        <>
          {header && <h3>{header}</h3>}
          {body && <p>{body}</p>}
        </>
      )}
      {!userAdded && (
        <InputField
          required={required}
          compact
          hideLabel={!label}
          dark={dark}
          label={label ?? t("ADD_USER.EMAIL")}
          icon="search"
          placeholder="ola@example.com"
          disabled={isSearching}
          value={searchQuery}
          onKeyDown={(e) => e.key === "Enter" && searchForUser()}
          onChange={(e) => setSearchQuery(e.target.value)}
          infoText={infoText}
          append={
            <Button
              dark
              onClick={searchForUser}
              isLoading={isSearching}
              disabled={isSearching}
              style={{ marginRight: -8 }}
            >
              {t("SEARCH")}
            </Button>
          }
        />
      )}
      <div className={`${styles.resultContainer} ${dark ? styles.dark : ""}`}>
        {isSearching ? (
          <LoadingSpinner />
        ) : foundUser ? (
          <div className={styles.userCard}>
            <Icon name="person" />
            <p>
              {foundUser.firstName} {foundUser.lastName} ({foundUser.email}){" "}
              <span style={{ color: "var(--wlcm-color-dark-inactive-grey)" }}>
                {showCompany && `${t("FROM")} ${companyName}`}
              </span>
            </p>
            {userAdded ? (
              <button className={styles.deleteUserButton} onClick={deleteHost}>
                <Icon name="delete" className="delete-user" />
                <Tooltip
                  anchorSelect=".delete-user"
                  content={t("PREREGISTRATION.DELETE_HOST")}
                />
                <span className="sr-only">
                  {t("PREREGISTRATION.DELETE_HOST")}
                </span>
              </button>
            ) : (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addUser();
                }}
                disabled={isAddButtonDisabled}
                isLoading={isLoading}
              >
                {t("BUILDING_SETTINGS.ADD_ADMIN_MODAL.ADD")}
              </Button>
            )}
          </div>
        ) : (
          foundUser === null && (
            <div className={styles.noResultsContainer}>
              <h4>
                {t("BUILDING_SETTINGS.ADD_ADMIN_MODAL.NO_RESULTS.HEADER")}
              </h4>
              <p>{noResultsText}</p>
            </div>
          )
        )}
      </div>
    </div>
  );
};
