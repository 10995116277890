import styles from "@/styles/Visitors.module.scss";
import { Trans, useTranslation } from "react-i18next";
import { useContextStore } from "@/store/zustandStore";
import { WELCOME_VISITORS_URL } from "@/lib/constants";
import i18n from "@/i18n/config";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Suspense } from "react";
import { Button, LoadingSpinner } from "@app-components";
import { useCompanyVisitors, useCompanyPreregistrations } from "@/api";
import { CombinedVisitorsAndPreregistrations } from "@/components/pre-registration/CombinedVisitorsAndPreregistration";
import { PreregistrationBody } from "@/components/pre-registration/PreregistrationBody";

export const Route = createFileRoute("/company/visitors/")({
  component: CompanyVisitors,
});

function CompanyVisitors() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedCompany } = useContextStore();
  const { data: visitors } = useCompanyVisitors(selectedCompany?.value || "");
  const { data: preregistrations } = useCompanyPreregistrations(
    selectedCompany?.value || "",
  );

  return (
    <>
      <div className="pageHeader">
        <h1>{t("PAGES.COMPANY_VISITORS.HEADER")}</h1>
        <p>
          <Trans i18nKey="PAGES.COMPANY_VISITORS.SUBHEADER">
            Text
            <a href={WELCOME_VISITORS_URL(i18n.language)} target="_blank"></a>.
          </Trans>
        </p>
      </div>
      <Suspense
        fallback={
          <div className="center">
            <LoadingSpinner />
          </div>
        }
      >
        <CombinedVisitorsAndPreregistrations
          visitors={visitors}
          preregistrations={preregistrations}
          isCompany
        />
      </Suspense>
      <div className={`${styles.preregistrationContainer} pageHeader`}>
        <div>
          <h1>{t("PAGES.VISITORS.PREREGISTRATION")}</h1>
          <p>{t("PAGES.VISITORS.PREREGISTRATION_DESCRIPTION")}</p>
        </div>
        <Button
          icon="add"
          onClick={() => navigate({ to: "/company/visitors/preregistration" })}
        >
          {t("PAGES.VISITORS.CREATE_PREREGISTRATION")}
        </Button>
      </div>
      <Suspense
        fallback={
          <div className="center">
            <LoadingSpinner />
          </div>
        }
      >
        <PreregistrationBody preregistrations={preregistrations} isCompany />
      </Suspense>
    </>
  );
}
