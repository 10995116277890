import {
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import client, { throwCaughtError } from "../client";
import {
  ExtendedCompany,
  Company,
  ExtendedUser,
  CompanyContact,
  SSOItem,
  BronnoysundCompanyResult,
} from "@/types";
import { ORG_NUMBER_REGEX } from "@/lib/constants";

export const useCompanyContact = (
  buildingTenantId?: string,
): UseSuspenseQueryResult<CompanyContact | null> => {
  return useSuspenseQuery({
    queryKey: ["company-contact", buildingTenantId],
    queryFn: async () => {
      try {
        if (!buildingTenantId) throw new Error("buildingTenantId is required");
        const response = await client.get(
          `/getCompanyContact?buildingTenantId=${buildingTenantId}`,
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  });
};

export const companyAdminsOptions = (buildingTenantId?: string) => ({
  queryKey: ["company-admins", buildingTenantId],
  queryFn: async () => {
    try {
      if (!buildingTenantId) throw new Error("buildingTenantId is required");
      const response = await client.get(
        `/getExtendedUsers?buildingTenantId=${buildingTenantId}`,
      );
      return response.data.users;
    } catch (error) {
      throwCaughtError(error);
    }
  },
});

export const useCompanyAdmins = (
  buildingTenantId?: string,
): UseSuspenseQueryResult<ExtendedUser[]> => {
  return useSuspenseQuery(companyAdminsOptions(buildingTenantId));
};

export const getCompanyFunction = async (
  buildingTenantId: string,
): Promise<Company | undefined> => {
  try {
    const response = await client.get(
      `/getCompaniesByIds?buildingTenantIds=${buildingTenantId}`,
    );
    return response.data[0];
  } catch (error) {
    throwCaughtError(error);
  }
  return undefined;
};

export const companyOptions = (buildingTenantId: string) => ({
  queryKey: ["company", buildingTenantId],
  queryFn: () => getCompanyFunction(buildingTenantId),
});

export const useCompany = (
  buildingTenantId: string | undefined = "",
): UseSuspenseQueryResult<Company> => {
  return useSuspenseQuery({
    ...companyOptions(buildingTenantId),
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

export const useCompanies = (
  buildingTenantIds: string[] | undefined = [],
): UseSuspenseQueryResult<Company[]> => {
  return useSuspenseQuery({
    queryKey: ["companies", buildingTenantIds],
    queryFn: async () => {
      if (buildingTenantIds.length === 0) return [];
      try {
        const companyIdsAsQueryParams = buildingTenantIds
          .map((id, index) =>
            index === 0
              ? `buildingTenantIds=${id}`
              : `&buildingTenantIds=${id}`,
          )
          .join("");
        const response = await client.get(
          `/getCompaniesByIds?${companyIdsAsQueryParams}`,
        );
        return response.data;
      } catch (error) {
        throwCaughtError(error);
      }
    },
  });
};

export const useExtendedCompanies = (): UseSuspenseQueryResult<
  ExtendedCompany[]
> => {
  return useSuspenseQuery({
    queryKey: ["extended-companies"],
    queryFn: async () => {
      try {
        const response = await client.get("/getExtendedCompanies");
        return response.data;
      } catch (error) {
        throwCaughtError(error);
      }
    },
  });
};

// Documentation: https://data.brreg.no/enhetsregisteret/api/dokumentasjon/no/index.html#tag/Enheter/operation/hentEnheter
export const getCompaniesFromBronnoysund = async (
  query: string,
): Promise<BronnoysundCompanyResult[] | undefined> => {
  try {
    if (!query) return;
    const queryIsOrgNumber = ORG_NUMBER_REGEX.test(query);
    const queryParams =
      (queryIsOrgNumber ? "organisasjonsnummer=" : "navn=") +
      encodeURIComponent(query);
    const res = await fetch(
      `https://data.brreg.no/enhetsregisteret/api/enheter?${queryParams}&size=5`,
    );
    const data = await res.json();
    return data?._embedded?.enheter || [];
  } catch (error) {
    throwCaughtError(error);
  }
};

export const createCompanyInBuilding = async (
  companyData: Partial<Company>,
): Promise<string | undefined> => {
  const response = await client.post("/createCompany", { companyData });
  return response.data?.buildingTenantId;
};

export const updateCompanySettings = async (
  companyId: string,
  updateData: Partial<Company>,
) => {
  try {
    const response = await client.patch(`/updateCompany`, {
      tenantId: companyId,
      updateData,
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const deleteCompany = async (companyId: string) => {
  try {
    const response = await client.delete("/deleteCompany", {
      data: { buildingTenantId: companyId },
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const addSsoToCompany = async (
  comapnyId: string,
  companyName: string,
  realEstate: string,
  ssoSettings: SSOItem[],
) => {
  try {
    const response = await client.post("/addSso", {
      tenantId: comapnyId,
      tenantName: companyName,
      realEstate,
      ssoSettings,
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};
