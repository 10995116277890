import React from "react";
// import "../../../src/global.scss";
import styles from "./PopupCard.module.scss";
import { Icon } from "../Icon";

type PopupCardProps = {
  onClose?: () => void;
  children: React.ReactNode;
};

export function PopupCard({ onClose, children }: PopupCardProps) {
  return (
    <div className={styles.popupCardWrapper} onClick={onClose}>
      <div
        className={styles.popupCard}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {onClose && (
          <button className={styles.closeButton} onClick={onClose}>
            <Icon className={styles.close} name="close" />
          </button>
        )}
        {children}
      </div>
    </div>
  );
}
