import { Icon } from "../Icon";
import styles from "./StatusText.module.scss";

type StatusTextProps = {
  success?: boolean;
  fail?: boolean;
  dark?: boolean;
  text: string;
};

export function StatusText({ success, fail, dark, text }: StatusTextProps) {
  return (
    <div className={`${styles.statusText} ${dark ? styles.dark : ""}`}>
      {success && !fail ? (
        <Icon className={styles.successIcon} name="check_circle" />
      ) : fail && !success ? (
        <Icon className={styles.warningIcon} name="emergency" />
      ) : (
        <Icon className={styles.infoIcon} name="info" />
      )}
      <p
        className={`${styles.statusText} ${success ? styles.success : ""} ${fail ? styles.fail : ""}`}
      >
        {text}
      </p>
    </div>
  );
}
