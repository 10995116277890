import React from "react";
import EasyCropper, { Size } from "react-easy-crop";
import { useImageCropContext } from "./ImageCropProvider";

interface CropperProps {
  cropShape?: "round" | "rect";
  aspectRatio?: number;
  showGrid?: boolean;
  cropSize?: Size;
  style?: {
    containerStyle?: React.CSSProperties;
    mediaStyle?: React.CSSProperties;
    cropAreaStyle?: React.CSSProperties;
  };
}

const Cropper = ({
  cropShape = "rect",
  aspectRatio,
  showGrid = true,
  cropSize,
  style,
}: CropperProps) => {
  const context = useImageCropContext();
  if (!context)
    throw new Error(
      "<Cropper /> should not be used outside of <ImageCropProvider />",
    );
  const {
    image,
    zoom,
    setZoom,
    rotation,
    setRotation,
    crop,
    setCrop,
    onCropComplete,
  } = context;

  return (
    <EasyCropper
      image={image || undefined}
      crop={crop}
      zoom={zoom}
      rotation={rotation}
      cropShape={cropShape}
      aspect={aspectRatio}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
      onRotationChange={setRotation}
      showGrid={showGrid}
      cropSize={cropSize}
      style={style}
    />
  );
};

export default Cropper;
