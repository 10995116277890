import styles from "./RadioButtons.module.scss";

type Option = {
  title: string;
  value: any;
};

type RadioButtonsProps = {
  options: Option[];
  onChange: (value: any) => void;
  checked: any;
  dark?: boolean;
  isVertical?: boolean;
};

export function RadioButtons({
  options,
  onChange,
  dark,
  checked,
  isVertical,
}: RadioButtonsProps) {
  const renderOptions = () => {
    return options.map((radio: Option) => {
      return (
        <div
          key={radio.value}
          className={`${styles.radio} ${dark ? styles.dark : ""}`}
        >
          <label className={styles.customRadioButton}>
            <input
              type="radio"
              value={radio.value}
              checked={checked === radio.value}
              onChange={() => onChange(radio)}
            />
            <span className={styles.customRadio}>
              {checked === radio.value && (
                <>
                  <div className={styles.dot}></div>
                </>
              )}
            </span>
            {radio.title}
          </label>
        </div>
      );
    });
  };
  return (
    <div
      className={`${styles.buttonGroup} ${isVertical ? styles.vertical : ""}`}
    >
      {renderOptions()}
    </div>
  );
}
