import { Button, Icon } from "@app-components";
import type { FormApi, ReactFormApi, Validator } from "@tanstack/react-form";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import styles from "@/styles/SaveResetButtons.module.scss";

interface CommonProps {
  showButtons: boolean;
}

interface FormVariant extends CommonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormApi<any, Validator<any>> & ReactFormApi<any, any>;
  onHideButtons: () => void;
}

interface ControlledVariant extends CommonProps {
  loading: boolean;
  onReset: () => void;
  onSave: () => void;
}

type SaveResetButtonsProps = FormVariant | ControlledVariant;

function isFormVariant(props: SaveResetButtonsProps): props is FormVariant {
  return (props as FormVariant).form !== undefined;
}

export default function SaveResetButtons(props: SaveResetButtonsProps) {
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      {props.showButtons && (
        <motion.div
          className={styles.buttonsContainer}
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 30, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {isFormVariant(props) ? (
            <>
              <props.form.Subscribe
                selector={(state) => [state.isSubmitting]}
                children={([isSubmitting]) => (
                  <Button
                    dark
                    type="reset"
                    disabled={isSubmitting}
                    onClick={(e) => {
                      e.preventDefault();
                      props.form.reset();
                      props.onHideButtons();
                    }}
                  >
                    <Icon name="undo" />
                    <span className={styles.buttonLabel}>
                      {t("REVERT_CHANGES")}
                    </span>
                  </Button>
                )}
              />
              <props.form.Subscribe
                selector={(state) => [state.canSubmit, state.isSubmitting]}
                children={([canSubmit, isSubmitting]) => (
                  <Button
                    success
                    type="submit"
                    disabled={isSubmitting || !canSubmit}
                    isLoading={isSubmitting}
                    className={isSubmitting ? styles.submitting : ""}
                  >
                    <Icon name="save" />
                    <span className={styles.buttonLabel}>
                      {t("SAVE_CHANGES")}
                    </span>
                  </Button>
                )}
              />
            </>
          ) : (
            <>
              <Button dark type="reset" onClick={props.onReset}>
                <Icon name="undo" />
                <span className={styles.buttonLabel}>
                  {t("REVERT_CHANGES")}
                </span>
              </Button>
              <Button
                success
                type="submit"
                disabled={props.loading}
                isLoading={props.loading}
                onClick={props.onSave}
                className={props.loading ? styles.submitting : ""}
              >
                <Icon name="save" />
                <span className={styles.buttonLabel}>{t("SAVE_CHANGES")}</span>
              </Button>
            </>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
