import axios from "axios";
import { env } from "@/lib/utils";
import { getAuthUser } from "@/lib/firebase";

const apiClient = axios.create({
  baseURL: `${env("VITE_API_BASE")}/api`,
});

apiClient.interceptors.request.use(
  async (config) => {
    const idToken = await getAuthUser().getIdToken();
    if (idToken) {
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

type CustomError = { response?: { data?: { message?: string } } };
export const throwCaughtError = (error: unknown) => {
  throw new Error("An unexpected error occurred: ", {
    cause: (error as CustomError)?.response?.data?.message || error,
  });
};

export default apiClient;
