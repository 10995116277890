import { useSnackbarStore, useUserStore } from "@/store/zustandStore";
import styles from "@/styles/AnswerToVisitor.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  Button,
  Icon,
  InputField,
  LoadingSpinner,
} from "@app-components";
import { motion, AnimatePresence } from "framer-motion";
import { useQueryClient } from "@tanstack/react-query";
import {
  letInVisitor,
  sendSMSMessage,
  updateVisitor,
  useBuilding,
} from "@/api";
import { AccessSystem, NotificationType, Timestamp } from "@/types";
import { Tooltip } from "react-tooltip";
import { formatTimestampToHoursAndMinutes } from "@/lib/utils";

export type AnswerToVisitorProps = {
  visitorName: string;
  visitorLanguage: string;
  visitorId: string;
  visitorPhone: string;
  createdAt: Timestamp;
  hostReplied?: string;
  whoReplied?: string;
  company?: boolean;
  letInAt?: Timestamp;
};

export default function AnswerToVisitor({
  visitorName,
  visitorLanguage,
  visitorId,
  visitorPhone,
  createdAt,
  hostReplied,
  whoReplied,
  company,
  letInAt,
}: AnswerToVisitorProps) {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
  const [showSuccessOverlay, setShowSuccessOverlay] = useState(
    !!(hostReplied || letInAt),
  ); // true by default if host has replied or let the user in, false otherwise
  const [loading, setLoading] = useState(false);
  const [letInLoading, setLetInLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { addNotification } = useSnackbarStore();
  const { userName } = useUserStore();
  const queryClient = useQueryClient();
  const oneHourAgo = new Date().getSeconds() - 60 * 60;
  const { data: building } = useBuilding();

  // TODO: replace this conditons with Firebase configurations once implemented
  const renderLetInButton = building.accessSystem === AccessSystem.STARWATCH;

  useEffect(() => {
    if (message.length < 2) {
      setIsSendButtonDisabled(true);
      return;
    }

    setIsSendButtonDisabled(false);
  }, [message]);

  const handleInput = (input: string) => {
    setMessage(input);
  };

  const resetReplyState = () => {
    setMessage("");
    setExpanded(true);
    setShowSuccessOverlay(false);
  };

  const letVisitorIn = async () => {
    try {
      setLetInLoading(true);
      await letInVisitor(
        visitorId,
        company!,
        visitorName,
        visitorPhone,
        visitorLanguage,
      );
      setShowSuccessOverlay(true);
      queryClient.invalidateQueries({
        queryKey: [company ? "company-visitors" : "personal-visitors"],
      });
      addNotification(
        t("ANSWER_VISITOR.LET_IN_SUCCESS"),
        NotificationType.SUCCESS,
      );
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        addNotification(error.message);
      } else {
        console.error(error);
        addNotification("Failed to send access code to the visitor.");
      }
    } finally {
      setLetInLoading(false);
      setExpanded(false);
    }
  };

  const sendMessageToReceiver = async (
    messageOverride: string | null = null,
  ) => {
    setLoading(true);
    const msg = messageOverride || message;
    if (messageOverride) setMessage(msg); // Allows showing predefined message texts in confirmation overlay
    const messageBody = t("MESSAGE_BODY", {
      hostName: userName,
      message: msg,
    });

    try {
      await sendSMSMessage(visitorPhone, messageBody);

      setShowSuccessOverlay(true);
      await updateVisitor(
        visitorId,
        [
          { field: "hostReplied", value: msg },
          ...(company ? [{ field: "whoReplied", value: userName }] : []),
        ],
        company,
      );
      queryClient.invalidateQueries({
        queryKey: [company ? "company-visitors" : "personal-visitors"],
      });
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes("Failed to send message to visitor")) {
          console.error(error);
          addNotification(error.message);
        } else {
          console.error(error);
          addNotification(
            "Failed to get ID token. Please logout and login agian." +
              error.message,
          );
        }
      } else {
        console.error(error);
        addNotification("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
      setExpanded(false);
    }
  };
  return (
    <div className={styles.container}>
      {renderLetInButton && (
        <div className={styles.letInVisitor}>
          <div>
            <h4>{t("ANSWER_VISITOR.LET_IN_VISITOR")}</h4>
            <p>{t("ANSWER_VISITOR.SEND_ACCESS_CODE")}</p>
          </div>
          <Button
            id={`${visitorId}-let-in-btn`}
            dark
            icon="key"
            isLoading={letInLoading}
            disabled={letInLoading || !!letInAt}
            onClick={letVisitorIn}
          >
            {t("ANSWER_VISITOR.LET_IN")}
          </Button>
          {!!letInAt && (
            <Tooltip
              anchorSelect={`#${visitorId}-let-in-btn`}
              content={`${t("ANSWER_VISITOR.CODE_ALREADY_SENT")} ${formatTimestampToHoursAndMinutes(letInAt)}`}
            />
          )}
        </div>
      )}

      {renderLetInButton ? (
        <>
          <hr />
          <div style={{ position: "relative" }}>
            <Accordion
              id={`${visitorId}-quick-answer`}
              expanded={expanded}
              onToggle={setExpanded}
              header={
                <h4>
                  <Icon name="sms" /> {t("ANSWER_VISITOR.ACCORDION_HEADER")}
                </h4>
              }
              body={
                <QuickResponse
                  message={message}
                  sendMessageToReceiver={sendMessageToReceiver}
                  handleInput={handleInput}
                  isSendButtonDisabled={isSendButtonDisabled}
                />
              }
            />
          </div>
        </>
      ) : (
        <QuickResponse
          message={message}
          sendMessageToReceiver={sendMessageToReceiver}
          handleInput={handleInput}
          isSendButtonDisabled={isSendButtonDisabled}
        />
      )}

      <AnimatePresence initial={false}>
        {createdAt._seconds <= oneHourAgo ? (
          <motion.div
            key="timeout"
            className={styles.greyOverlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <p>
              <strong>{t("ANSWER_VISITOR.TIMED_OUT")}</strong>
            </p>
            <p>
              {hostReplied ? (
                <>
                  <span>
                    {t("ANSWER_VISITOR.REPLY", {
                      name: company ? whoReplied : t("YOU"),
                    })}
                    :
                  </span>{" "}
                  "{hostReplied}"
                </>
              ) : (
                `${t(`ANSWER_VISITOR.NO_REPLY${company ? "_COMPANY" : ""}`)}...`
              )}
            </p>
          </motion.div>
        ) : loading ? (
          <motion.div
            key="loading"
            className={styles.greyOverlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LoadingSpinner />
          </motion.div>
        ) : (
          showSuccessOverlay && (
            <motion.div
              key="success"
              className={styles.messageSentOverlay}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <>
                <div>
                  {letInAt && (
                    <p>
                      <span>{t("BUILDING_VISITORS.ACCESS_CODE_SENT")} </span>
                      {formatTimestampToHoursAndMinutes(letInAt)}
                    </p>
                  )}
                  {(message || hostReplied) && (
                    <p>
                      <span>
                        {t("ANSWER_VISITOR.REPLY", {
                          name: company ? whoReplied : t("YOU"),
                        })}
                        :
                      </span>{" "}
                      "{message || hostReplied}"
                    </p>
                  )}
                </div>

                <div className={styles.buttonRow}>
                  {message || hostReplied ? (
                    <Button secondary onClick={resetReplyState}>
                      {t("ANSWER_VISITOR.ANOTHER_MESSAGE")}
                    </Button>
                  ) : (
                    <Button secondary onClick={resetReplyState}>
                      {t("ANSWER_VISITOR.SEND_MESSAGE_TOO")}
                    </Button>
                  )}

                  {!letInAt && (
                    <Button
                      secondary
                      icon="key"
                      isLoading={letInLoading}
                      disabled={letInLoading || !!letInAt}
                      onClick={letVisitorIn}
                    >
                      {t("ANSWER_VISITOR.LET_IN")}
                    </Button>
                  )}
                </div>
              </>
            </motion.div>
          )
        )}
      </AnimatePresence>
    </div>
  );
}

interface QuickResponseProps {
  message: string;
  sendMessageToReceiver: (messageOverride?: string | null) => void;
  handleInput: (input: string) => void;
  isSendButtonDisabled: boolean;
}

const QuickResponse = ({
  message,
  sendMessageToReceiver,
  handleInput,
  isSendButtonDisabled,
}: QuickResponseProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.quickAnswer}>
        <h4>{t("ANSWER_VISITOR.QUICKLY")}</h4>
        <p>{t("ANSWER_VISITOR.BE_THERE")}...</p>
        <Button
          dark
          onClick={() =>
            sendMessageToReceiver(
              t("ANSWER_VISITOR.PREDEFINED_MESSAGE_X_MIN", {
                minutes: 5,
              }),
            )
          }
        >
          5 min
        </Button>
        <Button
          dark
          onClick={() =>
            sendMessageToReceiver(
              t("ANSWER_VISITOR.PREDEFINED_MESSAGE_X_MIN", {
                minutes: 10,
              }),
            )
          }
        >
          10 min
        </Button>
      </div>
      <div>
        <h4>{t("ANSWER_VISITOR.CUSTOM_MESSAGE")}</h4>
        <div className={styles.messageContainer}>
          <InputField
            label={t("ANSWER_VISITOR.LABEL")}
            placeholder={t("ANSWER_VISITOR.PLACEHOLDER") + "..."}
            compact
            hideLabel
            value={message}
            onChange={(e) => handleInput(e.target.value)}
            maxLength={100}
            inputBgColor="var(--wlcm-color-inactive-grey)"
            append={
              <Button
                dark
                disabled={isSendButtonDisabled}
                onClick={() => sendMessageToReceiver()}
              >
                <Icon name="send" />
              </Button>
            }
          />
        </div>
      </div>
    </>
  );
};
