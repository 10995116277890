import {
  createContext,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import ConfirmationModal from "@/components/ConfirmationModal";

export type ConfirmPropsType = {
  title?: string;
  subtitle?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
} | null;

export type ConfirmContextType = {
  show: (options: ConfirmPropsType) => void;
} | null;

export const ConfirmContext = createContext<ConfirmContextType>(null);

interface Props {
  children: ReactNode;
}
export default function ConfirmProvider({ children }: Props) {
  const [confirm, setConfirm] = useState<ConfirmPropsType>(null);
  const [open, toggle] = useState(false);
  const show = useCallback(
    (confirmOptions: ConfirmPropsType) => {
      setConfirm(confirmOptions);
      toggle(true);
    },
    [toggle],
  );
  const onConfirm = () => {
    confirm?.onConfirm?.();
    toggle(false);
  };
  const onCancel = () => {
    confirm?.onCancel?.();
    toggle(false);
  };
  const value = useMemo(() => ({ show }), [show]);
  return (
    <ConfirmContext.Provider value={value}>
      <ConfirmationModal
        {...confirm}
        onCancel={onCancel}
        onConfirm={onConfirm}
        open={open}
      />
      {children}
    </ConfirmContext.Provider>
  );
}
