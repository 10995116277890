import { HTMLAttributes } from "react";
import styles from "./breadcrumbs.module.scss";

interface BreadcrumbProps extends HTMLAttributes<HTMLDivElement> {
  levels: {
    label: string;
    path: string;
  }[];
  onNavigate: (path: string) => void;
}

export function Breadcrumbs({ levels, onNavigate }: BreadcrumbProps) {
  return (
    <nav aria-label="breadcrumb" className={styles.breadcrumbs}>
      <ol>
        {levels.map((level, index) => (
          <>
            <li key={level.path}>
              <button
                className={
                  index === levels.length - 1 ? styles.currentLevel : ""
                }
                role="link"
                aria-current={index === levels.length - 1}
                onClick={() => onNavigate(level.path)}
              >
                {level.label}
              </button>
            </li>
            {index !== levels.length - 1 && (
              <span key={index} aria-hidden className="no-select">
                /
              </span>
            )}
          </>
        ))}
      </ol>
    </nav>
  );
}
