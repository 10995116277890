import { useSnackbarStore } from "@/store/zustandStore";
import { ExtendedUser, NotificationType, User } from "@/types";
import {
  PopupCard,
} from "@app-components";
import { useState } from "react";
import { getUser } from "@/api";
import { PersonSearch } from "./PersonSearch";

type AddAdminProps = {
  header: string;
  body: string;
  noResultsText: string;
  existingAdmins: ExtendedUser[] | User[];
  onClose: () => void;
  onAdd: (user: User) => Promise<void>;
};

export default function AddAdmin({
  header,
  body,
  noResultsText,
  existingAdmins,
  onClose,
  onAdd,
}: AddAdminProps) {
  const { addNotification } = useSnackbarStore();
  const [searchQuery, setSearchQuery] = useState("");
  const [foundUser, setFoundUser] = useState<User | null | undefined>(); // undefined before search, null if no user is found
  const [isSearching, setIsSearching] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const searchForUser = async () => {
    if (!searchQuery) {
      setFoundUser(undefined);
      return;
    }
    setIsSearching(true);
    try {
      const user = await getUser("email", searchQuery);
      setFoundUser(user);
    } catch (error) {
      console.error(error);
      setFoundUser(null);
    } finally {
      setIsSearching(false);
    }
  };

  const foundUserIsAlreadyAdmin = existingAdmins.some(
    (admin) => admin.userId === foundUser?.userId,
  );

  const addUserAsAdmin = async () => {
    if (!foundUser) return;
    setIsAdding(true);
    try {
      await onAdd(foundUser);
      addNotification(
        "Successfully added administrator",
        NotificationType.SUCCESS,
      );
      onClose();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error);
        addNotification("Failed to add administrator" + error.message);
      } else {
        console.error(error);
        addNotification("An unexpected error occurred.");
      }
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <PopupCard onClose={onClose}>
      <PersonSearch
        header={header}
        body={body}
        noResultsText={noResultsText}
        searchQuery={searchQuery}
        dark
        setSearchQuery={setSearchQuery}
        isSearching={isSearching}
        searchForUser={searchForUser}
        foundUser={foundUser}
        isAddButtonDisabled={isAdding || foundUserIsAlreadyAdmin}
        addUser={addUserAsAdmin}
        isLoading={isAdding}
        />
    </PopupCard>
  );
}
