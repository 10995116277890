import { useCallback, useEffect } from "react";
import { useBlocker } from "@tanstack/react-router";
import { useConfirm } from "@/hooks/useConfirm";
import type { ConfirmPropsType } from "@/providers/ConfirmProvider";

export const usePrompt = ({
  isOpen = false,
  promptOnNavigate = true,
  title,
  subtitle,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}: ConfirmPropsType & { isOpen?: boolean; promptOnNavigate?: boolean }) => {
  const { proceed, reset, status } = useBlocker({
    condition: isOpen,
  });
  const { show } = useConfirm();
  const confirm = useCallback(() => {
    if (!isOpen) return Promise.resolve(true);
    return new Promise<boolean>((resolve) => {
      show({
        title,
        subtitle,
        confirmText,
        cancelText,
        onConfirm: () => {
          resolve(true);
          onConfirm?.();
        },
        onCancel: () => {
          resolve(false);
          onCancel?.();
        },
      });
    });
  }, [
    cancelText,
    confirmText,
    isOpen,
    onCancel,
    onConfirm,
    show,
    subtitle,
    title,
  ]);

  useEffect(() => {
    if (
      (promptOnNavigate && status === "blocked") ||
      (!promptOnNavigate && isOpen)
    ) {
      confirm().then((result) => {
        if (result) proceed();
        else reset();
      });
    }
  }, [status, confirm, isOpen]);

  useEffect(() => {
    if (isOpen) {
      window.onbeforeunload = () => subtitle;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [isOpen, subtitle]);

  return {
    confirm,
  };
};
