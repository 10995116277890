import { Icon } from "@app-components";
import { HeaderContext } from "@tanstack/react-table";
import styles from "@/styles/SortableTableHeader.module.scss";
import { HTMLAttributes } from "react";
import { Tooltip } from "react-tooltip";

interface SortableTableHeaderProps extends HTMLAttributes<HTMLDivElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info: HeaderContext<any, string>;
  tableHeaderId: string;
  label: string;
  columnInfo?: string;
}

export default function SortableTableHeader({
  info,
  tableHeaderId,
  label,
  columnInfo,
  ...rest
}: SortableTableHeaderProps) {
  const currColumnSort = info.table
    .getState()
    .sorting.find((s) => s.id === tableHeaderId);

  return (
    <div className={styles.container} {...rest}>
      {label}
      {columnInfo && (
        <>
          <Icon
            className="table-column-info-icon"
            style={{
              marginLeft: 5,
              color: "var(--wlcm-color-blue)",
              fontSize: "1.25em",
            }}
            name="info"
          />
          <Tooltip
            anchorSelect=".table-column-info-icon"
            content={columnInfo}
            place="top-start"
            style={{ width: "100%", minWidth: "10rem", maxWidth: "20rem" }}
          />
        </>
      )}
      <button
        aria-label={currColumnSort?.desc ? "Sort ascending" : "Sort descending"}
        className={currColumnSort ? styles.activeSortBtn : ""}
        onClick={() => {
          info.table.setSorting([
            {
              id: tableHeaderId,
              desc: currColumnSort ? !currColumnSort.desc : false,
            },
          ]);
        }}
      >
        <Icon
          className={
            currColumnSort?.desc ? styles.descendingIcon : styles.ascendingIcon
          }
          name="arrow_back"
        />
      </button>
    </div>
  );
}
