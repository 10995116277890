import { updateAccessCard, useBuilding } from "@/api";
import { useLoggedInUser } from "@/providers/AuthProvider";
import { useSnackbarStore } from "@/store/zustandStore";
import { CardStatus, NotificationType, User } from "@/types";
import { Button, InputField } from "@app-components";
import { useForm } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import styles from "@/styles/Access.module.scss";
import { z } from "zod";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { MESSAGE_REGEX } from "@/lib/constants";

interface ActivateCardFormProps {
  cardId: string;
  recipient?: User;
  onClose: () => void;
}

export function ActivateCardForm({
  cardId,
  recipient,
  onClose,
}: ActivateCardFormProps) {
  const { t } = useTranslation();
  const user = useLoggedInUser();
  const queryClient = useQueryClient();
  const { data: building } = useBuilding();
  const { addNotification } = useSnackbarStore();

  const form = useForm({
    defaultValues: {
      cardNumber: "",
      pin: "",
      pickupInstructions: "",
    },
    onSubmit: async ({ value }) => {
      if (!recipient) {
        addNotification("Can't activate card because there is no recipient");
        return;
      }
      try {
        await updateAccessCard(
          cardId,
          {
            ...value,
            status: CardStatus.ACTIVE,
          },
          user.userId,
        );
        queryClient.invalidateQueries({
          queryKey: ["access-cards", recipient.userId],
        });
        queryClient.invalidateQueries({ queryKey: ["extended-access-cards"] });
        queryClient.invalidateQueries({ queryKey: ["users"] });
        addNotification(
          `Activated access card for user ${recipient.firstName} ${recipient.lastName}`,
          NotificationType.SUCCESS,
        );
        onClose();
      } catch (error) {
        console.error(error);
        addNotification("Failed to activate access card. Try again later!");
      }
    },
    validatorAdapter: zodValidator(),
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "var(--wlcm-spacing-xs)",
      }}
    >
      <div style={{ flex: 1 }}>
        <form.Field
          name="cardNumber"
          validators={{
            onBlur: z
              .string()
              .min(
                1,
                t("VALIDATION.REQUIRED", {
                  field: t("ACCESS_CARD.TABLE_HEADERS.CARD_NUMBER"),
                }),
              )
              .min(
                2,
                t("VALIDATION.MIN_LENGTH", {
                  field: t("ACCESS_CARD.TABLE_HEADERS.CARD_NUMBER"),
                  length: 2,
                  type: t("NUMBERS"),
                }),
              )
              .max(
                20,
                t("VALIDATION.MAX_LENGTH", {
                  field: t("ACCESS_CARD.TABLE_HEADERS.CARD_NUMBER"),
                  length: "20",
                  type: t("NUMBERS"),
                }),
              )
              .trim()
              .refine(
                (value) =>
                  !isNaN(value as unknown as number) &&
                  !isNaN(parseFloat(value)),
                {
                  message: t("VALIDATION.NUMBER", {
                    field: t("ACCESS_CARD.TABLE_HEADERS.CARD_NUMBER"),
                  }),
                },
              ),
          }}
        >
          {(field) => (
            <InputField
              required
              dark
              compact
              type="number"
              label={t("ACCESS_CARD.TABLE_HEADERS.CARD_NUMBER")}
              placeholder="000000000"
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              dangerText={field.state.meta.errors.toString()}
            />
          )}
        </form.Field>
      </div>
      <div style={{ flex: 1 }}>
        <form.Field
          name="pin"
          validators={{
            onBlur: z
              .string()
              .length(
                4,
                t("VALIDATION.EXACT_LENGTH", {
                  field: "PIN",
                  length: "4",
                  type: t("NUMBERS"),
                }),
              )
              .trim()
              .refine(
                (value) =>
                  !isNaN(value as unknown as number) &&
                  !isNaN(parseFloat(value)),
                {
                  message: t("VALIDATION.NUMBER", { field: "PIN" }),
                },
              ),
          }}
        >
          {(field) => (
            <InputField
              required
              dark
              compact
              type="number"
              label="PIN"
              placeholder="0000"
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              dangerText={field.state.meta.errors.toString()}
            />
          )}
        </form.Field>
      </div>
      <div style={{ width: "100%" }}>
        <form.Field
          name="pickupInstructions"
          validators={{
            onChange: z
              .string()
              .max(
                150,
                t("VALIDATION.MAX_LENGTH", {
                  field: t("ACCESS_CARD.MESSAGE"),
                  length: "150",
                  type: t("CHARACTERS"),
                }),
              )
              .trim()
              .refine((value) => MESSAGE_REGEX.test(value), {
                message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
              }),
          }}
        >
          {(field) => (
            <InputField
              dark
              maxLength={150}
              label={t("ACCESS_CARD.MESSAGE")}
              prepend={
                recipient &&
                building.defaultAccessCardPickupMsg &&
                building.defaultAccessCardPickupMsg[
                  recipient.language as "en" | "no"
                ] &&
                !field.state.value && (
                  <button
                    className={styles.useDefaultButton}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      field.handleChange(
                        building.defaultAccessCardPickupMsg![
                          recipient.language as "en" | "no"
                        ],
                      );
                    }}
                  >
                    {t("USE_DEFAULT")}
                  </button>
                )
              }
              placeholder={
                recipient &&
                building.defaultAccessCardPickupMsg &&
                building.defaultAccessCardPickupMsg[
                  recipient.language as "en" | "no"
                ]
                  ? ` ... ${t("ACCESS_CARD.DETAILS_MODAL.CUSTOM_MESSAGE")}`
                  : ""
              }
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              onClear={() => field.handleChange("")}
              dangerText={field.state.meta.errors.toString()}
              infoText={
                recipient?.language &&
                t("ACCESS_CARD.DETAILS_MODAL.USERS_PREFFERED_LANGUAGE", {
                  language: t(`LANG_${recipient.language.toUpperCase()}`),
                })
              }
            />
          )}
        </form.Field>
      </div>
      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
        children={([canSubmit, isSubmitting]) => (
          <Button
            style={{ marginLeft: "auto" }}
            type="submit"
            disabled={isSubmitting || !canSubmit}
            isLoading={isSubmitting}
          >
            {t("ACCESS_CARD.ACTIONS.ACTIVATE")}
          </Button>
        )}
      />
    </form>
  );
}
