import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  loader: ({ context }) => {
    if (context.company.visitMode === "enabled") {
      redirect({ to: "/visitors", throw: true });
    } else if (context.handbookLink) {
      redirect({ to: "/handbook", throw: true });
    } else if (context.bookingLink) {
      redirect({ to: "/bookings", throw: true });
    } else if (context.user.adminInCompanies.length > 0) {
      redirect({ to: "/company/users", throw: true });
    } else {
      redirect({ to: "/settings", throw: true });
    }
  },
});
