import { Icon } from "@app-components";
import styles from "@/styles/Snackbar.module.scss";
import { useSnackbarStore } from "@/store/zustandStore";
import { AnimatePresence, motion } from "framer-motion";

const iconMatrix = {
  success: "check_circle",
  error: "emergency",
  info: "info",
  warning: "emergency",
};

interface Props {
  completeLeftAlign?: boolean;
}

export default function Snackbar({ completeLeftAlign }: Props) {
  const { notifications, removeNotification } = useSnackbarStore();

  return (
    <div
      className={`${styles.container} ${completeLeftAlign ? styles.completeLeftAlign : ""}`}
    >
      <div className={styles.snackbar}>
        <AnimatePresence>
          {notifications.map((ntf) => (
            <motion.div
              key={ntf.id}
              className={`${styles.notification} ${styles[ntf.type + "Notification"]}`}
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -20, opacity: 0 }}
            >
              <Icon name={iconMatrix[ntf.type]} /> <p>{ntf.message}</p>{" "}
              <button
                onClick={() => removeNotification(ntf.id)}
                aria-label="Remove notification"
              >
                <Icon name="close" />
              </button>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}
