import { createFileRoute } from "@tanstack/react-router";
import { GroupPreregistration } from "@/components/pre-registration/GroupPreregistration";
import { useTranslation } from "react-i18next";

export const Route = createFileRoute("/building/visitors/preregistration")({
  component: Preregistration,
});

function Preregistration() {
  const { t } = useTranslation();

  const breadcrumbs = [
    { label: t("SIDEBAR.BUILDING_VISITORS"), path: "/building/visitors" },
    {
      label: t("PAGES.VISITORS.PREREGISTRATION"),
      path: "/building/visitors/preregistration",
    },
  ];
  return (
    <GroupPreregistration
      noResultsText={t("PREREGISTRATION.NO_RESULTS_BUILDING")}
      showAddHost
      breadcrumbs={breadcrumbs}
      showCompany
    />
  );
}
