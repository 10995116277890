import {
  useQuery,
  UseQueryResult,
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import client, { throwCaughtError } from "../client";
import {
  AccessCardHistory,
  AccessCardType,
  CardOrderedBy,
  CardType,
} from "@/types";

export const useAccessCards = (
  userId: string,
  omitPin = false,
): UseSuspenseQueryResult<AccessCardType[]> => {
  return useSuspenseQuery({
    queryKey: ["access-cards", userId, omitPin],
    queryFn: async () => {
      try {
        const response = await client.get(
          `/getAccessCardsByUserId?userId=${userId}&omitPin=${omitPin}`,
        );
        return response.data.cards;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });
};

export const useExtendedAccessCards = (
  requestsOnly?: boolean,
): UseSuspenseQueryResult<AccessCardType[]> => {
  return useSuspenseQuery({
    queryKey: ["extended-access-cards", requestsOnly],
    queryFn: async () => {
      try {
        const response = await client.get(
          `/getExtendedAccessCards?requestsOnly=${requestsOnly}`,
        );
        return response.data.requests;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });
};

export const useAccessCardHistory = (
  cardId: string,
): UseQueryResult<AccessCardHistory[]> => {
  return useQuery({
    queryKey: ["access-card-history", cardId],
    queryFn: async () => {
      try {
        const response = await client.get(
          `/getAccessCardHistoryById?cardId=${cardId}`,
        );
        return response.data.history;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });
};

export const createAccessCard = async (
  type: CardType,
  orderedBy: CardOrderedBy,
  userId: string,
  orderNote?: string,
) => {
  try {
    await client.post(`/createAccessCard`, {
      type,
      orderedBy,
      userId,
      orderNote,
    });
  } catch (error) {
    throwCaughtError(error);
  }
};

export const updateAccessCard = async (
  cardId: string,
  updateData: Partial<AccessCardType>,
  modifiedByUserId?: string,
) => {
  try {
    await client.patch(`/updateAccessCard`, {
      cardId,
      updateData,
      modifiedByUserId,
    });
  } catch (error) {
    throwCaughtError(error);
  }
};
