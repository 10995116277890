import { updateAccessCard } from "@/api";
import { MESSAGE_REGEX } from "@/lib/constants";
import { useLoggedInUser } from "@/providers/AuthProvider";
import { useSnackbarStore } from "@/store/zustandStore";
import { CardStatus, NotificationType, User } from "@/types";
import { Button, InputField } from "@app-components";
import { useForm } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useTranslation } from "react-i18next";
import { z } from "zod";

interface DeactivateCardFormProps {
  cardId: string;
  cardNumber: string;
  recipient?: User;
  onClose: () => void;
}

export default function DeactivateCardForm({
  cardId,
  cardNumber,
  recipient,
  onClose,
}: DeactivateCardFormProps) {
  const { t } = useTranslation();
  const user = useLoggedInUser();
  const queryClient = useQueryClient();
  const { addNotification } = useSnackbarStore();

  const deactivationForm = useForm({
    defaultValues: {
      reasonForDeactivation: "",
    },
    onSubmit: async ({ value }) => {
      try {
        await updateAccessCard(
          cardId,
          {
            ...value,
            status: CardStatus.DEACTIVATED,
          },
          user.userId,
        );
        queryClient.invalidateQueries({
          queryKey: ["access-cards", recipient?.userId],
        });
        queryClient.invalidateQueries({
          queryKey: ["extended-access-cards"],
        });
        addNotification(
          `Deactivated access card with card number ${cardNumber}`,
          NotificationType.SUCCESS,
        );
        onClose();
      } catch (error) {
        console.error(error);
        addNotification("Failed to activate access card. Try again later!");
      }
    },
    validatorAdapter: zodValidator(),
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        deactivationForm.handleSubmit();
      }}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <deactivationForm.Field
        name="reasonForDeactivation"
        validators={{
          onChange: z
            .string()
            .max(
              150,
              t("VALIDATION.MAX_LENGTH", {
                field: t("ACCESS_CARD.REASON_FOR_DEACTIVATION"),
                length: "150",
                type: t("CHARACTERS"),
              }),
            )
            .trim()
            .refine((value) => MESSAGE_REGEX.test(value), {
              message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
            }),
        }}
      >
        {(field) => (
          <InputField
            dark
            required
            maxLength={150}
            label={t("ACCESS_CARD.REASON_FOR_DEACTIVATION")}
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
          />
        )}
      </deactivationForm.Field>
      <deactivationForm.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
        children={([canSubmit, isSubmitting]) => (
          <Button
            style={{ marginLeft: "auto" }}
            type="submit"
            disabled={isSubmitting || !canSubmit}
            isLoading={isSubmitting}
          >
            {t("ACCESS_CARD.ACTIONS.DEACTIVATE")}
          </Button>
        )}
      />
    </form>
  );
}
