export enum NotificationType {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
}

export type storeType = {
  notifications: {
    id: number;
    message: string;
    type: NotificationType;
  }[];
  addNotification: (message: string, type?: NotificationType) => void;
  removeNotification: (id: number) => void;
};

export type userStoreType = {
  userName: string;
  setUserName: (userName: string) => void;
};

export type contextStoreType = {
  selectedCompany: SelectChild | undefined;
  setCompany: ({ value, label }: SelectChild) => void;
};

export type Timestamp = {
  _seconds: number;
  _nanoseconds: number;
};

export interface CompanyVisit {
  id: string;
  entranceId: string;
  entranceName: string;
  buildingId: string;
  buildingTenantId: string;
  buildingTenantName: string;
  language: string;
  messageFromVisitor: string;
  visitorPhone: string;
  visitorCompanyName: string;
  visitorId: string;
  visitorName: string;
  createdAt: Timestamp;
  hostReplied?: string;
  whoReplied?: string;
  // letIn: boolean;
  letInAt?: Timestamp;
}

export interface Visit extends CompanyVisit {
  hostId: string;
  hostPhone: string;
  hostName: string;
}

type PropertyManager = {
  firstName: string;
  lastName: string;
  position: {
    no: string;
    en: string;
  };
  managerProfileImgUrl: string;
};

export enum AccessMode {
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export enum AccessSystem {
  STARWATCH = "starwatch",
}

export type Building = {
  propertyManager: PropertyManager;
  buildingBannerImgUrl: string;
  buildingAdmins: string[];
  displayName: string;
  legalName: string;
  owner: string;
  ownerLogoImgUrl: string;
  defaultAccessCardPickupMsg?: {
    no: string;
    en: string;
  };
  accessMode?: AccessMode;
  accessSystem?: AccessSystem;
  buildingAdminsNotifications?: string[];
};

// Building + fields for Base64 encoded images
export type BuildingForm = Omit<Building, "buildingAdmins"> & {
  propertyManager?: PropertyManager & {
    managerProfileImg?: string;
  };
  buildingBannerImg: string;
  ownerLogoImg: string;
};

export type Entrance = {
  entranceId?: string;
  entranceName: {
    no: string;
    en: string;
  };
  address: string;
};

export interface User {
  buildingTenantId: string;
  adminInCompanies: string[];
  email: string;
  phone: string;
  userId: string;
  firstName: string;
  lastName: string;
  visibleInVisit: boolean;
  language: string;
  generalConsent: boolean;
  userActive?: boolean;
  userProfileImgUrl?: string;
  companyContact?: boolean;
  activeAccessCardNumber?: string;
}

export interface ExtendedUser extends User {
  buildingTenantName: string;
}

export interface CompanyContact {
  userId: string;
  name: string;
  email: string;
}

export enum ConsentType {
  GENERAL = "general",
}

export type Consent = {
  id?: string;
  type: ConsentType;
  userId: string;
  consented: boolean;
  timestamp: Timestamp;
};

export enum VisitMode {
  ENABLED = "enabled",
  LIMITED = "limited",
  DISABLED = "disabled",
}

export type SSOItem = {
  provider: string;
  domain: string;
};

export interface Company {
  buildingTenantId: string;
  buildingTenantName: string;
  entranceIds: string[];
  floor?: string;
  companyLogoImgUrl?: string;
  orgNumber: string;
  ssoSettings: SSOItem[];
  visitMode: VisitMode;
  sso: boolean;
}

export interface ExtendedCompany extends Company {
  totalUsers: number;
  activeUsers: number;
}

export type SelectChild = {
  value: string;
  label: string;
};

export type AddUserType = {
  firstName: string;
  lastName: string;
  buildingTenantId: string;
  adminInCompanies: string[];
  email: string;
  phone?: string;
  visibleInVisit: boolean;
  companyContact?: boolean;
  sso?: boolean;
};

export type CsvData = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
};

export type BronnoysundCompanyResult = {
  organisasjonsnummer: string;
  navn: string;
};

export enum CardType {
  PHYSICAL = "physical",
  DIGITAL = "digital",
}

export enum CardStatus {
  ORDERED = "ordered",
  ACTIVE = "active",
  INACTIVE = "inactive",
  DEACTIVATED = "deactivated",
  CANCELLED = "cancelled", // order cancelled
}

export type CardOrderedBy = {
  userId: string;
  company: string;
  name?: string;
  email?: string;
};

export type AccessCardType = {
  id: string;
  type: CardType;
  orderedBy: CardOrderedBy;
  orderNote?: string;
  status: CardStatus;
  createdAt: Timestamp;
  activatedAt?: Timestamp;
  inactivatedAt?: Timestamp;
  deactivatedAt?: Timestamp;
  cancelledAt?: Timestamp;
  cardNumber?: string;
  pin?: string;
  userId: string;
  userName?: string;
  pickupInstructions?: string;
  reasonForDeactivation?: string;
  note?: string;
};

export type AccessCardHistory = {
  cardId: string;
  status: CardStatus;
  timestamp: Timestamp;
  modifiedByUserId: string;
};

export type PreRegistrationAttendee = {
  name: string;
  phone: string;
  email?: string;
  arrivedAt?: Timestamp;
};
export type PreRegistrationGroup = {
  id: string;
  hostId: string;
  hostName?: string;
  companyName: string;
  companyId: string;
  eventName: string;
  eventDescription?: string;
  startTime: Timestamp;
  attendees: PreRegistrationAttendee[];
  entranceId: string;
};

export type ExtendedPreRegistrationAttendee = {
  name: string;
  phone: string;
  email?: string;
  arrivedAt?: Timestamp;
  startTime: Timestamp;
  hostName?: string;
  eventName: string;
  companyName: string;
};
