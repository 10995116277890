import { Button, Icon } from "@app-components";
import { Table } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

interface TablePaginationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>;
  totalItems: number;
  localStoragePageSizeKey: string;
  customPageSizeOptions?: number[];
}

export default function TablePagination({
  table,
  totalItems,
  localStoragePageSizeKey,
  customPageSizeOptions
}: TablePaginationProps) {
  const { t } = useTranslation();

  function getPaginationText() {
    const totalItemsCount = totalItems;
    const numberOfItemsPerPage = table.getState().pagination.pageSize;
    const currPage = table.getState().pagination.pageIndex;
    const start = currPage * numberOfItemsPerPage + 1;
    const end = Math.min(start + numberOfItemsPerPage - 1, totalItemsCount);

    return `${start} - ${end} ${t("OF")} ${totalItemsCount}`;
  }

  const changeRowsPerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    localStorage.setItem(localStoragePageSizeKey, e.target.value);
    table.setPageSize(Number(e.target.value));
  };

  const pageSizeOptions = customPageSizeOptions ?? [15, 20, 30, 40, 50];

  return (
    <div className="paginationContainer">
      <div>
        <p>{t("ROWS_PER_PAGE")}:</p>
        <select
          value={table.getState().pagination.pageSize}
          onChange={changeRowsPerPage}
        >
          {pageSizeOptions.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div>
        <Button
          secondary
          onClick={() => table.firstPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <Icon name="double_arrow_left" />
        </Button>
        <Button
          secondary
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <Icon name="arrow_back" />
        </Button>
        <p>{getPaginationText()}</p>
        <Button
          secondary
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <Icon name="arrow_forward" />
        </Button>
        <Button
          secondary
          onClick={() => table.lastPage()}
          disabled={!table.getCanNextPage()}
        >
          <Icon name="double_arrow_right" />
        </Button>
      </div>
    </div>
  );
}
