import styles from "@/styles/Users.module.scss";
import { useTranslation, Trans } from "react-i18next";
import { WELCOME_USER_ADMINISTRATION_URL } from "@/lib/constants";
import i18n from "@/i18n/config";
import { createFileRoute } from "@tanstack/react-router";

import { User } from "@/types";
import { Button, InputField } from "@app-components";
import { useEffect, useState, lazy, Suspense } from "react";
import { useUploadUsers } from "@/hooks/uploadUsers";
import { useContextStore } from "@/store/zustandStore";
import { Data } from "react-spreadsheet-import/types/types";
import AddUserModal from "@/components/company_admin/company_users/AddUserModal";
import { companyOptions, companyUsersOptions } from "@/api";
import { useQuery } from "@tanstack/react-query";
import UsersTable from "@/components/user/UsersTable";
const SpreadsheetImporter = lazy(
  () => import("@/components/user/SpreadsheetImporter"),
);

const searchableColumns: Array<keyof User> = [
  "firstName",
  "lastName",
  "email",
  "phone",
];

export const Route = createFileRoute("/company/users/")({
  component: Users,
});

function Users() {
  const { t } = useTranslation();
  const { selectedCompany } = useContextStore();

  const {
    data: users,
    isLoading,
    error: companyUsersError,
  } = useQuery(companyUsersOptions(selectedCompany?.value));
  const { data: company, error: companyError } = useQuery(
    companyOptions(selectedCompany?.value || ""),
  );

  const [addUserOpen, setAddUserOpen] = useState(false);
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [emailList, setEmailList] = useState<string[]>([]);
  const { submitUsers } = useUploadUsers(() => setBulkUploadOpen(false));

  useEffect(() => {
    if (users) {
      const emails = users.map((user: User) => user.email);
      setEmailList(emails);
    }
  }, [users]);

  if (companyUsersError || companyError) {
    throw new Error("Error fetching company users", {
      cause: companyUsersError || companyError,
    });
  }

  useEffect(() => {
    if (searchTerm && users) {
      // get every user that has an attribute from searchableColumns that starts with the searchTerm
      setFilteredUsers(
        users.filter((user: User) =>
          searchableColumns.some((column) =>
            (user[column]?.toString().toLowerCase() || "").startsWith(
              searchTerm.toLowerCase(),
            ),
          ),
        ),
      );
    } else {
      setFilteredUsers(users || []);
    }
  }, [searchTerm, users]);

  const addMultipleUsers = async (data: Data<string>[]) => {
    const usersToAdd = data.map((user) => ({
      firstName: user.firstName as string,
      lastName: user.lastName as string,
      email: user.email as string,
      phone: (user.phone as string) || "",
      buildingTenantId: selectedCompany?.value || "",
      adminInCompanies: [],
      entranceIds: company?.entranceIds || [],
      visibleInVisit:
        user.visibleInVisit === "true" || user.visibleInVisit === true,
      sso: company?.sso || false,
    }));
    await submitUsers(usersToAdd);
  };

  return (
    <>
      <div className="pageHeader">
        <h1>{t("PAGES.USERS.HEADER")}</h1>
        <p>
          <Trans i18nKey="PAGES.USERS.SUBHEADER">
            Text
            <a
              href={WELCOME_USER_ADMINISTRATION_URL(i18n.language)}
              target="_blank"
            ></a>
            .
          </Trans>
        </p>
      </div>
      <>
        <>
          <div className={styles.toolContainer}>
            <InputField
              label={t("USERS.SEARCH_PLACEHOLDER")}
              placeholder={t("USERS.SEARCH_PLACEHOLDER") + "..."}
              icon="search"
              value={searchTerm}
              compact
              hideLabel
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div>
              <Button icon="upload" onClick={() => setBulkUploadOpen(true)}>
                {t("USERS.BULK_UPLOAD.BULK_UPLOAD")}
              </Button>
              <Button icon="add" onClick={() => setAddUserOpen(true)}>
                {t("USERS.ADD_USER")}
              </Button>
            </div>
          </div>
          <UsersTable
            isLoading={isLoading}
            filteredUsers={filteredUsers}
            companyId={selectedCompany?.value || ""}
          />
        </>
        {addUserOpen && (
          <AddUserModal
            onClose={() => setAddUserOpen(false)}
            emailList={emailList}
          />
        )}
        {bulkUploadOpen && company && (
          <Suspense>
            <SpreadsheetImporter
              open={bulkUploadOpen}
              company={company}
              emailList={emailList}
              onClose={() => setBulkUploadOpen(false)}
              onSubmit={(data) => addMultipleUsers(data.validData)}
            />
          </Suspense>
        )}
      </>
    </>
  );
}
