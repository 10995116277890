/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createEntrance,
  deleteEntrance,
  updateEntrance,
  useAllEntrances,
} from "@/api";
import Table from "@/components/table/Table.tsx";
import i18n from "@/i18n/config";
import {
  ADDRESS_REGEX,
  MESSAGE_REGEX,
  WELCOME_VISIT_KIOSK_URL,
} from "@/lib/constants";
import { useSnackbarStore } from "@/store/zustandStore";
import styles from "@/styles/BuildingSettings.module.scss";
import { Entrance, NotificationType } from "@/types";
import { Button, InputField, PopupCard } from "@app-components";
import { useForm } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { z } from "zod";

const columnHelper = createColumnHelper<Entrance>();

export default function EntrancesSection() {
  const { t } = useTranslation();
  const { data: entrances } = useAllEntrances();
  const [addEntranceOpen, setAddEntranceOpen] = useState(false);
  const [editEntrance, setEditEntrance] = useState<Entrance>();

  const tableColumns = useMemo(
    () => [
      columnHelper.accessor("entranceName", {
        cell: (info) => info.getValue()[i18n.language === "en" ? "en" : "no"],
        header: () => (
          <div>{t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.NAME")}</div>
        ),
      }),
      columnHelper.accessor("address", {
        cell: (info) => info.getValue(),
        header: () => (
          <div>{t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.ADDRESS")}</div>
        ),
      }),
      columnHelper.accessor("entranceId", {
        cell: (info) => {
          const url = WELCOME_VISIT_KIOSK_URL(info.getValue()!);
          return (
            <a href={url} target="_blank">
              {url}
            </a>
          );
        },
        header: () => (
          <div>
            {t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.WELCOME_URL")}
          </div>
        ),
      }),
    ],
    [],
  );

  const table = useReactTable({
    data: entrances || [],
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <section style={{ display: "contents" }}>
        <div className={`${styles.fullWidth} ${styles.sectionWithButton}`}>
          <h2>{t("BUILDING_SETTINGS.ENTRANCES.HEADER")}</h2>
          <p className={styles.description}>
            {t("BUILDING_SETTINGS.ENTRANCES.DESCRIPTION")}
          </p>
          <Button
            icon="add"
            onClick={(e) => {
              e.preventDefault();
              setAddEntranceOpen(true);
            }}
          >
            {t("BUILDING_SETTINGS.ENTRANCES.ADD")}
          </Button>
        </div>
        <Table
          table={table}
          className={styles.fullWidth}
          noDataMessage={t("BUILDING_SETTINGS.ENTRANCES.NO_DATA")}
          onRowClick={(row) => setEditEntrance(row)}
        />
      </section>
      {/* createPortal is REQUIRED so the modal form is not rendered inside the <BuildingSettings /> form */}
      {addEntranceOpen &&
        createPortal(
          <AddEntranceModal onClose={() => setAddEntranceOpen(false)} />,
          document.querySelector("main") || document.body,
        )}
      {editEntrance &&
        createPortal(
          <EditEntranceModal
            entrance={editEntrance}
            onClose={() => setEditEntrance(undefined)}
          />,
          document.querySelector("main") || document.body,
        )}
    </>
  );
}

const AddEntranceModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { addNotification } = useSnackbarStore();

  const form = useForm({
    defaultValues: {
      address: "",
      entranceName: {
        en: "",
        no: "",
      },
    },
    onSubmit: async ({ value }) => {
      try {
        const res = await createEntrance(value);
        addNotification(res.message, NotificationType.SUCCESS);
        onClose();
        queryClient.invalidateQueries({ queryKey: ["entrances"] });
      } catch (error) {
        addNotification(
          typeof error === "string"
            ? error
            : "Failed to add entrance for unknown reason.",
        );
      }
    },
    validatorAdapter: zodValidator(),
  });

  return (
    <PopupCard onClose={onClose}>
      <div
        className={styles.addEntranceModal}
        onChange={(e) => e.stopPropagation()}
      >
        <h3>{t("BUILDING_SETTINGS.ENTRANCES.ADD")}</h3>
        <EntranceForm form={form} autoFocus submitText={t("ADD_USER.SUBMIT")} />
      </div>
    </PopupCard>
  );
};

const EditEntranceModal = ({
  entrance,
  onClose,
}: {
  entrance: Entrance;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { addNotification } = useSnackbarStore();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteEntrance_ = async (e: Event) => {
    e.preventDefault();
    setIsDeleting(true);
    try {
      const res = await deleteEntrance(entrance.entranceId!);
      addNotification(res.message, NotificationType.SUCCESS);
      onClose();
      queryClient.invalidateQueries({ queryKey: ["entrances"] });
    } catch (error) {
      addNotification(
        typeof error === "string"
          ? error
          : "Failed to delete entrance for unknown reason.",
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const form = useForm({
    defaultValues: {
      address: entrance.address,
      entranceName: {
        ...entrance.entranceName,
      },
    },
    onSubmit: async ({ value }) => {
      if (!entrance.entranceId) {
        addNotification("Can't update entrance because no ID was found.");
        return;
      }
      setIsUpdating(true);

      try {
        const res = await updateEntrance(entrance.entranceId, value);
        addNotification(res.message, NotificationType.SUCCESS);
        onClose();
        queryClient.invalidateQueries({ queryKey: ["entrances"] });
      } catch (error) {
        addNotification(
          typeof error === "string"
            ? error
            : "Failed to edit entrance for unknown reason.",
        );
      } finally {
        setIsUpdating(false);
      }
    },
    validatorAdapter: zodValidator(),
  });

  return (
    <PopupCard onClose={onClose}>
      <div
        className={styles.addEntranceModal}
        onChange={(e) => e.stopPropagation()}
      >
        <h3>
          {t("BUILDING_SETTINGS.ENTRANCES.EDIT", {
            name: entrance.entranceName[i18n.language === "en" ? "en" : "no"],
          })}
        </h3>
        <EntranceForm form={form} submitText={t("SAVE_CHANGES")} />
        <div className="editDangerArea">
          <h4>{t("EDIT_USER.DANGER")}</h4>
          <Button
            icon="delete"
            disabled={isUpdating || isDeleting}
            isLoading={isDeleting}
            danger
            onClick={deleteEntrance_}
          >
            {t("BUILDING_SETTINGS.ENTRANCES.DELETE")}
          </Button>
        </div>
      </div>
    </PopupCard>
  );
};

const EntranceForm = ({
  form,
  autoFocus = false,
  submitText,
}: {
  form: any;
  autoFocus?: boolean;
  submitText: string;
}) => {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <form.Field
        name="address"
        validators={{
          onBlur: z
            .string()
            .min(
              1,
              t("VALIDATION.REQUIRED", {
                field: t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.ADDRESS"),
              }),
            )
            .max(
              100,
              t("VALIDATION.MAX_LENGTH", {
                field: t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.ADDRESS"),
                length: "100",
                type: t("CHARACTERS"),
              }),
            )
            .trim()
            .refine((value) => ADDRESS_REGEX.test(value), {
              message: t("VALIDATION.INVALID", {
                field: t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.ADDRESS"),
              }),
            }),
        }}
      >
        {(field: any) => (
          <InputField
            required
            dark
            autoFocus={autoFocus}
            label={t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.ADDRESS")}
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(e) => field.handleChange(e.target.value)}
            dangerText={field.state.meta.errors.toString()}
          />
        )}
      </form.Field>
      <fieldset>
        <legend className="sr-only">
          {t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.NAME")}
        </legend>
        <form.Field
          name="entranceName.no"
          validators={{
            onBlur: z
              .string()
              .min(
                1,
                t("VALIDATION.REQUIRED", {
                  field: t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.NAME"),
                }),
              )
              .max(
                30,
                t("VALIDATION.MAX_LENGTH", {
                  field: t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.NAME"),
                  length: "30",
                  type: t("CHARACTERS"),
                }),
              )
              .trim()
              .refine((value) => MESSAGE_REGEX.test(value), {
                message: t("VALIDATION.INVALID", {
                  field: t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.NAME"),
                }),
              }),
          }}
        >
          {(field: any) => (
            <InputField
              required
              dark
              label={t("BUILDING_SETTINGS.ENTRANCES.NAME_NB")}
              placeholder="Inngang A"
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              dangerText={field.state.meta.errors.toString()}
            />
          )}
        </form.Field>
        <form.Field
          name="entranceName.en"
          validators={{
            onBlur: z
              .string()
              .min(
                1,
                t("VALIDATION.REQUIRED", {
                  field: t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.NAME"),
                }),
              )
              .max(
                30,
                t("VALIDATION.MAX_LENGTH", {
                  field: t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.NAME"),
                  length: "30",
                  type: t("CHARACTERS"),
                }),
              )
              .trim()
              .refine((value) => MESSAGE_REGEX.test(value), {
                message: t("VALIDATION.INVALID", {
                  field: t("BUILDING_SETTINGS.ENTRANCES.TABLE_HEADERS.NAME"),
                }),
              }),
          }}
        >
          {(field: any) => (
            <InputField
              required
              dark
              label={t("BUILDING_SETTINGS.ENTRANCES.NAME_EN")}
              placeholder="Entrance A"
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              dangerText={field.state.meta.errors.toString()}
            />
          )}
        </form.Field>
      </fieldset>
      <form.Subscribe
        selector={(state: any) => [state.canSubmit, state.isSubmitting]}
        children={([canSubmit, isSubmitting]: [boolean, boolean]) => (
          <Button
            type="submit"
            disabled={isSubmitting || !canSubmit}
            isLoading={isSubmitting}
          >
            {submitText}
          </Button>
        )}
      />
    </form>
  );
};
