import { PreRegistrationGroup } from "@/types";
import { useTranslation } from "react-i18next";
import VisitorsPlaceholder from "../VisitorsPlaceholder";
import PreregistrationGroupCard from "./PreregistredVisitorCard";
import styles from "@/styles/Visitors.module.scss";

export const PreregistrationBody = ({
  preregistrations,
  isCompany,
}: {
  preregistrations: PreRegistrationGroup[];
  isCompany?: boolean;
}) => {
  const { t } = useTranslation();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Filter out preregistrations that start today
  const filteredPreregistrations = preregistrations.filter(
    (preregistration) => {
      const preregistrationDate = new Date(
        preregistration.startTime._seconds * 1000,
      );
      preregistrationDate.setHours(0, 0, 0, 0);
      return preregistrationDate.getTime() > today.getTime();
    },
  );

  if (filteredPreregistrations.length === 0) {
    return (
      <VisitorsPlaceholder
        icon="groups"
        placeholder={t("PREREGISTRATION.NO_PREREGRISTRATIONS", {
          entity: isCompany ? t("COMPANY") : t("YOU"),
        })}
      />
    );
  }

  return (
    <div className={styles.cards}>
      {filteredPreregistrations
        .sort(
          (a, b) =>
            new Date(a.startTime._seconds * 1000).getTime() -
            new Date(b.startTime._seconds * 1000).getTime(),
        )
        .map((preregistration) => (
          <PreregistrationGroupCard
            key={preregistration.id}
            data={preregistration}
          />
        ))}
    </div>
  );
};
