import { ButtonHTMLAttributes, LegacyRef, ReactNode } from "react";
import { Icon } from "../Icon";
import "./../../globals.scss";
import styles from "./button.module.scss";
import { LoadingDots } from "@app-components";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  innerRef?: LegacyRef<HTMLButtonElement> | null;
  tooltip?: string;
  autoFocus?: boolean;
  className?: string;
  onClick?: (e?: any) => void;
  icon?: string;
  large?: boolean; // legacy
  size?: "sm" | "md" | "lg";
  disabled?: boolean;
  selected?: boolean;
  secondary?: boolean;
  success?: boolean;
  danger?: boolean;
  dark?: boolean;
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
  children: ReactNode;
}

export function Button({
  innerRef,
  tooltip,
  autoFocus,
  className,
  onClick,
  icon,
  large,
  size = "md",
  disabled,
  selected,
  secondary,
  success,
  danger,
  dark,
  isLoading,
  type,
  children,
  ...rest
}: ButtonProps) {
  return (
    <button
      {...rest}
      ref={innerRef ? innerRef : null}
      autoFocus={autoFocus ? true : false}
      disabled={disabled}
      type={type}
      className={`
        ${className ? className : ""} 
        ${styles.button} 
        ${dark ? styles.dark : ""}
        ${secondary ? styles.secondary : ""} 
        ${success ? styles.success : ""} 
        ${danger ? styles.danger : ""} 
        ${large ? styles.large : ""} 
        ${size ? styles[`size-${size}`] : ""}
        ${selected ? styles.selected : ""}
        ${disabled ? styles.disabled : ""}`}
      onClick={onClick}
      title={tooltip}
    >
      {!isLoading ? (
        <>
          {icon && <Icon className={styles.icon} name={icon} />}
          {children}
        </>
      ) : (
        <LoadingDots />
      )}
    </button>
  );
}
