import { InputHTMLAttributes } from "react";
import styles from "./Toggle.module.scss";

interface ToggleProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  label?: string;
  hideLabel?: boolean;
  onChange?: (newValue: boolean) => void;
  checked?: boolean;
  title?: string;
  dark?: boolean;
  yellow?: boolean;
  className?: string;
  disabled?: boolean;
}

export function Toggle({
  label,
  hideLabel,
  onChange,
  checked,
  title,
  dark,
  yellow,
  className,
  disabled,
  id,
  ...rest
}: ToggleProps) {
  return (
    <div className={styles.toggleRow} {...rest}>
      <div className={styles.toggleContainer}>
        <div
          className={`${className ? className : ""} 
          ${styles.toggle} 
          ${dark ? styles.dark : ""}
          ${yellow ? styles.yellow : ""}
          ${disabled ? styles.disabled : ""}
          `}
        >
          <input
            id={id}
            disabled={disabled}
            className={styles.checkBox}
            checked={checked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange && onChange(e.target.checked)
            }
            type="checkbox"
          />
          <span className={styles.roundToggle}></span>
        </div>
      </div>
      {!!label && (
        <label
          htmlFor={`${title}_CHECKBOX`}
          className={hideLabel || title ? "sr-only" : ""}
        >
          {label}
        </label>
      )}
      <p>{title}</p>
    </div>
  );
}
