import "@/styles/index.scss";
import "./i18n/config.ts";
import styles from "@/styles/App.module.scss";
import { StrictMode, Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import AuthProvider, { useLoggedInUser } from "@/providers/AuthProvider";
import LoadingScreen from "@/components/LoadingScreen";
import { LoadingSpinner } from "@app-components";
import ErrorBoundary from "./components/app/ErrorBoundary.tsx";
import Welcome from "./components/welcome_page/index.tsx";
import IntercomProvider from "@/providers/Intercom.tsx";
import MonitoringProvider from "@/providers/Monitoring.tsx";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { useContextStore } from "@/store/zustandStore.ts";
import {
  companyVisitorsOptions,
  personalVisitorsOptions,
  buildingOptions,
  companyOptions,
  useCompany,
} from "@/api";
import PageNotFound from "@/components/app/404.tsx";

import { routeTree } from "./routeTree.gen";

const queryClient = new QueryClient();

export const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultOnCatch(error) {
    window.localStorage.removeItem("selectedCompany");
    throw error;
  },
  defaultPendingComponent: () => (
    <div className="center">
      <LoadingSpinner />
    </div>
  ),
  context: {
    user: undefined!,
    company: undefined!,
    handbookLink: undefined!,
    bookingLink: undefined!,
    queryClient,
  },
  defaultPreloadStaleTime: 0,
  defaultNotFoundComponent: PageNotFound,
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary
        outsideAuthProvider
        className={styles.topLevelErrorBoundary}
      >
        <Suspense fallback={<LoadingScreen />}>
          <MonitoringProvider>
            <AuthProvider>
              <ErrorBoundary className={styles.topLevelErrorBoundary}>
                <IntercomProvider>
                  <RouterProviderWithContext />
                </IntercomProvider>
              </ErrorBoundary>
            </AuthProvider>
          </MonitoringProvider>
        </Suspense>
      </ErrorBoundary>
    </QueryClientProvider>
  </StrictMode>,
);

function RouterProviderWithContext() {
  const user = useLoggedInUser();
  const { data: company } = useCompany(user.buildingTenantId);
  const handbookLink = import.meta.env.VITE_HANDBOOK_LINK;
  const bookingLink = import.meta.env.VITE_BOOKING_LINK;
  const { selectedCompany, setCompany } = useContextStore();
  const { isLoading: isLoadingSelectedCompany, error: selectedCompanyError } =
    useQuery({
      ...companyOptions(selectedCompany?.value || ""),
      enabled: !!selectedCompany?.value,
    });

  useEffect(() => {
    queryClient.prefetchQuery(personalVisitorsOptions(user.userId));
    queryClient.prefetchQuery(buildingOptions);

    if (user.adminInCompanies?.length > 0) {
      queryClient.prefetchQuery(
        companyVisitorsOptions(user.adminInCompanies[0]),
      );
    }
    if (handbookLink && handbookLink !== "null") {
      (document.getElementById("handbook") as HTMLIFrameElement).src =
        handbookLink;
    }
    if (bookingLink && bookingLink !== "null") {
      (document.getElementById("bookings") as HTMLIFrameElement).src =
        bookingLink;
    }
  }, []);

  useEffect(() => {
    if (selectedCompanyError) {
      console.error(
        "Got error with selected company, resetting selected company",
      );
      window.localStorage.removeItem("selectedCompany");
      setCompany({
        label: company.buildingTenantName,
        value: company.buildingTenantId,
      });
    }
  }, [selectedCompanyError]);

  if (!user.userActive) return <Welcome />;
  if (isLoadingSelectedCompany) return <LoadingScreen />;

  return (
    <RouterProvider
      router={router}
      context={{ user, company, handbookLink, bookingLink }}
    />
  );
}
