import { useContextStore, useSnackbarStore } from "@/store/zustandStore";
import { ExtendedUser, NotificationType } from "@/types";
import { Button, Icon, LoadingSpinner } from "@app-components";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import styles from "@/styles/CompanySettings.module.scss";
import { useMemo, useState } from "react";
import { updateUser, useCompanyContact } from "@/api";
import { useQueryClient } from "@tanstack/react-query";

interface CompanyContactSelectorProps {
  user: ExtendedUser;
}

export default function CompanyContactSelector({
  user,
}: CompanyContactSelectorProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { selectedCompany } = useContextStore();
  const { addNotification } = useSnackbarStore();
  const { data: currContact } = useCompanyContact(user.buildingTenantId);
  const [isUpdating, setIsUpdating] = useState(false);
  const isContactForCurrCompany = useMemo(
    () =>
      user.companyContact && user.buildingTenantId === selectedCompany?.value,
    [user, selectedCompany],
  );

  const assignCompanyContact = async () => {
    setIsUpdating(true);
    try {
      if (currContact) {
        await updateUser(currContact.userId, {
          companyContact: false,
        });
      }
      await updateUser(user.userId, { companyContact: true });
      await queryClient.invalidateQueries({
        queryKey: ["company-contact"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["company-admins"],
      });
      addNotification(
        "Assigned new company contact.",
        NotificationType.SUCCESS,
      );
    } catch (error) {
      addNotification("Failed to assign company contact. " + error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <>
      <span className="sr-only">
        {isContactForCurrCompany ? t("YES") : t("NO")}
      </span>
      {isUpdating ? (
        <LoadingSpinner />
      ) : isContactForCurrCompany ? (
        <>
          <Icon name="star" className="company-contact-icon" />
          <Tooltip
            anchorSelect=".company-contact-icon"
            content={t("USERS.TABLE_HEADERS.COMPANY_CONTACT")}
          />
        </>
      ) : user.buildingTenantId === selectedCompany?.value ? (
        <>
          <Button
            className={`make-company-contact-btn ${styles.companyContactBtn}`}
            onClick={assignCompanyContact}
          >
            <Icon name="star_outlined" />
            <span className="sr-only">
              {t("USERS.ASSIGN_COMPANY_CONTACT.LABEL")}
            </span>
          </Button>
          <Tooltip
            anchorSelect=".make-company-contact-btn"
            content={t("USERS.ASSIGN_COMPANY_CONTACT.LABEL")}
          />
        </>
      ) : (
        <>
          <Button
            className={`disabled-company-contact-btn ${styles.companyContactBtn}`}
            disabled={true}
          >
            <Icon name="star_outlined" />
            <span className="sr-only">
              {t("USERS.ASSIGN_COMPANY_CONTACT.LABEL")}
            </span>
          </Button>
          <Tooltip
            anchorSelect=".disabled-company-contact-btn"
            content={t("USERS.ASSIGN_COMPANY_CONTACT.DISABLED")}
            style={{
              backgroundColor: "var(--wlcm-color-danger-red)",
            }}
          />
        </>
      )}
    </>
  );
}
