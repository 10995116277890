import { HTMLAttributes } from "react";
import "./../../globals.scss";
import styles from "./icon.module.scss";

interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  name: string;
  className?: string;
  large?: boolean;
  title?: string;
  style?: React.CSSProperties;
}

export function Icon({ name, className, large, title, style }: IconProps) {
  return (
    <span
      title={title}
      className={`icon-${name} ${className ? className : ""} ${
        large ? styles.large : styles.default
      } `}
      style={style}
      aria-hidden
    ></span>
  );
}
