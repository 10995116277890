import { Trans, useTranslation } from "react-i18next";
import styles from "@/styles/UserSettings.module.scss";
import { Button } from "@app-components";
import { env } from "@/lib/utils";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import UserDetailsForm from "@/components/user/UserDetailsForm";
import { useCompanyContact } from "@/api";
import { useLoggedInUser } from "@/providers/AuthProvider";

export const Route = createFileRoute("/settings/")({
  component: UserSettings,
});

function UserSettings() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useLoggedInUser();
  const { data: companyContact } = useCompanyContact(user.buildingTenantId);

  return (
    <div className={styles.mainContainer}>
      <div className="pageHeader">
        <h1>{t("PAGES.SETTINGS.HEADER")}</h1>
        <p>
          {t("PAGES.SETTINGS.SUBHEADER")}
          {companyContact && (
            <strong>
              {" "}
              <Trans
                i18nKey="YOUR_MAIN_ADMIN"
                values={{
                  companyContact: companyContact.name,
                }}
                components={{
                  mailto: <a href={`mailto:${companyContact.email}`}></a>,
                }}
              ></Trans>
            </strong>
          )}
        </p>
      </div>
      <UserDetailsForm />
      <div className={styles.manageConsentsBtnContainer}>
        <Button
          role="link"
          secondary
          onClick={(e) => {
            e.preventDefault();
            navigate({ to: "/settings/consents" });
          }}
        >
          {t("PAGES.SETTINGS.MANAGE_CONSENTS")}
        </Button>
      </div>
      <div className={styles.version}>
        {t("PORTAL_VERSION")} {env("VITE_FRONTEND_VERSION")}
      </div>
    </div>
  );
}
