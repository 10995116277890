import {
  getImageFunction,
  updateUser,
  uploadImage,
  useCompany,
  useImage,
} from "@/api";
import { usePrompt } from "@/hooks/usePrompt";
import { useLoggedInUser } from "@/providers/AuthProvider";
import { useSnackbarStore } from "@/store/zustandStore";
import { NotificationType, User, VisitMode } from "@/types";
import { useForm } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "@/styles/UserSettings.module.scss";
import {
  Icon,
  ImageInput,
  InputField,
  RadioButtons,
  Toggle,
} from "@app-components";
import { z } from "zod";
import { NAME_REGEX, PHONE_REGEX } from "@/lib/constants";
import SaveResetButtons from "@/components/SaveResetButtons";
import i18n from "@/i18n/config";
import { Tooltip } from "react-tooltip";

export default function UserDetailsForm({ userData }: { userData?: User }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { addNotification } = useSnackbarStore();
  const user = userData || useLoggedInUser();
  const { data: company } = useCompany(user.buildingTenantId);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { data: profileImage } = useImage(user.userProfileImgUrl ?? "");

  usePrompt({
    isOpen: hasUnsavedChanges,
  });

  const form = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      visibleInVisit: user.visibleInVisit,
      userProfileImg: "",
      userProfileImgUrl: profileImage,
      language: user.language || "no",
    },
    onSubmit: async ({ value }) => {
      i18n.changeLanguage(value.language);
      let imgUploadFailed = false;

      if (value.userProfileImg) {
        try {
          const imgUpload = await uploadImage(
            value.userProfileImg,
            `${value.firstName}_${value.lastName}`,
          );
          const url = await getImageFunction(imgUpload.url);
          value.userProfileImgUrl = url;
        } catch (error) {
          value.userProfileImgUrl = profileImage;
          imgUploadFailed = true;
          console.error(error);
          addNotification("Image upload failed. It may be too large.");
        }
      }

      try {
        // Extract all values except for encoded image data which is no longer needed
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { userProfileImg, ...formValues } = value;
        await updateUser(user.userId, formValues);
        setHasUnsavedChanges(false);
        if (imgUploadFailed) {
          addNotification(
            "Image upload failed, but other settings saved successfully",
            NotificationType.WARNING,
          );
        } else {
          addNotification(
            "Settings saved successfully",
            NotificationType.SUCCESS,
          );
        }
        queryClient.invalidateQueries({ queryKey: ["user", "users"] });
      } catch (error) {
        if (error instanceof Error) {
          console.error(error);
          addNotification("Failed to save changes in settings" + error.message);
        } else {
          console.error(error);
          addNotification("An unexpected error occurred.");
        }
      }
    },
    validatorAdapter: zodValidator(),
  });

  return (
    <form
      onChange={() => setHasUnsavedChanges(true)}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      className={styles.container}
    >
      <div className={styles.personalInfoContainer}>
        <div className={styles.profileImgContainer}>
          <form.Field name="userProfileImgUrl">
            {(field) => (
              <ImageInput
                key={field.state.value}
                id="user-profile"
                placeholder={
                  <div className={styles.userProfilePlaceholder}>
                    <Icon name="add_photo" />
                    <p>{t("IMAGE.PLACEHOLDER.PROFILE")}</p>
                  </div>
                }
                allowedFileTypes={[".jpg", ".jpeg", ".png"]}
                translations={{
                  uploadImageOverlayText: t("IMAGE.UPLOAD"),
                  replaceImageAriaLabel: t("IMAGE.REPLACE.ARIA_LABEL"),
                  replaceImageOverlayText: t("IMAGE.REPLACE.TEXT"),
                  undoChangeButtonText: t("IMAGE.UNDO"),
                  editDoneText: t("IMAGE.DONE"),
                  editCancelText: t("IMAGE.CANCEL"),
                  fileTooLargeText: t("IMAGE.FILE_TOO_LARGE"),
                }}
                onChange={(image) => {
                  field.handleChange(image.previewUrl);
                  form.setFieldValue("userProfileImg", image.encoded);
                  setHasUnsavedChanges(true);
                }}
                onError={(error) => addNotification(error)}
                onUndo={(imgUrl) => {
                  field.handleChange(imgUrl);
                  form.setFieldValue("userProfileImg", "");
                }}
                originalImageUrl={profileImage}
                currImageUrl={field.state.value}
                cropSize={{ height: 200, width: 150 }}
                style={{
                  componentStyle: {
                    height: 200,
                  },
                  previewStyle: {
                    image: {
                      borderRadius: "var(--wlcm-border-radius-sm)",
                      overflow: "hidden",
                    },
                  },
                  overlayStyle: {
                    borderRadius: "var(--wlcm-border-radius-sm)",
                  },
                  ...(window.innerWidth > 767
                    ? {
                        menuStyle: {
                          left: 0,
                        },
                      }
                    : {}),
                }}
                tips={
                  <>
                    <p
                      id="profile-img-tips"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "4px",
                        gap: "4px",
                      }}
                    >
                      <Icon
                        name="info"
                        style={{ color: "var(--wlcm-color-blue)" }}
                      />{" "}
                      Tips
                    </p>
                    <Tooltip
                      anchorSelect="#profile-img-tips"
                      place="bottom"
                      style={{ maxWidth: 200 }}
                    >
                      <Trans
                        i18nKey="IMAGE.PROFILE_TIPS"
                        components={{
                          ul: (
                            <ul
                              style={{ paddingLeft: "var(--wlcm-spacing-xs)" }}
                            ></ul>
                          ),
                          li: <li></li>,
                        }}
                      />
                    </Tooltip>
                  </>
                }
              />
            )}
          </form.Field>
        </div>
        <div className={styles.otherPersonalSettings}>
          <label htmlFor="FIRST_NAME_INPUT">
            <Icon name="person" /> {t("USERS.TABLE_HEADERS.FIRST_NAME")}
          </label>
          <form.Field
            name="firstName"
            validators={{
              onChange: z
                .string()
                .min(
                  1,
                  t("VALIDATION.REQUIRED", {
                    field: t("ADD_USER.FIRST_NAME"),
                  }),
                )
                .min(
                  2,
                  t("VALIDATION.MIN_LENGTH", {
                    field: t("ADD_USER.FIRST_NAME"),
                    length: 2,
                    type: t("CHARACTERS"),
                  }),
                )
                .max(
                  30,
                  t("VALIDATION.MAX_LENGTH", {
                    field: t("ADD_USER.FIRST_NAME"),
                    length: "30",
                    type: t("CHARACTERS"),
                  }),
                )
                .trim()
                .refine((value) => NAME_REGEX.test(value), {
                  message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                }),
            }}
          >
            {(field) => (
              <InputField
                id="FIRST_NAME_INPUT"
                compact
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          <label htmlFor="LAST_NAME_INPUT">
            <Icon name="person" /> {t("USERS.TABLE_HEADERS.LAST_NAME")}
          </label>
          <form.Field
            name="lastName"
            validators={{
              onChange: z
                .string()
                .min(
                  1,
                  t("VALIDATION.REQUIRED", {
                    field: t("ADD_USER.LAST_NAME"),
                  }),
                )
                .min(
                  2,
                  t("VALIDATION.MIN_LENGTH", {
                    field: t("ADD_USER.LAST_NAME"),
                    length: 2,
                    type: t("CHARACTERS"),
                  }),
                )
                .max(
                  30,
                  t("VALIDATION.MAX_LENGTH", {
                    field: t("ADD_USER.LAST_NAME"),
                    length: "30",
                    type: t("CHARACTERS"),
                  }),
                )
                .trim()
                .refine((value) => NAME_REGEX.test(value), {
                  message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                }),
            }}
          >
            {(field) => (
              <InputField
                id="LAST_NAME_INPUT"
                compact
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          <label htmlFor="EMAIL_INPUT">
            <Icon name="mail" /> {t("USERS.TABLE_HEADERS.EMAIL")}
          </label>
          <form.Field
            name="email"
            //COMMENTED OUT UNTIL WE CAN HANDLE EMAIL CHANGES

            // validators={{
            //   onBlur: z
            //     .string()
            //     .min(
            //       1,
            //       t("VALIDATION.REQUIRED", { field: t("ADD_USER.EMAIL") }),
            //     )
            //     .email(t("VALIDATION.INVALID", { field: t("ADD_USER.EMAIL") }))
            //     .trim()
            //     .refine(
            //       (value) => {
            //         const emailDomain = value.split("@")[1];
            //         return company?.ssoSettings.some(
            //           (setting) => setting.domain === emailDomain,
            //         );
            //       },
            //       {
            //         message: t("VALIDATION.DOMAIN", {
            //           domain: company?.ssoSettings
            //             .map((setting) => setting.domain)
            //             .join(", "),
            //         }),
            //       },
            //     ),
            //   /* UNCOMMEND WHEN WE ALLOW EMAIL CHANGES */
            //   /* .refine((value) => !emailList.includes(value), {
            //       message: t("VALIDATION.EMAIL_USED"),
            //     }) */
            // }}
          >
            {(field) => (
              <InputField
                id="EMAIL_INPUT"
                disabled /* EMAIL SHOULD NOT BE EDITABLE UNTIL WE CAN HANDLE THE CHANGE IN KEYCLOAK AS WELL AS FIRESTORE */
                compact
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          <label htmlFor="PHONE_INPUT">
            <Icon name="phone_android" /> {t("USERS.TABLE_HEADERS.PHONE")}
          </label>
          <form.Field
            name="phone"
            validators={{
              onChange: z
                .string()
                .trim()
                .refine((value) => !value || PHONE_REGEX.test(value), {
                  message: t("VALIDATION.INVALID_PHONE"),
                }),
            }}
          >
            {(field) => (
              <InputField
                id="PHONE_INPUT"
                type="tel"
                compact
                value={field.state.value}
                placeholder="+4700000000"
                onBlur={field.handleBlur}
                onFocus={() => {
                  if (!field.state.value) field.handleChange("+47");
                }}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
        </div>
      </div>
      {company.visitMode === VisitMode.ENABLED && (
        <div className={styles.optionsContainer}>
          <label htmlFor="VISIBILITY_TOGGLE">
            <Icon name="visibility" /> {t("USERS.TABLE_HEADERS.VISIBLE")}
          </label>
          <div className={styles.flexSetting}>
            <form.Field name="visibleInVisit">
              {(field) => (
                <Toggle
                  id="VISIBILITY_TOGGLE"
                  title={field.state.value ? t("YES") : t("NO")}
                  checked={field.state.value}
                  onChange={(checked) => field.handleChange(checked)}
                />
              )}
            </form.Field>
            <p className={styles.description}>
              {t(`${userData ? "ADMIN_" : ""}VISIBILITY_TOGGLE_DESCRIPTION`)}
            </p>
          </div>
        </div>
      )}
      <div className={styles.optionsContainer}>
        <label htmlFor="LANGUAGE_PREFERENCE">
          <Icon name="globe" /> {t("USERS.LANGUAGE_PREFERENCE")}
        </label>
        <div className={styles.flexSetting}>
          <form.Field name="language">
            {(field) => (
              <RadioButtons
                options={[
                  { title: t("LANG_NO"), value: "no" },
                  { title: t("LANG_EN"), value: "en" },
                ]}
                onChange={(value) => {
                  field.handleChange(value.value);
                }}
                checked={field.state.value}
              />
            )}
          </form.Field>
        </div>
      </div>
      <SaveResetButtons
        form={form}
        showButtons={hasUnsavedChanges}
        onHideButtons={() => setHasUnsavedChanges(false)}
      />
    </form>
  );
}
