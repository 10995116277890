import { useRef, useEffect } from "react";
import styles from "@/styles/Info.module.scss";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/bookings")({
  component: Bookings,
});

function Bookings() {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!ref.current) return;
    const iframe = document.getElementById("bookings") as HTMLIFrameElement;
    const topCover = document.getElementById(
      "iframe-top-cover",
    ) as HTMLDivElement;
    const boundingRect = ref.current.getBoundingClientRect();
    iframe.style.top = boundingRect.top + "px";
    iframe.style.left = boundingRect.left + "px";
    iframe.style.width = boundingRect.width + "px";
    iframe.classList.remove("hide");
    topCover.classList.remove("hide");

    const obs = new ResizeObserver(() => {
      if (!ref.current) return;

      const boundingRect = ref.current.getBoundingClientRect();
      // HACK: 87px is the height of the header
      iframe.style.top = "87px";
      iframe.style.left = boundingRect.left + "px";
      if (boundingRect.width >= 2000) {
        topCover.style.left = boundingRect.x + 1930 + "px";
      }
      iframe.style.width = boundingRect.width + "px";
    });
    obs.observe(ref.current);
    return () => {
      iframe.classList.add("hide");
      topCover.classList.add("hide");
      topCover.style.left = "";
      if (ref.current) obs.unobserve(ref.current);
    };
  }, [ref]);
  return <div ref={ref} className={styles.iframeWrapper}></div>;
}
