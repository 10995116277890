import { Icon } from "../Icon";
import styles from "./Checkbox.module.scss";

type CheckboxProps = {
  onClick?: (e: React.MouseEvent) => void;
  onChange?: (newValue: boolean) => void;
  checked?: boolean;
  label: string | React.ReactElement;
  hideLabel?: boolean;
  dark?: boolean;
  className?: string;
  disabled?: boolean;
};

export function Checkbox({
  onClick,
  onChange,
  checked,
  label,
  hideLabel,
  dark,
  className,
  disabled,
}: CheckboxProps) {
  return (
    <label
      className={`${styles.checkboxContainer} ${dark && styles.dark} ${disabled && styles.disabled} ${className}`}
      onClick={(e) => !!onClick && onClick(e)}
    >
      <span className={hideLabel ? "sr-only" : ""}>{label}</span>
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange && onChange(e.target.checked)
        }
      />
      <span className={styles.customCheckbox}>
        <Icon name="done" className={styles.checkIcon} aria-hidden />
      </span>
    </label>
  );
}
