import { initializeApp } from "firebase/app";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { env } from "@/lib/utils";
import { APP_DOMAIN } from "@/lib/constants";

const config = {
  apiKey: env("VITE_FB_API_KEY"),
  authDomain: APP_DOMAIN().slice(8),
  projectId: env("VITE_FB_PROJECT"),
  storageBucket: env("VITE_FB_STORAGE_BUCKET"),
  messagingSenderId: env("VITE_FB_MESSAGING_SENDER_ID"),
  appId: env("VITE_FB_WEB_APP_ID"),
};

const app = initializeApp(config);
const auth = getAuth(app);

// Sign in the user if they have gone throug a magic link
if (isSignInWithEmailLink(auth, window.location.href)) {
  const emailFromLocalStorage = window.localStorage.getItem("emailForSignIn");

  const emailFromQueryParamResult =
    window.location.search.match(/.*&email=(.*)/);
  if (emailFromQueryParamResult && emailFromQueryParamResult[1]) {
    signInWithEmailLink(
      auth,
      emailFromQueryParamResult[1],
      window.location.href,
    );
  } else if (emailFromLocalStorage) {
    signInWithEmailLink(auth, emailFromLocalStorage, window.location.href);
  } else {
    throw new Error("Could not get email, please try again");
  }
}

const getAuthUser = () => {
  if (!auth.currentUser) throw new Error("User not logged in");
  return auth.currentUser;
};

export { auth, getAuthUser };
