import { createFileRoute } from '@tanstack/react-router'
import { GroupPreregistration } from '@/components/pre-registration/GroupPreregistration'
import { useTranslation } from 'react-i18next'
import { useContextStore } from '@/store/zustandStore'

export const Route = createFileRoute('/company/visitors/preregistration')({
  component: Preregistration,
})

function Preregistration() {
  const { t } = useTranslation()
  const { selectedCompany } = useContextStore();

  const breadcrumbs = [
    { label: t('SIDEBAR.COMPANY_VISITORS'), path: '/company/visitors' },
    {
      label: t('PAGES.VISITORS.PREREGISTRATION'),
      path: '/company/visitors/preregistration',
    },
  ]
  return (
    <GroupPreregistration
      noResultsText={t('PREREGISTRATION.NO_RESULTS_COMPANY')}
      showAddHost
      breadcrumbs={breadcrumbs}
      companyId={selectedCompany?.value}
    />
  )
}
