import { accessCardStatusColorMap } from "@/lib/constants";
import { formatTimestampToTimeAgo } from "@/lib/utils";
import styles from "@/styles/Access.module.scss";
import { CardStatus, type AccessCardType, type User } from "@/types";
import { Button, Icon } from "@app-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import AccessCardDetailsModal from "./AccessCardDetailsModal";
import { usePrompt } from "@/hooks/usePrompt";
import { updateAccessCard } from "@/api";
import { useLoggedInUser } from "@/providers/AuthProvider";
import { useSnackbarStore } from "@/store/zustandStore";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "@/i18n/config";

interface AccessCardProps {
  data: AccessCardType;
  cardUser?: User;
  companyAdminView?: boolean;
}

export default function AccessCard({
  data,
  cardUser,
  companyAdminView = false,
}: AccessCardProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const user = useLoggedInUser();
  const { addNotification } = useSnackbarStore();
  const [revealPin, setRevealPin] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [blockOpen, setBlockOpen] = useState(false);

  usePrompt({
    promptOnNavigate: false,
    isOpen: blockOpen,
    title: t("ACCESS_CARD.BLOCK_MODAL.HEADER"),
    subtitle: t("ACCESS_CARD.BLOCK_MODAL.BODY"),
    onConfirm: async () => {
      try {
        await updateAccessCard(
          data.id,
          {
            status: CardStatus.INACTIVE,
          },
          user.userId,
        );
        queryClient.invalidateQueries({
          queryKey: ["access-cards", cardUser?.userId],
        });
      } catch (error) {
        console.error(error);
        addNotification(
          "Failed to inactivate card. Please contact your administrator for help.",
        );
      } finally {
        setBlockOpen(false);
      }
    },
    onCancel: () => setBlockOpen(false),
  });

  const tenDaysAgo = new Date(new Date().setDate(new Date().getDate() - 10));
  const showPickupInstructions =
    data.status === CardStatus.ACTIVE &&
    data.pickupInstructions &&
    // activated less than 10 days ago
    new Date(new Date().setSeconds(data.activatedAt!._seconds)).getTime() >
      tenDaysAgo.getTime();

  const timeStampToShow =
    data.status === CardStatus.ORDERED
      ? data.createdAt
      : data.status === CardStatus.ACTIVE
        ? data.activatedAt
        : data.status === CardStatus.INACTIVE
          ? data.inactivatedAt
          : data.status === CardStatus.CANCELLED
            ? data.cancelledAt
            : data.deactivatedAt;

  return (
    <>
      <article
        className={styles.accessCard}
        style={{
          borderTopColor:
            accessCardStatusColorMap[data.status] ||
            "var(--wlcm-color-darker-grey)",
        }}
        onClick={() => setModalOpen(true)}
      >
        <h3 className="sr-only">
          {t(`ACCESS_CARD.ARIA_DESCRIPTION.${data.type.toUpperCase()}`)}
        </h3>
        <div>
          <p className={`card-status-text-${data.id} ${styles.centerAligned}`}>
            <span
              aria-hidden
              className={styles.statusDot}
              style={{
                backgroundColor:
                  accessCardStatusColorMap[data.status] ||
                  "var(--wlcm-color-darker-grey)",
              }}
            ></span>
            {t(`ACCESS_CARD.STATUS.${data.status.toUpperCase()}.LABEL`)}
            {data.status !== CardStatus.ACTIVE && (
              <span style={{ opacity: 0.5 }}>
                {formatTimestampToTimeAgo(
                  t,
                  timeStampToShow!,
                  i18n.language,
                  true,
                )}
              </span>
            )}
          </p>
          <Tooltip
            anchorSelect={`.card-status-text-${data.id}`}
            content={t(
              `ACCESS_CARD.STATUS.${data.status.toUpperCase()}.DESCRIPTION`,
            )}
          />
          <div aria-hidden className={styles.userImgPlaceholder}>
            <Icon name="person" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--wlcm-spacing-xs)",
            }}
          >
            <p className={styles.centerAligned}>
              <strong>{t("ACCESS_CARD.NUMBER")}:</strong>
              {data.cardNumber ? (
                <span className={styles.monospace}>{data.cardNumber}</span>
              ) : (
                <span style={{ opacity: 0.5 }}>
                  {t("ACCESS_CARD.NO_CARD_NUMBER")}
                </span>
              )}
            </p>
            {!companyAdminView &&
              data.status !== CardStatus.INACTIVE &&
              data.status !== CardStatus.DEACTIVATED && (
                <p className={styles.centerAligned}>
                  <strong>{t("ACCESS_CARD.PIN.LABEL")}:</strong>
                  {data.pin ? (
                    <span
                      tabIndex={0}
                      className={styles.centerAligned}
                      onMouseEnter={() => setRevealPin(true)}
                      onMouseLeave={() => setRevealPin(false)}
                      onFocus={() => setRevealPin(true)}
                      onBlur={() => setRevealPin(false)}
                      onClick={(e) => {
                        e.stopPropagation();
                        setRevealPin((curr) => !curr);
                      }}
                    >
                      <strong
                        className={styles.monospace}
                        style={{
                          display: revealPin ? "inline-block" : "none",
                          color: "var(--wlcm-color-blue)",
                        }}
                      >
                        {data.pin}
                      </strong>
                      {!revealPin && (
                        <Icon name="visibility_off" style={{ opacity: 0.5 }} />
                      )}
                      <span
                        style={{
                          visibility: revealPin ? "hidden" : "visible",
                          opacity: 0.5,
                        }}
                      >
                        {t("ACCESS_CARD.PIN.DESCRIPTION")}
                      </span>
                    </span>
                  ) : (
                    <span style={{ opacity: 0.5 }}>
                      {t("ACCESS_CARD.NO_PIN")}
                    </span>
                  )}
                </p>
              )}
            {showPickupInstructions ? (
              <p>
                <strong>{t("ACCESS_CARD.MESSAGE")}:</strong>
                <br />
                <span className={styles.message}>
                  "{data.pickupInstructions}"
                </span>
              </p>
            ) : (
              data.note && (
                <p className={styles.truncatedCardNote}>
                  <strong>{t("ACCESS_CARD.NOTE")}:</strong>
                  <span>"{data.note}"</span>
                </p>
              )
            )}
            {data.status === CardStatus.DEACTIVATED &&
              data.reasonForDeactivation && (
                <p>
                  <strong>{t("ACCESS_CARD.REASON_FOR_DEACTIVATION")}:</strong>
                  <br />
                  <span className={styles.message}>
                    "{data.reasonForDeactivation}"
                  </span>
                </p>
              )}
          </div>
        </div>
        <div className={styles.cardActions}>
          {data.status === CardStatus.ACTIVE && (
            <>
              <Button
                dark
                secondary
                className="block-card-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setBlockOpen(true);
                }}
              >
                {t("ACCESS_CARD.ACTIONS.BLOCK.LABEL")}
              </Button>
              <Tooltip
                anchorSelect=".block-card-btn"
                content={t("ACCESS_CARD.ACTIONS.BLOCK.TOOLTIP")}
              />
            </>
          )}
        </div>
      </article>
      {modalOpen && (
        <AccessCardDetailsModal
          userMode={!companyAdminView}
          accessCard={data}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}
