import { CSSProperties, ReactNode, useState } from "react";
import styles from "./Accordion.module.scss";
import { Icon } from "../Icon/icon";

interface CommonAccordionProps {
  id: string;
  header: ReactNode;
  body: ReactNode;
  style?: {
    component?: CSSProperties;
    activator?: CSSProperties;
    body?: CSSProperties;
  };
}

interface ControlledVariant extends CommonAccordionProps {
  expanded: boolean;
  onToggle: (expanded: boolean) => void;
  defaultExpanded?: never;
}

interface DefaultVariant extends CommonAccordionProps {
  expanded?: never;
  onToggle?: never;
  defaultExpanded?: boolean;
}

type AccordionProps = ControlledVariant | DefaultVariant;

export function Accordion({
  id,
  header,
  body,
  expanded,
  onToggle,
  defaultExpanded = false,
  style,
}: AccordionProps) {
  const [internalExpanded, setInternalExpanded] = useState(!!defaultExpanded);

  const isControlled = expanded !== undefined && onToggle !== undefined;
  const isExpanded = isControlled ? expanded : internalExpanded;

  const toggleInternalExpanded = () => {
    if (isControlled) {
      onToggle(!expanded);
    } else {
      setInternalExpanded((prev) => !prev);
    }
  };

  return (
    <div className={styles.container} style={style?.component}>
      <div
        className={`${styles.activator} ${isExpanded ? styles.expanded : ""}`}
        style={style?.activator}
        onClick={toggleInternalExpanded}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            toggleInternalExpanded();
          }
        }}
        role="button"
        tabIndex={0}
        aria-expanded={isExpanded}
        aria-controls={id}
      >
        <div>{header}</div>
        <Icon name="expand_more" className={styles.icon} />
      </div>
      <div
        id={id}
        className={`${styles.body} ${isExpanded ? styles.expanded : ""}`}
        style={style?.body}
        role="region"
        aria-hidden={!isExpanded}
      >
        {body}
      </div>
    </div>
  );
}
