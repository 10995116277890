import { addSsoToCompany } from "@/api";
import SSOInput from "@/components/SSOInput";
import { REAL_ESTATE } from "@/lib/constants";
import { useSnackbarStore } from "@/store/zustandStore";
import styles from "@/styles/CompanySettings.module.scss";
import { Company, NotificationType, SSOItem } from "@/types";
import { Button, Chip, PopupCard } from "@app-components";
import { useForm } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

interface SsoSectionProps {
  company: Company;
}

interface AddDomainsModalProps {
  onClose: () => void;
  company: Company;
}

export default function SsoSection({ company }: SsoSectionProps) {
  const { t } = useTranslation();
  const [addDomainOpen, setAddDomainOpen] = useState(false);

  return (
    <>
      <div className={`${styles.fullWidth} ${styles.sectionWithButton}`}>
        <h2>{t("COMPANY_SETTINGS.SSO_SETTINGS")}</h2>
        <Button
          icon="add"
          onClick={(e) => {
            e.preventDefault();
            setAddDomainOpen(true);
          }}
        >
          {t("COMPANY_SETTINGS.ADD_DOMAINS")}
        </Button>
      </div>
      <div className={`${styles.fullWidth} ${styles.chipContainer}`}>
        {company.ssoSettings?.map((sso) => (
          <Chip
            key={sso.domain}
            icon={sso.provider.toLowerCase().split(" ").join("_")}
            label={sso.domain}
          />
        ))}
      </div>
      {/* createPortal is REQUIRED so the modal form is not rendered inside the <ComapnySettings /> form */}
      {addDomainOpen &&
        createPortal(
          <AddDomainsModal
            onClose={() => setAddDomainOpen(false)}
            company={company}
          />,
          document.querySelector("main") || document.body,
        )}
    </>
  );
}

const AddDomainsModal = ({ onClose, company }: AddDomainsModalProps) => {
  const { t } = useTranslation();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const { addNotification } = useSnackbarStore();
  const queryClient = useQueryClient();

  const form = useForm({
    defaultValues: {
      ssoSettings: [] as SSOItem[],
    },
    onSubmit: async ({ value }) => {
      try {
        const res = await addSsoToCompany(
          company.buildingTenantId,
          company.buildingTenantName,
          REAL_ESTATE,
          value.ssoSettings,
        );
        onClose();
        const googleSso = value.ssoSettings.filter(
          (value) => value.provider === "Google",
        );
        if (googleSso.length > 0) {
          addNotification(
            `${t("PAGES.COMPANY_SETTINGS.GOOGLE_SSO_HELP_TEXT")}`,
            NotificationType.SUCCESS,
          );
        } else {
          addNotification(res.message, NotificationType.SUCCESS);
        }
        queryClient.invalidateQueries({
          queryKey: ["company"],
        });
      } catch (error) {
        addNotification(
          typeof error === "string"
            ? error
            : "Failed to add domains to the company.",
        );
      }
    },
  });

  return (
    <PopupCard onClose={onClose}>
      <div className={styles.addDomainsContainer}>
        <h3 className={styles.addDomainsHeader}>
          {t("COMPANY_SETTINGS.ADD_DOMAINS")}
        </h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
          onChange={(e) => e.stopPropagation()}
        >
          <form.Field name="ssoSettings">
            {(field) => (
              <SSOInput
                className={styles.ssoInput}
                value={field.state.value}
                onChange={(newVal) => {
                  field.handleChange(newVal);
                  setSubmitDisabled(newVal.length === 0);
                }}
                startWithEmptyInput
              />
            )}
          </form.Field>
          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <Button
                className={styles.submitDomainsButton}
                type="submit"
                disabled={submitDisabled || isSubmitting || !canSubmit}
                isLoading={isSubmitting}
              >
                {t("COMPANY_SETTINGS.ADD_DOMAINS")}
              </Button>
            )}
          />
        </form>
      </div>
    </PopupCard>
  );
};
