import { useEffect } from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { useLoggedInUser } from "./AuthProvider";
import { useContextStore } from "@/store/zustandStore";
import { getIntercomHMAC, useBuilding } from "@/api";
import { env } from "@/lib/utils";

export default function IntercomProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const user = useLoggedInUser();
  const { data: building } = useBuilding();

  const { selectedCompany } = useContextStore();

  useEffect(() => {
    if (!user) return;

    (async () => {
      try {
        const hmac = await getIntercomHMAC();
        //eslint-disable-next-line
        const extraOptions: any = {};

        extraOptions["Role"] = "User";
        if (user.adminInCompanies.length > 0) {
          extraOptions["Role"] = "TenantAdmin";
        }
        if (building.buildingAdmins.includes(user.userId)) {
          extraOptions["Role"] = "BuildingAdmin";
        }
        extraOptions["companies"] = [
          {
            company_id: selectedCompany?.value,
            name: selectedCompany?.label,
            Building: building.displayName,
          },
        ];
        Intercom({
          region: "eu",
          app_id: env("VITE_INTERCOM_ID"),
          user_id: user.userId,
          name: `${user.firstName} ${user.lastName}`,
          language_override: user.language,
          email: user.email,
          user_hash: hmac,
          ...extraOptions,
        });
      } catch (err) {
        console.log("Failed to initialize Intercom ", err);
      }
    })();
  }, []);
  return <>{children}</>;
}
