import { PreRegistrationAttendee } from "@/types";
import { Icon } from "@app-components";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import Table from "../table/Table";
import styles from "@/styles/Preregistration.module.scss";

const columnHelper = createColumnHelper<PreRegistrationAttendee>();

interface PreregistrationAttendeesTableProps {
  futureVisitors: PreRegistrationAttendee[];
  deleteAttendee: (index: number) => void;
}

export const PreregistrationAttendeesTable = ({
  futureVisitors,
  deleteAttendee,
}: PreregistrationAttendeesTableProps) => {
  const { t } = useTranslation();

  const [attendees, setAttendees] = useState(futureVisitors);

  useEffect(() => {
    setAttendees(futureVisitors);
  }, [futureVisitors]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => info.getValue(),
        header: () => <div>{t("USERS.TABLE_HEADERS.NAME")}</div>,
      }),
      columnHelper.accessor("phone", {
        cell: (info) => info.getValue(),
        header: () => <div>{t("USERS.TABLE_HEADERS.PHONE")}</div>,
      }),
      columnHelper.accessor("email", {
        cell: (info) => {
          const email = info.getValue();
          return email ? email : "-";
        },
        header: () => <div>{t("USERS.TABLE_HEADERS.EMAIL")}</div>,
      }),
      columnHelper.display({
        id: "delete-attendee",
        cell: ({ row }) => {
          return (
            <div>
              <button
                aria-label="Delete Attendee"
                className={styles.deleteAttendeeButton}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteAttendee(row.index);
                }}
              >
                <Icon name="delete" className="delete-attendee" />
                <Tooltip
                  anchorSelect=".delete-attendee"
                  content={t("PREREGISTRATION.DELETE_ATTENDEE")}
                />
                <span className="sr-only">
                  {t("PREREGISTRATION.DELETE_ATTENDEE")}
                </span>
              </button>
            </div>
          );
        },
        header: () => (
          <div className="sr-only">{t("PREREGISTRATION.DELETE_ATTENDEE")}</div>
        ),
      }),
    ],
    [attendees],
  );

  const table = useReactTable({
    data: attendees,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: Number(localStorage.getItem("attendeePerPage") || 10),
      },
    },
  });

  return (
    <Table
      table={table}
      noDataMessage={t("PREREGISTRATION.NO_ATTENDEES")}
      totalItems={attendees.length}
      paginationKey="attendeePerPage"
      customPageSizeOptions={[10, 20, 30]}
    />
  );
};
