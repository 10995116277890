import { deleteCompany, updateUser, useCompany } from "@/api";
import { ExtendedCompany, NotificationType } from "@/types";
import { Button, PopupCard } from "@app-components";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "@/styles/Companies.module.scss";
import { useContextStore, useSnackbarStore } from "@/store/zustandStore";
import { useLoggedInUser, useRefetchUser } from "@/providers/AuthProvider";
import { Tooltip } from "react-tooltip";

export default function CompanyDetailsModal({
  onClose,
  company,
}: {
  onClose: () => void;
  company: ExtendedCompany;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const user = useLoggedInUser();
  const refetchUser = useRefetchUser();
  const { addNotification } = useSnackbarStore();
  const { selectedCompany, setCompany } = useContextStore();
  const { data: tenant } = useCompany(user?.buildingTenantId);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddingAdmin, setIsAddingAdmin] = useState(false);

  const deleteCompanyDisabled = company.totalUsers > 0;

  const deleteSelectedCompany = async () => {
    setIsDeleting(true);
    try {
      await deleteCompany(company.buildingTenantId);
      addNotification(
        `${company.buildingTenantName} was deleted successfully`,
        NotificationType.SUCCESS,
      );
      await queryClient.invalidateQueries({ queryKey: ["extended-companies"] });
      // If trying to delete a company that is the selected tenant, reset the selected tenant to users primary company
      if (company.buildingTenantId === selectedCompany?.value) {
        setCompany({
          value: tenant.buildingTenantId,
          label: tenant.buildingTenantName,
        });
      }

      onClose();
    } catch (error) {
      console.error(error);
      addNotification("Failed to delete company. Try again later!");
    } finally {
      setIsDeleting(false);
    }
  };

  const addCurrUserAsAdmin = async () => {
    setIsAddingAdmin(true);
    try {
      await updateUser(user.userId, {
        adminInCompanies: [...user.adminInCompanies, company.buildingTenantId],
      });
      refetchUser();
      await queryClient.invalidateQueries({ queryKey: ["extended-companies"] });
      addNotification(
        `You are now an administrator of ${company.buildingTenantName}`,
        NotificationType.SUCCESS,
      );
    } catch (error) {
      console.error(error);
      addNotification("Failed to add you as administrator. Try again later!");
    } finally {
      setIsAddingAdmin(false);
    }
  };

  return (
    <PopupCard onClose={onClose}>
      <h3>{company.buildingTenantName}</h3>
      <div className={styles.deleteCompanyInfo}>
        <p>
          Org.num:{" "}
          <strong>{company.orgNumber ? company.orgNumber : "\u2014"} </strong>
        </p>
        {!user.adminInCompanies.includes(company.buildingTenantId) && (
          <Button
            icon="user_cogwheel"
            disabled={isAddingAdmin || isDeleting}
            isLoading={isAddingAdmin}
            onClick={addCurrUserAsAdmin}
          >
            {t("COMPANY_DETAILS.BECOME_ADMIN")}
          </Button>
        )}
      </div>
      <div className="editDangerArea">
        <h4>{t("EDIT_USER.DANGER")}</h4>
        <Button
          id="delete-company-btn"
          icon="delete"
          disabled={isDeleting || isAddingAdmin || deleteCompanyDisabled}
          isLoading={isDeleting}
          danger
          onClick={deleteSelectedCompany}
        >
          {t("COMPANY_DETAILS.DELETE.BUTTON")}
        </Button>
        {deleteCompanyDisabled && (
          <Tooltip
            anchorSelect="#delete-company-btn"
            style={{ background: "var(--wlcm-color-danger-red)" }}
            content={t("COMPANY_DETAILS.DELETE.NOT_ALLOWED")}
          />
        )}
      </div>
    </PopupCard>
  );
}
