import styles from "@/styles/BuildingVisitors.module.scss";
import { Trans, useTranslation } from "react-i18next";
import { Button, Icon, InputField } from "@app-components";
import { WELCOME_VISITORS_URL } from "@/lib/constants";
import i18n from "@/i18n/config";
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  AccessSystem,
  ExtendedPreRegistrationAttendee,
  NotificationType,
  Visit,
} from "@/types";
import { useEffect, useMemo, useState } from "react";
import Table from "@/components/table/Table.tsx";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  letInVisitor,
  useAllEntrances,
  useBuilding,
  useBuildingVisitors,
} from "@/api";
import { Tooltip } from "react-tooltip";
import {
  filterTodayPreregistrations,
  formatTimestampToHoursAndMinutes,
  timestampSortingFn,
} from "@/lib/utils";
import { useSnackbarStore } from "@/store/zustandStore";
import { useQueryClient } from "@tanstack/react-query";
import { useBuildingPreregistrations } from "@/api/functions/pre-registration";
import SortableTableHeader from "@/components/table/SortableTableHeader";

export const Route = createFileRoute("/building/visitors/")({
  component: BuildingVisitors,
});

type Visitor = Visit | ExtendedPreRegistrationAttendee;
type SearchableColumns = keyof Visit | keyof ExtendedPreRegistrationAttendee;

const columnHelper = createColumnHelper<Visitor>();
const searchableColumns: Array<SearchableColumns> = [
  "visitorName",
  "hostName",
  "visitorCompanyName",
  "visitorPhone",
  "buildingTenantName",
  "phone",
  "name",
  "eventName",
  "companyName",
];

function BuildingVisitors() {
  const { t } = useTranslation();

  return (
    <>
      <div className="pageHeader">
        <h1>{t("PAGES.BUILDING_VISITORS.HEADER")}</h1>
        <p>
          <Trans i18nKey="PAGES.BUILDING_VISITORS.SUBHEADER">
            Text
            <a href={WELCOME_VISITORS_URL(i18n.language)} target="_blank"></a>.
          </Trans>
        </p>
      </div>
      <VisitorsBody />
    </>
  );
}

const VisitorsBody = () => {
  const [filteredVisitors, setFilteredVisitors] = useState<Visitor[]>([]);
  const [, setSelectedVisitor] = useState<Visitor>();
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useSnackbarStore();
  const queryClient = useQueryClient();
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);

  const { data: buildingVisitors } = useBuildingVisitors();
  const { data: entrances } = useAllEntrances();
  const { data: building } = useBuilding();
  const { data: preregistrationGroups } = useBuildingPreregistrations();

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "createdAt",
      desc: false,
    },
  ]);

  const todayPreregistrations = filterTodayPreregistrations(
    preregistrationGroups,
  );

  const extendedPreregistrationAttendees: ExtendedPreRegistrationAttendee[] =
    todayPreregistrations.flatMap((group) =>
      group.attendees.map((attendee) => ({
        ...attendee,
        startTime: group.startTime,
        hostName: group.hostName,
        eventName: group.eventName,
        companyName: group.companyName,
      })),
    );

  const combinedData = useMemo(
    () => [
      ...(buildingVisitors ?? []),
      ...(extendedPreregistrationAttendees ?? []),
    ],
    [buildingVisitors, extendedPreregistrationAttendees],
  );

  const isVisit = (
    data: Visit | ExtendedPreRegistrationAttendee,
  ): data is Visit => {
    return "visitorName" in data;
  };

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor(
          (row) => {
            if (isVisit(row)) {
              return row.createdAt;
            } else {
              return row.arrivedAt ?? row.startTime;
            }
          },
          {
            id: "createdAt",
            cell: (info) => {
              const value = info.getValue();
              if (!value) return null;

              const date = new Date(value._seconds * 1000);
              const hours = String(date.getHours()).padStart(2, "0");
              const minutes = String(date.getMinutes()).padStart(2, "0");
              const formattedTime = `${hours}:${minutes}`;

              if (!isVisit(info.row.original) && info.row.original.arrivedAt) {
                return (
                  <div className={styles.attendeeArrived}>
                    <span>{formattedTime}</span>
                    <Icon
                      name="done"
                      className={`${styles.greenIcon} preregistration-attendee-arrived-icon`}
                    />
                    <span className="sr-only">
                      {t("BUILDING_VISITORS.ATTENDEE_ARRIVED")}
                    </span>
                    <Tooltip
                      anchorSelect=".preregistration-attendee-arrived-icon"
                      content={t("BUILDING_VISITORS.ATTENDEE_ARRIVED")}
                    />
                  </div>
                );
              }

              return formattedTime; // Regular time rendering
            },
            header: (info) => (
              <SortableTableHeader
                info={info}
                tableHeaderId="createdAt"
                label={t("BUILDING_VISITORS.TIME")}

              />
            ),
            sortingFn: timestampSortingFn,
          },
        ),
        (entrances?.length ?? 0) > 1
          ? columnHelper.accessor("entranceName", {
              cell: (info) => info.getValue(),
              header: () => <div>{t("BUILDING_VISITORS.LOCATION")}</div>,
            })
          : null,
        columnHelper.accessor(
          (row) => {
            if (isVisit(row)) {
              return row.visitorName;
            } else {
              return row.name;
            }
          },
          {
            id: "visitorOrAttendeeName",
            cell: (info) => info.getValue(),
            header: () => <div>{t("BUILDING_VISITORS.VISITOR")}</div>,
          },
        ),
        columnHelper.accessor("hostName", {
          cell: (info) => {
            const hostName = info.getValue();
            return hostName == undefined ? "\u2014" : hostName;
          },
          header: () => <div>{t("BUILDING_VISITORS.HOST")}</div>,
        }),
        columnHelper.accessor(
          (row) => {
            if (isVisit(row)) {
              return row.buildingTenantName;
            } else {
              return row.companyName;
            }
          },
          {
            id: "buildingTenantOrCompanyName",
            cell: (info) => info.getValue(),
            header: () => <div>{t("BUILDING_VISITORS.HOST_COMPANY")}</div>,
          },
        ),
        columnHelper.accessor(
          (row) => {
            if (isVisit(row)) {
              return "\u2014";
            } else {
              return row.eventName;
            }
          },
          {
            id: "eventName",
            cell: (info) => info.getValue() || "",
            header: () => <div>{t("PREREGISTRATION.EVENT_NAME")}</div>,
          },
        ),
        columnHelper.accessor(
          (row) => {
            if (isVisit(row)) {
              return row.hostReplied;
            }
            return null;
          },
          {
            id: "hostReplied",
            cell: (info) => {
              return info.getValue() ? (
                <>
                  <Icon name="mail" className="visitor-sms-from-host-icon" />
                  <span className="sr-only">
                    {t("BUILDING_VISITORS.HOST_REPLIED")}
                  </span>
                  <Tooltip
                    anchorSelect=".visitor-sms-from-host-icon"
                    content={t("BUILDING_VISITORS.SMS_FROM_HOST")}
                  />
                </>
              ) : (
                ""
              );
            },
            header: () => (
              <div className="sr-only">
                {t("BUILDING_VISITORS.HOST_REPLIED")}
              </div>
            ),
          },
        ),
        // TODO: refine the condition when other access systems are integrated
        ...(building.accessSystem === AccessSystem.STARWATCH
          ? [
              columnHelper.accessor(
                (row) => (isVisit(row) ? row.letInAt : null),
                {
                  id: "letInAt",
                  cell: (info) => {
                    const letInAt = info.getValue(); 
                    if (!letInAt && !isVisit(info.row.original)) {
                      return null;
                    }

                    let timestamp;
                    if (letInAt) {
                      timestamp = formatTimestampToHoursAndMinutes(letInAt);
                    }
                    const isLoading = loadingIndex === info.row.index;

                    return letInAt ? (
                      <>
                        <Icon
                          name="key"
                          className={`${styles.greenIcon} visitor-let-in-key-icon-${info.row.index}`}
                        />
                        <span className="sr-only">
                          {t("BUILDING_VISITORS.LET_IN")}
                        </span>
                        <Tooltip
                          anchorSelect={`.visitor-let-in-key-icon-${info.row.index}`}
                          content={t("BUILDING_VISITORS.ACCESS_GRANTED", {
                            timestamp,
                          })}
                        />
                      </>
                    ) : (
                      <Button
                        onClick={() => {
                          if (isVisit(info.row.original)) {
                            letVisitorIn(info.row.original, info.row.index);
                          }
                        }}
                        isLoading={isLoading}
                      >
                        {t("BUILDING_VISITORS.LET_IN")}
                      </Button>
                    );
                  },
                  header: () => (
                    <div className="sr-only">
                      {t("BUILDING_VISITORS.LET_IN")}
                    </div>
                  ),
                },
              ),
            ]
          : []),
      ].filter(Boolean) as ColumnDef<
        Visit | ExtendedPreRegistrationAttendee,
        unknown
      >[],
    [entrances?.length, t, loadingIndex],
  );

  const table = useReactTable<Visitor>({
    data: filteredVisitors,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: Number(localStorage.getItem("visitorsPerPage") || 15),
      },
    },
  });

  useEffect(() => {
    if (combinedData) {
      const reversedData = [...combinedData].reverse();
      let filteredData = reversedData;

      if (searchTerm) {
        filteredData = reversedData.filter((visitor) => {
          if (isVisit(visitor)) {
            return searchableColumns.some((column) =>
              (
                visitor[column as keyof Visit]?.toString().toLowerCase() || ""
              ).includes(searchTerm.toLowerCase()),
            );
          } else {
            return searchableColumns.some((column) =>
              (
                visitor[column as keyof ExtendedPreRegistrationAttendee]
                  ?.toString()
                  .toLowerCase() || ""
              ).includes(searchTerm.toLowerCase()),
            );
          }
        });
      }

      // Only update state if the filtered data has changed
      setFilteredVisitors((prevFilteredVisitors) => {
        const prevDataString = JSON.stringify(prevFilteredVisitors);
        const newDataString = JSON.stringify(filteredData);
        if (prevDataString !== newDataString) {
          return filteredData;
        }
        return prevFilteredVisitors;
      });
    }
  }, [searchTerm, combinedData]);

  const letVisitorIn = async (visitor: Visit, index: number) => {
    setLoadingIndex(index);
    try {
      const company = !visitor.hostName;
      await letInVisitor(
        visitor.id,
        company,
        visitor.visitorName,
        visitor.visitorPhone,
        visitor.language,
      );

      addNotification(
        t("BUILDING_VISITORS.ACCESS_CODE_SENT"),
        NotificationType.SUCCESS,
      );
      queryClient.invalidateQueries({ queryKey: ["visitors-building"] });
    } catch (error) {
      console.error(error);
      addNotification("An unexpected error occurred.");
    } finally {
      setLoadingIndex(null);
    }
  };

  return (
    <>
      <div className={styles.upperButtons}>
        <div className={styles.searchContainer}>
          <InputField
            label={t("BUILDING_VISITORS.SEARCH_PLACEHOLDER")}
            placeholder={t("BUILDING_VISITORS.SEARCH_PLACEHOLDER") + "..."}
            icon="search"
            value={searchTerm}
            compact
            hideLabel
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <Button
          icon="add"
          onClick={() => navigate({ to: "/building/visitors/preregistration" })}
        >
          {t("PAGES.VISITORS.CREATE_PREREGISTRATION")}
        </Button>
      </div>
      <Table
        table={table}
        noDataMessage={t("BUILDING_VISITORS.NO_VISITORS")}
        onRowClick={(visit) => {
          setSelectedVisitor(visit);
        }}
        totalItems={filteredVisitors.length}
        paginationKey="visitorsPerPage"
      />
    </>
  );
};
