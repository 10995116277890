import React, { ReactNode, useState } from "react";
import Cropper from "./Cropper";
import { Size } from "react-easy-crop";
import {
  calculateMaxDimensions,
  getAspectRatio,
  readFile,
  toBase64,
} from "./helpers";
import ImageCropProvider, { useImageCropContext } from "./ImageCropProvider";
import { Button } from "../Button/button";
import { Icon } from "../Icon/icon";
import styles from "./ImageInput.module.scss";
import { StatusText } from "../StatusText/StatusText";

interface ImageInputProps {
  id: string;
  placeholder: ReactNode;
  allowedFileTypes?: string[];
  maxFileSize?: number;
  translations: {
    uploadImageOverlayText: string;
    replaceImageAriaLabel: string;
    replaceImageOverlayText: string;
    undoChangeButtonText: string;
    editDoneText: string;
    editCancelText: string;
    fileTooLargeText: string;
  };
  strategy?: "crop" | "cover";
  originalImageUrl?: string;
  currImageUrl?: string;
  onChange: (data: { previewUrl: string; encoded: string; raw: Blob }) => void;
  onError: (error: string) => void;
  onUndo?: (originalImgUrl: string) => void;
  menuPlacement?: "top" | "bottom";
  cropShape?: "round" | "rect";
  aspectRatio?: number | "auto";
  showGrid?: boolean;
  cropSize?: Size; // sets specific dimensions which override aspect-ratio
  cropBounds?: Size; // sets dimension boundaries which will respect image's original aspect-ratio
  style?: {
    componentStyle?: React.CSSProperties;
    overlayStyle?: React.CSSProperties;
    previewStyle?: {
      container?: React.CSSProperties;
      image?: React.CSSProperties;
    };
    containerStyle?: React.CSSProperties;
    mediaStyle?: React.CSSProperties;
    cropAreaStyle?: React.CSSProperties;
    menuStyle?: React.CSSProperties;
  };
  tips?: ReactNode;
}

function InputComponent({
  id,
  placeholder,
  allowedFileTypes = [".png", ".svg", ".jpg", ".jpeg"],
  maxFileSize = 5 * 1024 * 1024, // 5 MB in bytes
  translations,
  strategy = "crop",
  currImageUrl,
  originalImageUrl,
  onChange,
  onError,
  onUndo,
  menuPlacement,
  cropShape,
  aspectRatio,
  showGrid,
  cropSize,
  cropBounds,
  style,
  tips,
}: ImageInputProps) {
  const context = useImageCropContext();
  if (!context)
    throw new Error(
      "<ImageInput /> is missing it's context. Check the <ImageCropProvider />",
    );
  const {
    zoom,
    setImage,
    getProcessedImage,
    resetStates,
    handleZoomIn,
    handleZoomOut,
  } = context;

  const maxFileSizeMB = (maxFileSize / 1024 / 1024).toFixed(1);

  const [displayCropper, setDisplayCropper] = useState(false);
  const [preview, setPreview] = useState<string | undefined>(currImageUrl);
  const [processing, setProcessing] = useState(false);
  const [inputKey, setInputKey] = useState(new Date().getTime().toString());
  const [computedAspectRatio, setComputedAspectRatio] = useState<number>();
  const [computedCropSize, setComputedCropSize] = useState<Size | undefined>(
    cropSize,
  );
  const [fileTooLarge, setFileTooLarge] = useState(false);

  const handleCancel = () => {
    setPreview(originalImageUrl);
    resetStates();
    setDisplayCropper(false);
  };

  const handleDone = async (imageOverride?: File) => {
    setProcessing(true);
    const image = imageOverride ?? (await getProcessedImage());
    if (!image) {
      onError("Failed to process image. Try again later!");
      return;
    }
    const previewUrl = window.URL.createObjectURL(image);
    const encoded = await toBase64(image);
    if (!encoded || typeof encoded !== "string") {
      onError("Failed to encode image. Try again later!");
      return;
    }
    setPreview(previewUrl);
    resetStates();
    setDisplayCropper(false);
    onChange({ previewUrl, encoded, raw: image });
    setProcessing(false);
  };

  const handleFileChange = async ({
    target: { files },
  }: {
    target: { files: FileList | null };
  }) => {
    setInputKey(new Date().getTime().toString()); // reset input so same image can be uploaded again if needed
    setFileTooLarge(false);

    const file = files && files[0];
    if (!file) throw new Error("Uploaded file is null");

    if (file.size > maxFileSize) {
      setFileTooLarge(true);
      return;
    }

    if (strategy === "crop") {
      const imageDataUrl = await readFile(file);
      if (typeof imageDataUrl !== "string") {
        onError("Failed to read image. Try again later!");
        return;
      }
      if (aspectRatio === "auto") {
        const aspectRatio = await getAspectRatio(imageDataUrl);
        if (cropBounds) {
          setComputedCropSize(
            calculateMaxDimensions(
              aspectRatio,
              cropBounds.width,
              cropBounds.height,
            ),
          );
        } else {
          setComputedAspectRatio(aspectRatio);
        }
      }
      setImage(imageDataUrl);
      setDisplayCropper(true);
    } else {
      handleDone(file);
    }
  };

  const stopPropagation = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <div className={styles.container} style={style?.componentStyle}>
        {displayCropper ? (
          <>
            <Cropper
              cropShape={cropShape}
              aspectRatio={
                aspectRatio === "auto" ? computedAspectRatio : aspectRatio
              }
              showGrid={showGrid}
              cropSize={computedCropSize}
              style={{
                ...style,
                cropAreaStyle: {
                  ...style?.cropAreaStyle,
                  border: "1px solid black",
                },
              }}
            />
            <div
              className={styles.imageControls}
              style={{
                ...style?.menuStyle,
                ...(menuPlacement === "top"
                  ? {
                      top: 0,
                      transform:
                        "translateY(calc(-100% - var(--wlcm-spacing-xs)))",
                    }
                  : undefined),
              }}
            >
              <div className={styles.zoomControls}>
                <Button
                  dark
                  onClick={(e) => {
                    stopPropagation(e);
                    handleZoomOut();
                  }}
                >
                  <Icon name="zoom_out" />
                </Button>
                <p>{zoom}x</p>
                <Button
                  dark
                  onClick={(e) => {
                    stopPropagation(e);
                    handleZoomIn();
                  }}
                >
                  <Icon name="zoom_in" />
                </Button>
              </div>
              <Button
                dark
                className={styles.cancelButton}
                onClick={(e) => {
                  stopPropagation(e);
                  handleCancel();
                }}
              >
                {translations.editCancelText}
              </Button>
              <Button
                className={styles.finishButton}
                onClick={(e) => {
                  stopPropagation(e);
                  handleDone();
                }}
                isLoading={processing}
              >
                {translations.editDoneText}
              </Button>
            </div>
          </>
        ) : preview ? (
          <label htmlFor={id} style={style?.previewStyle?.container}>
            <span className="sr-only">
              {translations.replaceImageAriaLabel}
            </span>
            <img
              aria-hidden
              src={preview}
              style={{
                ...style?.previewStyle?.image,
                ...(fileTooLarge
                  ? { outline: "1px solid var(--wlcm-color-danger-red)" }
                  : {}),
              }}
              width="100%"
            />
            <div
              className={styles.uploadOverlay}
              style={style?.overlayStyle ?? {}}
            >
              <Icon name="upload" />
              <p>
                <strong>{translations.replaceImageOverlayText}</strong>
              </p>
              <p className={styles.allowedImageFormats}>
                ({allowedFileTypes.join(", ")})
              </p>
              <p className={styles.maxFileSize}>Max {maxFileSizeMB} MB</p>
              {preview !== originalImageUrl && (
                <Button
                  dark
                  onClick={(e) => {
                    stopPropagation(e);
                    setPreview(originalImageUrl);
                    setFileTooLarge(false);
                    if (onUndo) onUndo(originalImageUrl || "");
                  }}
                  icon="undo"
                  className={styles.undoButton}
                >
                  {translations.undoChangeButtonText}
                </Button>
              )}
            </div>
          </label>
        ) : (
          <label htmlFor={id}>
            {placeholder}
            <div
              className={styles.uploadOverlay}
              style={style?.overlayStyle ?? {}}
            >
              <Icon name="upload" />
              <p>
                <strong>{translations.uploadImageOverlayText}</strong>
              </p>
              <p className={styles.allowedImageFormats}>
                ({allowedFileTypes.join(", ")})
              </p>
              <p className={styles.maxFileSize}>Max {maxFileSizeMB} MB</p>
            </div>
          </label>
        )}
        <input
          key={inputKey}
          type="file"
          onChange={handleFileChange}
          id={id}
          accept={allowedFileTypes.join(", ")}
        />
      </div>
      {fileTooLarge && (
        <div style={{ marginTop: "4px", lineHeight: "normal" }}>
          <StatusText fail text={translations.fileTooLargeText} />
        </div>
      )}
      {tips}
    </>
  );
}

export function ImageInput(props: ImageInputProps) {
  return (
    <div style={{ display: "contents" }} onChange={(e) => e.stopPropagation()}>
      <ImageCropProvider>
        <InputComponent {...props} />
      </ImageCropProvider>
    </div>
  );
}
