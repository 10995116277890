import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-http-backend";

import commonNo from "./messages/no.json";
import commonEn from "./messages/en.json";

const resources = {
  no: { common: commonNo },
  en: { common: commonEn },
};

const options = {
  order: ["localStorage"],
  caches: ["localStorage"],
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources,
    ns: ["common"],
    defaultNS: "common",
    fallbackLng: "no",
    supportedLngs: ["no", "en"],
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export default i18n;
