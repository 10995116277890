import client, { throwCaughtError } from "../client";
import { Entrance } from "@/types";
import {
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";

export const useEntrance = (
  entranceId: string,
): UseSuspenseQueryResult<Entrance> => {
  return useSuspenseQuery({
    queryKey: ["entrance", entranceId],
    queryFn: async () => {
      try {
        const response = await client.get(
          `/getEntranceById?entranceId=${entranceId}`,
        );
        return response.data;
      } catch (error) {
        throwCaughtError(error);
      }
    },
  });
};

export const useAllEntrances = (): UseSuspenseQueryResult<Entrance[]> => {
  return useSuspenseQuery({
    queryKey: ["entrances"],
    queryFn: async () => {
      try {
        const response = await client.get("/getAllEntrances");
        return response.data;
      } catch (error) {
        throwCaughtError(error);
      }
    },
  });
};

export const createEntrance = async (data: Entrance) => {
  try {
    const response = await client.post("/createEntrance", data);
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const updateEntrance = async (
  entranceId: string,
  updateData: Partial<Entrance>,
) => {
  try {
    const response = await client.patch("/updateEntrance", {
      entranceId,
      updateData,
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const deleteEntrance = async (entranceId: string) => {
  try {
    const response = await client.delete("/deleteEntrance", {
      data: { entranceId },
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};
