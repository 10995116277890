import { useExtendedAccessCards } from "@/api";
import AccessCardDetailsModal from "@/components/access_cards/AccessCardDetailsModal";
import SortableTableHeader from "@/components/table/SortableTableHeader";
import Table from "@/components/table/Table";
import i18n from "@/i18n/config";
import { accessCardStatusColorMap } from "@/lib/constants";
import { formatTimestampToTimeAgo, timestampSortingFn } from "@/lib/utils";
import { AccessCardType, CardType } from "@/types";
import { Icon, InputField } from "@app-components";
import { createFileRoute } from "@tanstack/react-router";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const searchableColumns: Array<string> = [
  "cardNumber",
  "orderedBy.company",
  "orderedBy.name",
  "userName",
];

export const Route = createFileRoute("/building/access")({
  component: AccessCards,
});

const columnHelper = createColumnHelper<AccessCardType>();

function AccessCards() {
  const { t } = useTranslation();
  const { data: allCards } = useExtendedAccessCards(false);
  const { data: cardRequests } = useExtendedAccessCards(true);
  const [filteredRequests, setFilteredRequests] = useState<AccessCardType[]>(
    [],
  );
  const [filteredCards, setFilteredCards] = useState<AccessCardType[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCard, setSelectedCard] = useState<AccessCardType>();
  const [modalOpen, setModalOpen] = useState(false);
  const [reqSorting, setReqSorting] = useState<SortingState>([
    {
      id: "status",
      desc: false,
    },
  ]);
  const [allSorting, setAllSorting] = useState<SortingState>([
    {
      id: "status",
      desc: false,
    },
  ]);

  const baseColumns = (isCardOrders: boolean) => [
    columnHelper.accessor("status", {
      cell: (info) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "var(--wlcm-spacing-xs)",
          }}
        >
          <div
            aria-hidden
            style={{
              width: "1em",
              height: "1em",
              borderRadius: 100,
              backgroundColor: accessCardStatusColorMap[info.getValue()],
            }}
          ></div>
          <span>
            {t(`ACCESS_CARD.STATUS.${info.getValue().toUpperCase()}.LABEL`)}
          </span>
        </div>
      ),
      header: (info) => (
        <SortableTableHeader
          info={info}
          tableHeaderId="status"
          label="Status"
        />
      ),
    }),
    columnHelper.accessor("cardNumber", {
      cell: (info) => info.getValue() || "-",
      header: () => <div>{t("ACCESS_CARD.TABLE_HEADERS.CARD_NUMBER")}</div>,
    }),
    ...(isCardOrders
      ? [
          columnHelper.accessor("createdAt", {
            cell: (info) => {
              const timestamp = info.getValue();
              return timestamp
                ? formatTimestampToTimeAgo(t, timestamp, i18n.language, true)
                : "-";
            },
            header: (info) => (
              <SortableTableHeader
                info={info}
                tableHeaderId="createdAt"
                label={t("ACCESS_CARD.TABLE_HEADERS.ORDERED_AT")}
              />
            ),
            sortingFn: timestampSortingFn,
          }),
          columnHelper.accessor("inactivatedAt", {
            cell: (info) => {
              const timestamp = info.getValue();
              return timestamp
                ? formatTimestampToTimeAgo(t, timestamp, i18n.language, true)
                : "-";
            },
            header: (info) => (
              <SortableTableHeader
                info={info}
                tableHeaderId="inactivatedAt"
                label={t("ACCESS_CARD.TABLE_HEADERS.INACTIVATED_AT")}
              />
            ),
            sortingFn: timestampSortingFn,
          }),
        ]
      : [
          columnHelper.accessor("activatedAt", {
            cell: (info) => {
              const timestamp = info.getValue();
              return timestamp
                ? formatTimestampToTimeAgo(t, timestamp, i18n.language, true)
                : "-";
            },
            header: (info) => (
              <SortableTableHeader
                info={info}
                tableHeaderId="activatedAt"
                label={t("ACCESS_CARD.TABLE_HEADERS.ACTIVATED_AT")}
              />
            ),
            sortingFn: timestampSortingFn,
          }),
          columnHelper.accessor("deactivatedAt", {
            cell: (info) => {
              const timestamp = info.getValue();
              return timestamp
                ? formatTimestampToTimeAgo(t, timestamp, i18n.language, true)
                : "-";
            },
            header: (info) => (
              <SortableTableHeader
                info={info}
                tableHeaderId="deactivatedAt"
                label={t("ACCESS_CARD.TABLE_HEADERS.DEACTIVATED_AT")}
              />
            ),
            sortingFn: timestampSortingFn,
          }),
        ]),
    columnHelper.accessor("orderedBy.company", {
      cell: (info) => info.getValue(),
      header: () => <div>{t("ACCESS_CARD.TABLE_HEADERS.COMPANY")}</div>,
    }),
    columnHelper.accessor("orderedBy", {
      cell: (info) => {
        const orderedBy = info.getValue();
        return `${orderedBy?.name || "-"} ${orderedBy?.email ? "(" + orderedBy.email + ")" : ""}`;
      },
      header: () => <div>{t("ACCESS_CARD.TABLE_HEADERS.ORDERED_BY")}</div>,
    }),
    columnHelper.accessor("userName", {
      cell: (info) => info.getValue() || "-",
      header: () => <div>{t("ACCESS_CARD.TABLE_HEADERS.RECIPIENT")}</div>,
    }),
    columnHelper.accessor("type", {
      cell: (info) =>
        info.getValue() === CardType.PHYSICAL ? (
          <>
            <Icon name="badge" className="physical-card-icon" />
            <Tooltip
              anchorSelect=".physical-card-icon"
              content={t("ACCESS_CARD.ARIA_DESCRIPTION.PHYSICAL")}
            />
          </>
        ) : (
          <>
            <Icon name="phone_android" className="digital-card-icon" />
            <Tooltip
              anchorSelect=".digital-card-icon"
              content={t("ACCESS_CARD.ARIA_DESCRIPTION.DIGITAL")}
            />
          </>
        ),
      header: () => <div>Type</div>,
    }),
  ];

  const requestColumns = useMemo(
    // memoize so columns don't need to be "re-rendered" when component changes
    () => baseColumns(true),
    [],
  );

  const restColumns = useMemo(
    // memoize so columns don't need to be "re-rendered" when component changes
    () => baseColumns(false),
    [],
  );

  const requestsTable = useReactTable({
    data: filteredRequests,
    columns: requestColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: Number(localStorage.getItem("cardOrdersPerPage") || 15),
      },
    },
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: reqSorting,
    },
    onSortingChange: setReqSorting,
  });

  const restTable = useReactTable({
    data: filteredCards,
    columns: restColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: Number(localStorage.getItem("accessCardsPerPage") || 15),
      },
    },
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: allSorting,
    },
    onSortingChange: setAllSorting,
  });

  useEffect(() => {
    if (searchTerm) {
      console.log(searchTerm, "REQS: ", cardRequests.length);
      // get every card that has an attribute from searchableColumns that starts with the searchTerm
      if (cardRequests.length) {
        setFilteredRequests(
          cardRequests.filter((card: AccessCardType) =>
            searchableColumns.some((column) => {
              const val = card[column as keyof AccessCardType];
              return val
                ? (val?.toString().toLowerCase() || "").startsWith(
                    searchTerm.toLowerCase(),
                  )
                : false;
            }),
          ),
        );
      }
      if (allCards.length) {
        setFilteredCards(
          allCards.filter((card: AccessCardType) =>
            searchableColumns.some((column) => {
              const val = card[column as keyof AccessCardType];
              return val
                ? (val?.toString().toLowerCase() || "").startsWith(
                    searchTerm.toLowerCase(),
                  )
                : false;
            }),
          ),
        );
      }
    } else {
      setFilteredRequests(cardRequests);
      setFilteredCards(allCards);
    }
  }, [searchTerm, cardRequests, allCards]);

  return (
    <>
      <div className="pageHeader">
        <h1>{t("PAGES.BUILDING_ACCESS.HEADER")}</h1>
        <p>{t("PAGES.BUILDING_ACCESS.SUBHEADER")}</p>
      </div>
      <InputField
        label={t("ACCESS_CARD.SEARCH")}
        placeholder={t("ACCESS_CARD.SEARCH") + "..."}
        icon="search"
        value={searchTerm}
        compact
        hideLabel
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ maxWidth: "30rem" }}
      />
      <h2 style={{ marginTop: "var(--wlcm-spacing-sm)" }}>
        {t("ACCESS_CARD.REQUESTS_HEADER")}{" "}
        <span style={{ opacity: 0.5, marginLeft: "var(--wlcm-spacing-xs)" }}>
          {cardRequests.length} {t("TOTAL")}
          {searchTerm &&
            t("ACCESS_CARD.HEADER_COUNT_SEARCH", {
              count: filteredRequests.length,
            })}
        </span>
      </h2>
      <Table
        table={requestsTable}
        totalItems={
          filteredRequests && filteredRequests.length >= 15
            ? filteredRequests.length
            : undefined
        }
        onRowClick={(card) => {
          setSelectedCard(card);
          setModalOpen(true);
        }}
        paginationKey="cardOrdersPerPage"
        noDataMessage={t("PAGES.BUILDING_ACCESS.REQUESTS_PLACEHOLDER")}
      />
      <h2 style={{ marginTop: "var(--wlcm-spacing-sm)" }}>
        {t("ACCESS_CARD.ALL_HEADER")}{" "}
        <span style={{ opacity: 0.5, marginLeft: "var(--wlcm-spacing-xs)" }}>
          {allCards.length} {t("TOTAL")}
          {searchTerm &&
            t("ACCESS_CARD.HEADER_COUNT_SEARCH", {
              count: filteredCards.length,
            })}
        </span>
      </h2>
      <Table
        table={restTable}
        totalItems={
          filteredCards && filteredCards.length >= 15
            ? filteredCards.length
            : undefined
        }
        onRowClick={(card) => {
          setSelectedCard(card);
          setModalOpen(true);
        }}
        paginationKey="accessCardsPerPage"
        noDataMessage={t("PAGES.BUILDING_ACCESS.ALL_PLACEHOLDER")}
      />
      {modalOpen && selectedCard && (
        <AccessCardDetailsModal
          buildingAdminMode
          accessCard={selectedCard}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}
