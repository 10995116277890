import { PreRegistrationAttendee } from "@/types";
import { InputField, Button, Icon } from "@app-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "@/styles/Preregistration.module.scss";
import { EMAIL_REGEX, NAME_REGEX, PHONE_REGEX } from "@/lib/constants";
import {
  addNorwegianCountryCodeToNorwegianNumbers,
  cleanPhoneNumber,
} from "@/lib/utils";

interface AttendeeInputRowProps {
  value: PreRegistrationAttendee;
  onChange: (value: PreRegistrationAttendee) => void;
  onAdd: (value: PreRegistrationAttendee) => void;
}

export const AttendeeInputRow = ({
  value,
  onChange,
  onAdd,
}: AttendeeInputRowProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState({ name: "", phone: "", email: "" });
  const [phoneWarning, setPhoneWarning] = useState("");

  // Validation
  // Since I am not using zod here, I have custom validation function
  const validate = (attendee: PreRegistrationAttendee) => {
    const errors: { name: string; phone: string; email: string } = {
      name: "",
      phone: "",
      email: "",
    };

    if (!attendee.name.trim()) {
      errors.name = t("VALIDATION.REQUIRED", {
        field: t("USERS.TABLE_HEADERS.NAME"),
      });
    }

    if (!NAME_REGEX.test(attendee.name)) {
      errors.name = t("VALIDATION.FORBIDDEN_CHARACTERS");
    }

    if (attendee.name.length < 3) {
      errors.name = t("VALIDATION.MIN_LENGTH", {
        field: t("USERS.TABLE_HEADERS.NAME"),
        length: 3,
        type: t("CHARACTERS"),
      });
    }

    if (attendee.name.length > 30) {
      errors.name = t("VALIDATION.MAX_LENGTH", {
        field: t("USERS.TABLE_HEADERS.NAME"),
        length: 30,
        type: t("CHARACTERS"),
      });
    }

    if (attendee.phone) {
      attendee.phone = addNorwegianCountryCodeToNorwegianNumbers(
        cleanPhoneNumber(attendee.phone),
      );
    }

    if (!attendee.phone.trim()) {
      errors.phone = t("VALIDATION.REQUIRED", {
        field: t("USERS.TABLE_HEADERS.PHONE"),
      });
    }

    if (!PHONE_REGEX.test(attendee.phone)) {
      errors.phone = t("VALIDATION.INVALID_PHONE");
    }

    if (value.email && !EMAIL_REGEX.test(value.email)) {
      errors.email = t("VALIDATION.INVALID", {
        field: t("ADD_USER.EMAIL"),
      });
    }

    setError(errors);
    return errors;
  };

  const handleBlur = () => {
    if (value.phone) {
      if (value.phone.includes("+47") && value.phone.length !== 11) {
        setPhoneWarning(t("VALIDATION.SUS_PHONE"));
      } else {
        setPhoneWarning("");
      }
    }

    validate(value);
  };

  const addAttendee = () => {
    const errors = validate(value);
    if (!errors.name && !errors.phone && !errors.email) {
      onAdd(value);
    }
  };

  const handleFieldChange = (
    field: keyof PreRegistrationAttendee,
    val: string,
  ) => {
    onChange({ ...value, [field]: val });
  };

  return (
    <div className={styles.attandeesContainer}>
      <p>{t("PREREGISTRATION.ADD_ATTENDEES")}</p>
      <div className={styles.attendeeRow}>
        <InputField
          compact
          label={t("USERS.TABLE_HEADERS.NAME")}
          value={value.name}
          onChange={(e) => handleFieldChange("name", e.target.value)}
          onBlur={handleBlur}
          danger={!!error.name}
          dangerText={error.name}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              addAttendee();
            }
          }}
        />
        <InputField
          compact
          label={t("USERS.TABLE_HEADERS.PHONE")}
          value={value.phone}
          onChange={(e) => handleFieldChange("phone", e.target.value)}
          onBlur={handleBlur}
          onFocus={() => {
            if (!value.phone) {
              handleFieldChange("phone", "+47");
            }
          }}
          danger={!!error.phone}
          dangerText={error.phone}
          infoText={phoneWarning}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              addAttendee();
            }
          }}
        />
        <InputField
          compact
          label={t("USERS.TABLE_HEADERS.EMAIL")}
          value={value.email}
          onChange={(e) => handleFieldChange("email", e.target.value)}
          danger={!!error.email}
          dangerText={error.email}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              addAttendee();
            }
          }}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addAttendee();
          }}
        >
          <Icon name="add" />
        </Button>
      </div>
    </div>
  );
};
