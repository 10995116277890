import { flexRender, Table as ReactTable } from "@tanstack/react-table"; // Adjust imports according to your table library
import TablePagination from "./TablePagination";
import { LoadingSpinner } from "@app-components";

interface TableProps<T> {
  table: ReactTable<T>;
  isLoading?: boolean;
  noDataMessage?: string;
  onRowClick?: (rowData: T) => void;
  totalItems?: number;
  paginationKey?: string;
  className?: string;
  customPageSizeOptions?: number[];
}

function Table<T>({
  table,
  isLoading,
  noDataMessage,
  onRowClick,
  totalItems,
  paginationKey,
  className,
  customPageSizeOptions,
}: TableProps<T>) {
  const rows = table.getRowModel().rows;

  return (
    <div className={`${className} tableContainer`}>
      <table cellSpacing={0}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading ? (
            <tr className="noDataMessage">
              <td colSpan={table.getAllColumns().length}>
                <div className="center">
                  <LoadingSpinner />
                </div>
              </td>
            </tr>
          ) : rows.length === 0 && noDataMessage ? (
            <tr className="noDataMessage">
              <td colSpan={table.getAllColumns().length}>{noDataMessage}</td>
            </tr>
          ) : (
            rows.map((row) => (
              <tr
                key={row.id}
                onClick={() => onRowClick && onRowClick(row.original)}
                style={!onRowClick ? { cursor: "unset" } : {}}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
      {!!totalItems && (
        <TablePagination
          table={table}
          totalItems={totalItems}
          localStoragePageSizeKey={paginationKey || "defaultPaginationKey"}
          customPageSizeOptions={customPageSizeOptions}     
        />
      )}
    </div>
  );
}

export default Table;
