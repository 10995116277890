import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import getCroppedImg, { PixelCrop } from "./helpers";

export const ImageCropContext = createContext<
  | {
      image?: string;
      setImage: Dispatch<SetStateAction<string | undefined>>;
      zoom: number;
      setZoom: Dispatch<SetStateAction<number>>;
      rotation: number;
      setRotation: Dispatch<SetStateAction<number>>;
      crop: { x: number; y: number };
      setCrop: Dispatch<SetStateAction<{ x: number; y: number }>>;
      croppedAreaPixels: PixelCrop;
      setCroppedAreaPixels: Dispatch<SetStateAction<PixelCrop>>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onCropComplete: (_croppedArea: any, croppedAreaPixels: PixelCrop) => void;
      getProcessedImage: () => Promise<Blob | undefined>;
      handleZoomIn: () => void;
      handleZoomOut: () => void;
      handleRotateAntiCw: () => void;
      handleRotateCw: () => void;
      max_zoom: number;
      min_zoom: number;
      zoom_step: number;
      max_rotation: number;
      min_rotation: number;
      rotation_step: number;
      resetStates: () => void;
    }
  | undefined
>(undefined);

const defaultImage = undefined;
const defaultCrop = { x: 0, y: 0 };
const defaultRotation = 0;
const defaultZoom = 1;
const defaultCroppedAreaPixels = null;

interface ImageCropProviderProps {
  children: ReactNode;
  max_zoom?: number;
  min_zoom?: number;
  zoom_step?: number;
  max_rotation?: number;
  min_rotation?: number;
  rotation_step?: number;
}

const ImageCropProvider = ({
  children,
  max_zoom = 3,
  min_zoom = 0.5,
  zoom_step = 0.1,
  max_rotation = 360,
  min_rotation = 0,
  rotation_step = 5,
}: ImageCropProviderProps) => {
  const [image, setImage] = useState<string | undefined>(defaultImage);
  const [crop, setCrop] = useState(defaultCrop);
  const [rotation, setRotation] = useState(defaultRotation);
  const [zoom, setZoom] = useState(defaultZoom);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<PixelCrop>(
    defaultCroppedAreaPixels,
  );

  const onCropComplete = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const handleZoomIn = () => {
    if (zoom < max_zoom) {
      setZoom(Number((zoom + zoom_step).toFixed(1)));
    }
  };

  const handleZoomOut = () => {
    if (zoom > min_zoom) {
      setZoom(Number((zoom - zoom_step).toFixed(1)));
    }
  };

  const handleRotateCw = () => {
    setRotation(rotation + rotation_step);
  };

  const handleRotateAntiCw = () => {
    setRotation(rotation - rotation_step);
  };

  const getProcessedImage = async (): Promise<Blob | undefined> => {
    if (image && croppedAreaPixels) {
      const matches = image.match(/^data:(image\/[\w+]+);base64,(.+)$/);
      if (!matches) throw new Error("Image is not base64");
      const contentType = matches[1];
      const extension = contentType.split("/")[1].split("+")[0];

      const croppedImage = await getCroppedImg(
        contentType,
        image,
        croppedAreaPixels,
        rotation,
      );
      if (!croppedImage) throw new Error("Cropped image is null");
      const imageFile = new File(
        [croppedImage.file],
        `img-${Date.now()}.${extension}`,
        {
          type: contentType,
        },
      );
      return imageFile;
    }
  };

  const resetStates = () => {
    setImage(defaultImage);
    setCrop(defaultCrop);
    setRotation(defaultRotation);
    setZoom(defaultZoom);
    setCroppedAreaPixels(defaultCroppedAreaPixels);
  };

  return (
    <ImageCropContext.Provider
      value={{
        image,
        setImage,
        zoom,
        setZoom,
        rotation,
        setRotation,
        crop,
        setCrop,
        croppedAreaPixels,
        setCroppedAreaPixels,
        onCropComplete,
        getProcessedImage,
        handleZoomIn,
        handleZoomOut,
        handleRotateAntiCw,
        handleRotateCw,
        max_zoom,
        min_zoom,
        zoom_step,
        max_rotation,
        min_rotation,
        rotation_step,
        resetStates,
      }}
    >
      {children}
    </ImageCropContext.Provider>
  );
};

export const useImageCropContext = () => useContext(ImageCropContext);

export default ImageCropProvider;
