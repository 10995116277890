import styles from "@/styles/Visitors.module.scss";
import { Icon } from "@app-components";

interface IVisitorsPlaceholderProps {
  company?: boolean;
  icon: string;
  placeholder: string;
}

export default function VisitorsPlaceholder({
  icon,
  placeholder,
}: IVisitorsPlaceholderProps) {
  return (
    <div className={`${styles.cards} ${styles.placeholderContainer}`}>
      <div
        className={styles.visitorPlaceholder}
        id={styles["p-p-1"]}
        aria-hidden
      >
        <Icon name={icon} />
      </div>
      <div
        className={styles.visitorPlaceholder}
        id={styles["p-p-2"]}
        aria-hidden
      >
        <Icon name={icon} />
      </div>
      <div
        className={styles.visitorPlaceholder}
        id={styles["p-p-3"]}
        aria-hidden
      >
        <Icon name={icon} />
      </div>
      <div className={styles.overlay} aria-hidden></div>
      <h2>
        {placeholder}
      </h2>  
    </div>
  );
}
