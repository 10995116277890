import { Button, InputField, PopupCard } from "@app-components";
import { createFileRoute } from "@tanstack/react-router";
import { useContext, useState } from "react";
import { UserIdContext } from "./$userId";
import { createAccessCard, useAccessCards, useCompany, useUser } from "@/api";
import { useLoggedInUser } from "@/providers/AuthProvider";
import { CardType, NotificationType } from "@/types";
import { useSnackbarStore } from "@/store/zustandStore";
import { useQueryClient } from "@tanstack/react-query";
import styles from "@/styles/Access.module.scss";
import AccessCard from "@/components/access_cards/AccessCard";
import { useTranslation } from "react-i18next";

export const Route = createFileRoute("/company/users/$userId/access")({
  component: UserDetailsAccess,
});

function UserDetailsAccess() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const currentUser = useLoggedInUser();
  const userId = useContext(UserIdContext);
  const { addNotification } = useSnackbarStore();
  const { data: user } = useUser(userId);
  const { data: company } = useCompany(currentUser.buildingTenantId);
  const { data: accessCards } = useAccessCards(userId, true);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [orderNote, setOrderNote] = useState("");
  const [isOrdering, setIsOrdering] = useState(false);

  const handleCreatePlasticCardOrder = async () => {
    // TODO: add validitiy check on orderNote
    try {
      setIsOrdering(true);
      await createAccessCard(
        CardType.PHYSICAL,
        { userId: currentUser.userId, company: company.buildingTenantName },
        userId,
        orderNote,
      );
      setShowOrderModal(false);
      addNotification(
        "Plastic card has been ordered",
        NotificationType.SUCCESS,
      );
      queryClient.invalidateQueries({ queryKey: ["access-cards"] });
      queryClient.invalidateQueries({ queryKey: ["extended-access-cards"] });
    } catch (error) {
      console.error(error);
      addNotification("Failed to send card order. Please try again later.");
    } finally {
      setIsOrdering(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginBottom: "var(--wlcm-spacing-sm)",
        }}
      >
        <Button
          icon="add"
          disabled={isOrdering}
          onClick={() => setShowOrderModal(true)}
        >
          {t("ACCESS_CARD.ORDER_PHYSICAL")}
        </Button>
      </div>
      {!accessCards.length ? (
        <p
          style={{
            textAlign: "center",
            fontSize: "var(--wlcm-text-md)",
            opacity: 0.5,
            marginTop: "var(--wlcm-spacing-md",
            marginBottom: "var(--wlcm-spacing-xl",
          }}
        >
          {t("ACCESS_CARD.COMPANY_ADMIN_PLACEHOLDER")}
        </p>
      ) : (
        <div className={styles.cardContainer}>
          {accessCards.map((card) => (
            <AccessCard
              key={card.id}
              data={card}
              cardUser={user}
              companyAdminView
            />
          ))}
        </div>
      )}
      {showOrderModal && (
        <PopupCard onClose={() => setShowOrderModal(false)}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--wlcm-spacing-sm)",
            }}
          >
            <h3>{t("ACCESS_CARD.ORDER_PHYSICAL")}</h3>
            <div>
              <p className={styles.startAligned}>
                <strong>{t("ACCESS_CARD.TABLE_HEADERS.RECIPIENT")}:</strong>{" "}
                <span>
                  {user.firstName} {user.lastName} ({user.email})
                </span>
              </p>
              <p className={styles.startAligned}>
                <strong>{t("ACCESS_CARD.TABLE_HEADERS.COMPANY")}:</strong>{" "}
                <span>{company.buildingTenantName}</span>
              </p>
            </div>
            <div>
              <label htmlFor="ORDER_NOTE_INPUT">
                <strong>{t("ACCESS_CARD.ORDER_MESSAGE.LABEL")}</strong>
              </label>
              <p style={{ marginBottom: 4 }}>
                {t("ACCESS_CARD.ORDER_MESSAGE.DESCRIPTION")}
              </p>
              <InputField
                id="ORDER_NOTE_INPUT"
                dark
                value={orderNote}
                maxLength={100}
                onChange={(e) => setOrderNote(e.target.value)}
                onClear={() => setOrderNote("")}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                disabled={isOrdering}
                isLoading={isOrdering}
                onClick={handleCreatePlasticCardOrder}
              >
                {t("ORDER")}
              </Button>
            </div>
          </div>
        </PopupCard>
      )}
    </>
  );
}
