import { useUploadUsers } from "@/hooks/uploadUsers";
import { useContextStore } from "@/store/zustandStore";
import { Button, InputField, PopupCard, Toggle } from "@app-components";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useTranslation } from "react-i18next";
import styles from "@/styles/Users.module.scss";
import { NAME_REGEX, PHONE_REGEX } from "@/lib/constants";
import { z } from "zod";
import { useCompany } from "@/api";

export default function AddUserModal({
  onClose,
  emailList,
}: {
  onClose: () => void;
  emailList: string[];
}) {
  const { t } = useTranslation();
  const { submitUsers } = useUploadUsers(onClose);
  const { selectedCompany } = useContextStore();
  const { data: company } = useCompany(selectedCompany?.value);

  if (!selectedCompany)
    throw new Error(
      "No company selected, you should not be able to get here without one",
    );

  const form = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      visibleInVisit: true,
      shouldBeAdmin: false,
    },
    onSubmit: async ({ value }) => {
      const userToAdd = {
        firstName: value.firstName,
        lastName: value.lastName,
        buildingTenantId: selectedCompany.value,
        adminInCompanies: value.shouldBeAdmin ? [selectedCompany.value] : [],
        entranceIds: company?.entranceIds || [],
        email: value.email,
        phone: value.phone,
        visibleInVisit: value.visibleInVisit,
      };
      await submitUsers([userToAdd]);
    },
    validatorAdapter: zodValidator(),
  });

  return (
    <PopupCard onClose={onClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <h3>{t("ADD_USER.ADD_USER")}</h3>
        <p>{t("ADD_USER.ADD_USER_DESCRIPTION")}</p>
        <div className={styles.formGrid}>
          <form.Field
            name="firstName"
            validators={{
              onBlur: z
                .string()
                .min(
                  1,
                  t("VALIDATION.REQUIRED", { field: t("ADD_USER.FIRST_NAME") }),
                )
                .min(
                  2,
                  t("VALIDATION.MIN_LENGTH", {
                    field: t("ADD_USER.FIRST_NAME"),
                    length: 2,
                    type: t("CHARACTERS"),
                  }),
                )
                .max(
                  30,
                  t("VALIDATION.MAX_LENGTH", {
                    field: t("ADD_USER.FIRST_NAME"),
                    length: "30",
                    type: t("CHARACTERS"),
                  }),
                )
                .trim()
                .refine((value) => NAME_REGEX.test(value), {
                  message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                }),
            }}
          >
            {(field) => (
              <InputField
                autoFocus
                required
                dark
                label={t("ADD_USER.FIRST_NAME")}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          <form.Field
            name="lastName"
            validators={{
              onBlur: z
                .string()
                .min(
                  1,
                  t("VALIDATION.REQUIRED", { field: t("ADD_USER.LAST_NAME") }),
                )
                .min(
                  2,
                  t("VALIDATION.MIN_LENGTH", {
                    field: t("ADD_USER.LAST_NAME"),
                    length: 2,
                    type: t("CHARACTERS"),
                  }),
                )
                .max(
                  30,
                  t("VALIDATION.MAX_LENGTH", {
                    field: t("ADD_USER.LAST_NAME"),
                    length: "30",
                    type: t("CHARACTERS"),
                  }),
                )
                .trim()
                .refine((value) => NAME_REGEX.test(value), {
                  message: t("VALIDATION.FORBIDDEN_CHARACTERS"),
                }),
            }}
          >
            {(field) => (
              <InputField
                required
                dark
                label={t("ADD_USER.LAST_NAME")}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          <form.Field
            name="email"
            validators={{
              onBlur: z
                .string()
                .min(
                  1,
                  t("VALIDATION.REQUIRED", { field: t("ADD_USER.EMAIL") }),
                )
                .email(t("VALIDATION.INVALID", { field: t("ADD_USER.EMAIL") }))
                .trim()
                .refine((value) => !emailList.includes(value), {
                  message: t("VALIDATION.EMAIL_USED"),
                }),
            }}
          >
            {(field) => (
              <InputField
                required
                dark
                label={t("ADD_USER.EMAIL")}
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          <form.Field
            name="phone"
            validators={{
              onBlur: z
                .string()
                .trim()
                .refine(
                  (value) => !value || (value && PHONE_REGEX.test(value)),
                  {
                    message: t("VALIDATION.INVALID_PHONE"),
                  },
                ),
            }}
          >
            {(field) => (
              <InputField
                dark
                type="tel"
                label={t("ADD_USER.PHONE")}
                value={field.state.value}
                onBlur={field.handleBlur}
                onFocus={() => {
                  if (!field.state.value) field.handleChange("+47");
                }}
                onChange={(e) => field.handleChange(e.target.value)}
                dangerText={field.state.meta.errors.toString()}
              />
            )}
          </form.Field>
          {company.visitMode === "enabled" && (
            <form.Field name="visibleInVisit">
              {(field) => (
                <Toggle
                  title={t("ADD_USER.VISIBLE")}
                  checked={field.state.value}
                  dark
                  onChange={(checked) => field.handleChange(checked)}
                />
              )}
            </form.Field>
          )}
          <form.Field name="shouldBeAdmin">
            {(field) => (
              <Toggle
                title={t("ADD_USER.ADMIN", {
                  companyName: selectedCompany.label,
                })}
                checked={field.state.value}
                dark
                onChange={(checked) => field.handleChange(checked)}
              />
            )}
          </form.Field>

          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <Button
                type="submit"
                disabled={isSubmitting || !canSubmit}
                isLoading={isSubmitting}
              >
                {t("ADD_USER.SUBMIT")}
              </Button>
            )}
          />
        </div>
      </form>
    </PopupCard>
  );
}
