import {
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import client, { throwCaughtError } from "../client";

export const uploadImage = async (image: string, userName?: string) => {
  try {
    const response = await client.post("/uploadImage", {
      image,
      ...(userName ? { userName } : {}),
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const sendSMSMessage = async (
  recipientsPhoneNumber: string,
  messageBody: string,
) => {
  try {
    const response = await client.post("/sendSms", {
      recipientsPhoneNumber,
      messageBody,
    });
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const getIntercomHMAC = async (): Promise<string | undefined> => {
  try {
    const response = await client.post("/createHmac");
    return response.data.hash;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const getImageFunction = async (path: string) => {
  try {
    const response = await client.get(`/getImage?path=${path}`);
    return response.data.url;
  } catch (error) {
    console.error(error); //If issues with image, no need to stop everything else
    return "";
  }
};

export const useImage = (path: string): UseSuspenseQueryResult<string> => {
  return useSuspenseQuery({
    queryKey: ["image", path],
    queryFn: () => getImageFunction(path),
  });
};
