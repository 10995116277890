import {
  contextStoreType,
  NotificationType,
  SelectChild,
  storeType,
  userStoreType,
} from "@/types";
import { create } from "zustand";

export const useSnackbarStore = create<storeType>((set) => ({
  notifications: [],

  addNotification: (
    message: string,
    type: NotificationType = NotificationType.ERROR,
  ) => {
    const id = new Date().getTime();
    set(({ notifications }) => ({
      notifications: [...notifications, { id, message, type }],
    }));
    setTimeout(() => {
      set(({ notifications }) => ({
        notifications: notifications.filter((n) => n.id !== id),
      }));
    }, 10000); // 10 secs
  },
  removeNotification: (id: number) =>
    set(({ notifications }) => ({
      notifications: notifications.filter((n) => n.id !== id),
    })),
}));

export const useUserStore = create<userStoreType>((set) => ({
  userName: "",
  setUserName: (userName: string) => set({ userName: userName }),
}));

const storedcompany = localStorage.getItem("selectedCompany");

export const useContextStore = create<contextStoreType>((set) => ({
  selectedCompany: storedcompany ? JSON.parse(storedcompany) : undefined,
  setCompany: (selectedCompany: SelectChild) => set({ selectedCompany }),
}));
