import { useEffect, useState } from "react";

export const useIntersection = (
  element: React.RefObject<Element>,
  rootMargin: string,
) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const current = element?.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin },
    );
    if (current) observer?.observe(current);

    return () => {
      if (current) observer.unobserve(current);
    };
  }, []);

  return isVisible;
};
