import { Button, Icon } from "@app-components";
import { useTranslation } from "react-i18next";
import styles from "@/styles/CompanySettings.module.scss";
import Table from "@/components/table/Table.tsx";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ExtendedUser, NotificationType } from "@/types";
import { useMemo, useState } from "react";
import { useContextStore, useSnackbarStore } from "@/store/zustandStore";
import { createPortal } from "react-dom";
import AddAdminModal from "@/components/AddAdminModal";
import { updateUser, companyAdminsOptions } from "@/api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CompanyContactSelector from "./CompanyContactSelector";
import { Tooltip } from "react-tooltip";

const columnHelper = createColumnHelper<ExtendedUser>();

export default function AdminsSection() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { addNotification } = useSnackbarStore();
  const { selectedCompany } = useContextStore();
  const {
    data: admins,
    isLoading,
    error,
  } = useQuery(companyAdminsOptions(selectedCompany?.value));
  const [addAdminOpen, setAddAdminOpen] = useState(false);

  if (error) {
    throw new Error("Error fetching company admins", { cause: error });
  }

  const removeUserAsAdmin = async (
    e: Event,
    userId: string,
    prevAdminInCompanies: string[],
  ) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const adminInCompanies = prevAdminInCompanies.filter(
        (id) => id !== selectedCompany?.value,
      );
      await updateUser(userId, { adminInCompanies });
      queryClient.invalidateQueries({
        queryKey: ["company-admins", selectedCompany?.value],
      });
      addNotification(
        "User was removed as administrator",
        NotificationType.SUCCESS,
      );
    } catch (error) {
      console.error(error);
      addNotification("Failed to remove user as admin. Try again later!");
    }
  };

  const columns = useMemo(
    // memoize so columns don't need to be "re-rendered" when component changes
    () => [
      columnHelper.accessor((row) => row, {
        id: "companyContact",
        cell: (info) => <CompanyContactSelector user={info.getValue()} />,
        header: () => (
          <div className="sr-only">
            {t("USERS.TABLE_HEADERS.COMPANY_CONTACT")}
          </div>
        ),
      }),
      columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
        id: "userName",
        cell: (info) => info.getValue(),
        header: () => <div>{t("USERS.TABLE_HEADERS.NAME")}</div>,
      }),
      columnHelper.accessor("email", {
        cell: (info) => info.getValue(),
        header: () => <div>{t("USERS.TABLE_HEADERS.EMAIL")}</div>,
      }),
      columnHelper.accessor("phone", {
        cell: (info) => info.getValue(),
        header: () => <div>{t("USERS.TABLE_HEADERS.PHONE")}</div>,
      }),
      columnHelper.accessor("buildingTenantName", {
        cell: (info) => info.getValue(),
        header: () => <div>{t("USERS.TABLE_HEADERS.COMPANY")}</div>,
      }),
      columnHelper.accessor((row) => row, {
        id: "actions",
        cell: (info) => {
          const { userId, adminInCompanies, companyContact, buildingTenantId } =
            info.getValue();
          const isCompanyContact =
            companyContact && buildingTenantId === selectedCompany?.value;
          return (
            <>
              <Button
                className={`remove-admin-button-${userId} ${styles.removeAdminBtn}`}
                size="sm"
                danger
                disabled={isCompanyContact}
                onClick={(e) => removeUserAsAdmin(e, userId, adminInCompanies)}
              >
                <Icon name="user_cogwheel" />
                <span className="sr-only">
                  {isCompanyContact
                    ? t("COMPANY_SETTINGS.ADMINS.REMOVE_NOT_ALLOWED")
                    : t("COMPANY_SETTINGS.ADMINS.REMOVE_AS_ADMIN")}
                </span>
              </Button>
              <Tooltip
                anchorSelect={`.remove-admin-button-${userId}`}
                style={
                  isCompanyContact
                    ? {
                        backgroundColor: "var(--wlcm-color-danger-red)",
                      }
                    : {}
                }
                content={
                  isCompanyContact
                    ? t("COMPANY_SETTINGS.ADMINS.REMOVE_NOT_ALLOWED")
                    : t("COMPANY_SETTINGS.ADMINS.REMOVE_AS_ADMIN")
                }
              />
            </>
          );
        },
        header: () => (
          <div className="sr-only">{t("COMPANY_SETTINGS.ADMINS.ACTIONS")}</div>
        ),
      }),
    ],
    [],
  );

  const table = useReactTable({
    data: admins || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <section style={{ display: "contents" }}>
        <div className={`${styles.fullWidth} ${styles.sectionWithButton}`}>
          <h2>{t("COMPANY_SETTINGS.ADMINS.HEADER")}</h2>
          <p className={styles.description}>
            {t("COMPANY_SETTINGS.ADMINS.DESCRIPTION", {
              company: selectedCompany?.label,
            })}
          </p>
          <Button
            icon="add"
            onClick={(e) => {
              e.preventDefault();
              setAddAdminOpen(true);
            }}
          >
            {t("COMPANY_SETTINGS.ADMINS.ADD")}
          </Button>
        </div>
        <Table
          table={table}
          className={styles.fullWidth}
          isLoading={isLoading}
        />
        <p className={`${styles.fullWidth} ${styles.descriptionText}`}>
          <span className="sr-only">star-icon</span>
          <Icon name="star" className={styles.descriptionTextIcon} />{" "}
          <span>=</span> {t("COMPANY_SETTINGS.ADMINS.CONTACT_DESCRIPTION")}
        </p>
      </section>
      {/* createPortal is REQUIRED so the modal form is not rendered inside the <BuildingSettings /> form */}
      {addAdminOpen &&
        admins &&
        selectedCompany &&
        createPortal(
          <AddAdminModal
            header={t("COMPANY_SETTINGS.ADMINS.ADD")}
            body={t("COMPANY_SETTINGS.ADMINS.ADD_BODY")}
            noResultsText={t("COMPANY_SETTINGS.ADMINS.NO_RESULTS")}
            existingAdmins={admins}
            onClose={() => setAddAdminOpen(false)}
            onAdd={async (userToAdd) => {
              await updateUser(userToAdd.userId, {
                adminInCompanies: [
                  ...userToAdd.adminInCompanies,
                  selectedCompany.value,
                ],
              });
              return queryClient.invalidateQueries({
                queryKey: ["company-admins", selectedCompany.value],
              });
            }}
          />,
          document.querySelector("main") || document.body,
        )}
    </>
  );
}
