import styles from "@/styles/App.module.scss";
import { Accordion, Chip, Icon } from "@app-components";
import { Suspense } from "react";
import { Link, useLocation } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";
import { AccessMode, Visit } from "@/types";
import { auth, getAuthUser } from "@/lib/firebase";
import welcomeLogo from "@/assets/whiteLogo.svg";
import { useTranslation } from "react-i18next";
import { useContextStore, useUserStore } from "@/store/zustandStore";
import { useEffect } from "react";
import { useState } from "react";
import BuildingCompanyPicker from "@/components/app/BuildingCompanyPicker";
import { WELCOME_HELP_URL } from "@/lib/constants";
import { useLoggedInUser } from "@/providers/AuthProvider";
import {
  useExtendedAccessCards,
  useBuilding,
  useCompany,
  useCompanyVisitors,
  personalVisitorsOptions,
  logoutFromKeycloak,
  personPreregistrationOptions,
  companyPreregistrationOptions,
} from "@/api";
import { filterTodayPreregistrations } from "@/lib/utils";

type SidebarElement = {
  label: string;
  icon: string;
  href: string;
  notificationNumber?: number;
};

const sidebarAccordionStyling = {
  activator: {
    background: "rgba(255,255,255,0.1)",
    padding: "4px 8px",
    borderRadius: 4,
  },
  body: {
    background: "rgba(255,255,255,0.05)",
    borderRadius: "0px 0px 4px 4px",
  },
};

export default function Sidebar() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { setUserName } = useUserStore();
  const user = useLoggedInUser();
  const { selectedCompany } = useContextStore();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    if (user) {
      setUserName(`${user.firstName} ${user.lastName}`);
      i18n.changeLanguage(user.language);
    }
  }, [user, setUserName]);

  const closeSidebar = () => {
    setSidebarOpen(false);
    if (location.pathname.includes("handbook")) {
      document.getElementById("handbook")?.classList.remove("hide");
      document.getElementById("iframe-top-cover")?.classList.remove("hide");
    }
    if (location.pathname.includes("bookings")) {
      document.getElementById("bookings")?.classList.remove("hide");
      document.getElementById("iframe-top-cover")?.classList.remove("hide");
    }
  };
  const toggleSidebar = () => {
    if (sidebarOpen) {
      closeSidebar();
    } else {
      setSidebarOpen(true);
      if (location.pathname.includes("handbook")) {
        document.getElementById("handbook")?.classList.add("hide");
        document.getElementById("iframe-top-cover")?.classList.add("hide");
      }
      if (location.pathname.includes("bookings")) {
        document.getElementById("bookings")?.classList.add("hide");
        document.getElementById("iframe-top-cover")?.classList.add("hide");
      }
    }
  };

  const onLogout = async () => {
    //If logout from Keycloak fails, we still want to log out from Firebase
    try {
      await logoutFromKeycloak(user.email);
    } catch (error) {
      console.error("Failed to logout from Keycloak:", error);
    }
    auth?.signOut();
    window.location.reload();
  };

  const userIsCompanyAdmin = user.adminInCompanies.length > 0;

  return (
    <div className={`${styles.sidebar} ${sidebarOpen && styles.open}`}>
      <header className={styles.logoHeader}>
        <img
          width={75}
          height={75}
          className={styles.mainLogo}
          src={welcomeLogo}
          alt="Welcome Workdays"
        />
        <Suspense
          fallback={
            <div
              className={`loading ${styles.buildingCompanyPickerSkeleton}`}
            ></div>
          }
        >
          <BuildingCompanyPicker />
        </Suspense>
        <button
          className={styles.menuButton}
          aria-pressed={sidebarOpen}
          aria-label="Navigation menu"
          onClick={toggleSidebar}
        >
          <Icon name="menu" />
          {/* {!!filteredVisits.length && ( */}
          {/*   <div className={styles.notificationDot} aria-hidden></div> */}
          {/* )} */}
        </button>
      </header>
      <nav className={styles.links}>
        <div className={styles.topLinks} key={selectedCompany?.value}>
          <Suspense>
            {selectedCompany && (
              <UserSidebar
                companyId={selectedCompany.value}
                closeSidebar={closeSidebar}
              />
            )}
            {userIsCompanyAdmin && selectedCompany && (
              <CompanyAdminSidebar
                closeSidebar={closeSidebar}
                companyId={selectedCompany.value}
              />
            )}
            <BuildingAdminSidebar closeSidebar={closeSidebar} />
          </Suspense>
        </div>
        <div className={styles.bottomLinks}>
          <button
            className={styles.sidebarButton}
            onClick={() => {
              window.open(WELCOME_HELP_URL(i18n.language), "_blank");
            }}
          >
            <Icon name="help" />
            {t("SIDEBAR.HELP")}
          </button>
          <button className={styles.sidebarButton} onClick={onLogout}>
            <Icon name="shield_person" />
            {t("SIDEBAR.LOG_OUT")}
          </button>
        </div>
      </nav>
    </div>
  );
}

type UserSidebarProps = {
  companyId: string;
  closeSidebar: () => void;
};
const UserSidebar = ({ companyId, closeSidebar }: UserSidebarProps) => {
  const { t } = useTranslation();
  const user = useLoggedInUser();
  const { data: building } = useBuilding();
  const { data: visits } = useQuery(personalVisitorsOptions(user.userId));
  const { data: groups } = useQuery(personPreregistrationOptions(user.userId));
  const { data: userCompany } = useCompany(user.buildingTenantId);
  const [expanded, setExpanded] = useState(
    companyId === userCompany.buildingTenantId,
  );

  const userIsCompanyAdmin = user.adminInCompanies?.length > 0;
  const userIsBuildingAdmin = building.buildingAdmins.includes(user.userId);
  const groupsToday =
    filterTodayPreregistrations(groups || [])?.filter((group) => {
      return group.attendees.some((attendee) => !attendee.arrivedAt); // Only include groups where not everyone has arrived yet
    }) || [];

  const sidebarUserElements = (
    personalVisitorsCount: number,
  ): SidebarElement[] => {
    let elements: SidebarElement[] = [];

    if (userCompany.visitMode === "enabled") {
      elements = [
        ...elements,
        {
          label: "VISITORS",
          icon: "group",
          href: "/visitors",
          notificationNumber: personalVisitorsCount,
        },
      ];
    }

    if (building.accessMode === AccessMode.ENABLED) {
      elements = [
        ...elements,
        {
          label: "ACCESS",
          icon: "badge",
          href: "/access",
        },
      ];
    }

    const handbookLink = import.meta.env.VITE_HANDBOOK_LINK;
    const bookingLink = import.meta.env.VITE_BOOKING_LINK;

    if (handbookLink && handbookLink !== "null") {
      elements = [
        ...elements,
        { label: "INFO", icon: "info", href: "/handbook" },
      ];
    }
    if (bookingLink && bookingLink !== "null") {
      elements = [
        ...elements,
        {
          label: "BOOKINGS",
          icon: "date_range",
          href: "/bookings",
        },
      ];
    }

    elements.push({ label: "SETTINGS", icon: "settings", href: "/settings" });

    return elements;
  };

  const oneHourAgo = new Date().getSeconds() - 60 * 60;
  const visitorsNotifCount: number =
    !visits && !groups
      ? 0
      : visits.filter(
          (v: Visit) =>
            v.hostId === user?.userId &&
            !v.hostReplied &&
            !v.letInAt &&
            v.createdAt._seconds >= oneHourAgo,
        ).length + groupsToday.length;

  return (
    <div style={{ position: "relative" }}>
      {userIsCompanyAdmin || userIsBuildingAdmin ? (
        <>
          <Accordion
            id="user-sidebar-items"
            expanded={expanded}
            onToggle={setExpanded}
            header={<h3>{t("SIDEBAR.PERSONAL_HEADING")}</h3>}
            body={sidebarUserElements(visitorsNotifCount).map(
              (element, index) => (
                <SideBarLink
                  key={index}
                  {...element}
                  closeSidebar={closeSidebar}
                />
              ),
            )}
            style={sidebarAccordionStyling}
          />
          {!!(visitorsNotifCount && !expanded) && (
            <div className={styles.notificationDot} aria-hidden />
          )}
        </>
      ) : (
        sidebarUserElements(visitorsNotifCount).map((element, index) => (
          <SideBarLink key={index} {...element} closeSidebar={closeSidebar} />
        ))
      )}
    </div>
  );
};

type CompanyAdminSidebarProps = {
  closeSidebar: () => void;
  companyId: string;
};
const CompanyAdminSidebar = ({
  closeSidebar,
  companyId,
}: CompanyAdminSidebarProps) => {
  const { data: companyVisits } = useCompanyVisitors(companyId);
  const { data: companyGroups } = useQuery(
    companyPreregistrationOptions(companyId),
  );
  const { data: contextCompany } = useCompany(companyId);
  const [expanded, setExpanded] = useState(true);

  const oneHourAgo = new Date().getSeconds() - 60 * 60;
  const groupsToday =
    filterTodayPreregistrations(companyGroups || [])?.filter((group) => {
      return group.attendees.some((attendee) => !attendee.arrivedAt); // Only include groups where not everyone has arrived yet
    }) || [];

  const sidebarCompanyAdminElements = (
    companyVisitorsCount: number,
  ): SidebarElement[] => {
    let elements: SidebarElement[] = [
      { label: "USERS", icon: "groups", href: "/company/users" },
      { label: "COMPANY_SETTINGS", icon: "tune", href: "/company/settings" },
    ];

    if (contextCompany.visitMode === "enabled") {
      elements = [
        {
          label: "COMPANY_VISITORS",
          icon: "group",
          href: "/company/visitors",
          notificationNumber: companyVisitorsCount,
        },
        ...elements,
      ];
    }

    return elements;
  };

  const companyVisitsNotifCount: number =
    !companyVisits && !companyGroups
      ? 0
      : companyVisits.filter(
          (c) => !c.hostReplied && c.createdAt._seconds >= oneHourAgo,
        ).length + groupsToday.length;
  return (
    <div style={{ position: "relative" }}>
      <Accordion
        id="company-sidebar-items"
        expanded={expanded}
        onToggle={setExpanded}
        header={
          <h3 style={{ color: "var(--wlcm-color-malling-purple)" }}>
            {contextCompany.buildingTenantName}
          </h3>
        }
        body={sidebarCompanyAdminElements(companyVisitsNotifCount).map(
          (element) => (
            <SideBarLink
              key={element.href}
              {...element}
              closeSidebar={closeSidebar}
            />
          ),
        )}
        style={sidebarAccordionStyling}
      />
      {!!(companyVisitsNotifCount && !expanded) && (
        <div className={styles.notificationDot} aria-hidden />
      )}
    </div>
  );
};

type BuildingAdminSidebarProps = {
  closeSidebar: () => void;
};

const BuildingAdminSidebar = ({ closeSidebar }: BuildingAdminSidebarProps) => {
  const authUser = getAuthUser();
  const { data: building } = useBuilding();
  const { data: cardRequests } = useExtendedAccessCards(true);
  const [expanded, setExpanded] = useState(true);

  const userIsBuildingAdmin = building.buildingAdmins.includes(authUser.uid);
  if (!userIsBuildingAdmin) return null;

  const sidebarBuildingAdminElements = (
    cardOrderCount: number,
  ): SidebarElement[] => [
    { label: "BUILDING_VISITORS", icon: "group", href: "/building/visitors" },
    { label: "BUILDING_COMPANIES", icon: "work", href: "/building/companies" },
    ...(building.accessMode === AccessMode.ENABLED
      ? [
          {
            label: "ACCESS",
            icon: "badge",
            href: "/building/access",
            notificationNumber: cardOrderCount,
          },
        ]
      : []),
    { label: "BUILDING_SETTINGS", icon: "tune", href: "/building/settings" },
  ];

  return (
    <div style={{ position: "relative" }}>
      <Accordion
        id="building-sidebar-items"
        expanded={expanded}
        onToggle={setExpanded}
        header={
          <h3 style={{ color: "var(--wlcm-color-malling-purple)" }}>
            {building.displayName}
          </h3>
        }
        body={sidebarBuildingAdminElements(cardRequests.length).map(
          (element) => (
            <SideBarLink
              key={element.href}
              {...element}
              closeSidebar={closeSidebar}
            />
          ),
        )}
        style={sidebarAccordionStyling}
      />
      {!!(cardRequests.length && !expanded) && (
        <div className={styles.notificationDot} aria-hidden />
      )}
    </div>
  );
};

type SideBarLink = {
  label: string;
  href: string;
  icon: string;
  notificationNumber?: number;
  closeSidebar: () => void;
};
const SideBarLink = ({
  label,
  notificationNumber,
  href,
  icon,
  closeSidebar,
}: SideBarLink) => {
  const { t } = useTranslation();
  const label_ = t("SIDEBAR." + label);

  return (
    <Link
      onClick={closeSidebar}
      to={href}
      activeProps={{
        className: `${styles.link} ${styles.active}`,
      }}
      className={styles.link}
    >
      <Icon className={styles.icon} name={icon} />{" "}
      <span className={styles.label}>{label_}</span>
      {!!notificationNumber && (
        <Chip
          className={styles.chip}
          label={notificationNumber < 10 ? notificationNumber.toString() : "9+"}
        />
      )}
    </Link>
  );
};
