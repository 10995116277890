import { useCompanyContact } from "@/api";
import { auth } from "@/lib/firebase";
import { useLoggedInUser } from "@/providers/AuthProvider";
import { CompanyContact } from "@/types";
import { Button } from "@app-components";
import { useLocation } from "@tanstack/react-router";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  fallback?: React.ReactNode;
  t: (key: string) => string;
  location?: string;
  outsideAuthProvider?: boolean;
  insideRouter?: boolean;
  companyContact?: CompanyContact;
}

interface State {
  hasError: boolean;
  error: Error | null;
  prevLocation: string;
}

const withEnhancements = (Component: typeof BasicBoundary) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function WrappedComponent(props: any) {
    const { t } = useTranslation();
    const location = props.insideRouter ? useLocation() : undefined;
    const currentUser = props.outsideAuthProvider
      ? undefined
      : useLoggedInUser();
    const { data: companyContact } = props.outsideAuthProvider
      ? { data: undefined }
      : useCompanyContact(currentUser?.buildingTenantId);

    return (
      <Component
        {...props}
        t={t}
        companyContact={companyContact}
        location={location?.pathname ?? ""}
      />
    );
  };
};

class BasicBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      prevLocation: props.location ?? "",
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      // Reset state and ensure it completes before re-render
      // Using setTimeout to delay state reset and avoid race conditions during React updates.
      setTimeout(() => {
        this.setState({ hasError: false, error: null });
      }, 1);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any, info: any) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    /* logErrorToMyService(error, info.componentStack); */
    console.error(error, info);
  }

  /* componentDidUpdate(prevProps: Props) {
    // Reset the error boundary state if the location has changed
    console.log("Changed", this.props.location, prevProps.location);
    if (this.props.location !== prevProps.location) {
      this.setState({ hasError: false });
    }
  } */

  render() {
    const { fallback, t, companyContact } = this.props;

    if (this.state.hasError) {
      // If a specific fallback UI is provided, return that
      // Otherwise show default fallback UI
      return (
        fallback || (
          <div style={this.props.style} className={this.props.className}>
            <div className="pageHeader">
              <h1>{t("ERROR_BOUNDARY.HEADER")}</h1>
              <p>{t("ERROR_BOUNDARY.BODY")}</p>
              {companyContact && (
                <p>
                  <Trans
                    i18nKey="YOUR_MAIN_ADMIN"
                    values={{
                      companyContact: companyContact.name,
                    }}
                    components={{
                      mailto: <a href={`mailto:${companyContact.email}`}></a>,
                    }}
                  ></Trans>
                </p>
              )}
              <p>
                {t("ERROR_BOUNDARY.MESSAGE")}
                {": "}
                <strong>{this.state.error?.message}</strong>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "var(--wlcm-spacing-xs)",
                alignItems: "center",
              }}
            >
              <Button dark onClick={() => window.location.reload()}>
                {t("ERROR_BOUNDARY.RELOAD")}
              </Button>
              <p>{t("OR")}</p>
              <Button
                secondary
                onClick={() => {
                  auth?.signOut();
                  window.location.reload();
                }}
              >
                {t("SIDEBAR.LOG_OUT")}
              </Button>
            </div>
          </div>
        )
      );
    }

    return this.props.children;
  }
}

const ErrorBoundary = withEnhancements(BasicBoundary);
export default ErrorBoundary;
