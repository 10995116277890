import { Button, PopupCard } from "@app-components";
import { useTranslation } from "react-i18next";
import type { ConfirmPropsType } from "@/providers/ConfirmProvider";

type ConfirmationModalProps = ConfirmPropsType & {
  open: boolean;
};

export default function ConfirmationModal(props: ConfirmationModalProps) {
  const { t } = useTranslation();

  const {
    open,
    title = t("UNSAVED_CHANGES.TITLE"),
    subtitle = t("UNSAVED_CHANGES.BODY"),
    cancelText = t("CANCEL"),
    confirmText = t("CONTINUE"),
    onCancel,
    onConfirm,
  } = props;

  if (open) {
    return (
      <PopupCard onClose={onCancel}>
        <h3>{title}</h3>
        <p>{subtitle}</p>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "end",
            gap: "var(--wlcm-spacing-xs) var(--wlcm-spacing-sm)",
            marginTop: "var(--wlcm-spacing-sm)",
          }}
        >
          <Button onClick={onCancel}>{cancelText}</Button>
          <Button danger onClick={onConfirm}>
            {confirmText}
          </Button>
        </div>
      </PopupCard>
    );
  } else {
    return <></>;
  }
}
