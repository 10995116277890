import { updateBuildingSettings, useBuilding } from "@/api";
import { ExtendedUser, NotificationType } from "@/types";
import { Button, Icon, LoadingSpinner } from "@app-components";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import styles from "@/styles/BuildingSettings.module.scss";
import { useSnackbarStore } from "@/store/zustandStore";

interface BuildingAdminNotificationsSelectorProps {
  user: ExtendedUser;
}

export default function BuildingAdminNotificationsSelector({
  user,
}: BuildingAdminNotificationsSelectorProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: building } = useBuilding();
  const [isUpdating, setIsUpdating] = useState(false);
  const { addNotification } = useSnackbarStore();
  const currentAdminsNotify = useMemo(() => {
    return building?.buildingAdminsNotifications || [];
  }, [building]);
  const isNotificationOn = useMemo(() => {
    return building?.buildingAdminsNotifications?.includes(user.userId)
      ? true
      : false;
  }, [building, user.userId]);

  const turnOnNotifications = async () => {
    setIsUpdating(true);
    try {
      const updatedAdminsNotify = [...currentAdminsNotify, user.userId];
      await updateBuildingSettings({
        buildingAdminsNotifications: updatedAdminsNotify,
      });
      await queryClient.invalidateQueries({
        queryKey: ["building"],
      });
      addNotification(
        t("BUILDING_SETTINGS.NOTIFICATIONS.SUCCESS"),
        NotificationType.SUCCESS,
      );
    } catch (error) {
      console.error(error);
      addNotification(t("BUILDING_SETTINGS.NOTIFICATIONS.ERROR"));
    } finally {
      setIsUpdating(false);
    }
  };

  const turnOffNotifications = async () => {
    setIsUpdating(true);
    try {
      const updatedAdminsNotify = currentAdminsNotify.filter(
        (id) => id !== user.userId,
      );
      await updateBuildingSettings({
        buildingAdminsNotifications: updatedAdminsNotify,
      });
      await queryClient.invalidateQueries({
        queryKey: ["building"],
      });
      addNotification(
        t("BUILDING_SETTINGS.NOTIFICATIONS.SUCCESS"),
        NotificationType.SUCCESS,
      );
    } catch (error) {
      console.error(error);
      addNotification(t("BUILDING_SETTINGS.NOTIFICATIONS.ERROR"));
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <>
      <span className="sr-only">{isNotificationOn ? t("YES") : t("NO")}</span>
      {isUpdating ? (
        <LoadingSpinner />
      ) : isNotificationOn ? (
        <>
          <Button
            className={`turn-off-notifications-button ${styles.notificationsBtn}`}
            onClick={turnOffNotifications}
          >
            <Icon name="notifications_filled" />
            <span className="sr-only">
              {t("BUILDING_SETTINGS.NOTIFICATIONS.ON")}
            </span>
          </Button>
          <Tooltip
            anchorSelect=".turn-off-notifications-button"
            content={t("BUILDING_SETTINGS.NOTIFICATIONS.ON")}
          />
        </>
      ) : (
        <>
          <Button
            className={`turn-on-notifications-button  ${styles.notificationsBtn} ${styles.notificationsBtnOff}`}
            onClick={turnOnNotifications}
          >
            <Icon name="notifications" />
            <span className="sr-only">
              {t("BUILDING_SETTINGS.NOTIFICATIONS.OFF")}
            </span>
          </Button>
          <Tooltip
            anchorSelect=".turn-on-notifications-button"
            content={t("BUILDING_SETTINGS.NOTIFICATIONS.OFF")}
          />
        </>
      )}
    </>
  );
}
